import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
  socialButton: {
    backgroundPosition: '25px 0px',
    boxSizing: 'border-box',
    color: 'rgb(255, 255, 255)',
    cursor: 'pointer',
    display: 'inline-block',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'left',
    textDecoration: 'none',
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    width: '100%',
    borderRadius: '3px',
    margin: '5px auto',
    outline: 'rgb(255, 255, 255) none 0px',
    paddingLeft: '20%',
    // transition: 'all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s',
    WebkitTransition: 'all .3s ease',
    MozTransition: 'all .3s ease',
    MsTransition: 'all .3s ease',
    OTransition: 'all .3s ease',
    transition: 'all .3s ease',
  },

  facebook_connect: {
    background:
      "rgb(255, 255, 255) url('https://raw.githubusercontent.com/eswarasai/social-login/master/img/facebook.svg?sanitize=true') no-repeat scroll 5px 0px / 30px 50px padding-box border-box",
    border: '1px solid rgb(60, 90, 154)',
    '&:hover': {
      borderColor: 'rgb(60, 90, 154)',
      background:
        "rgb(60, 90, 154) url('https://raw.githubusercontent.com/eswarasai/social-login/master/img/facebook-white.svg?sanitize=true') no-repeat scroll 5px 0px / 30px 50px padding-box border-box",
      WebkitTransition: 'all .8s ease-out',
      MozTransition: 'all .3s ease',
      MsTransition: 'all .3s ease',
      OTransition: 'all .3s ease',
      transition: 'all .3s ease-out',
    },
    '& span': {
      boxSizing: 'border-box',
      color: 'rgb(60, 90, 154)',
      cursor: 'pointer',
      textAlign: 'center',
      textTransform: 'uppercase',
      border: '0px none rgb(255, 255, 255)',
      outline: 'rgb(255, 255, 255) none 0px',
      WebkitTransition: 'all .3s ease',
      MozTransition: 'all .3s ease',
      MsTransition: 'all .3s ease',
      OTransition: 'all .3s ease',
      transition: 'all .3s ease',
    },
    '&:hover span': {
      color: '#FFF',
      WebkitTransition: 'all .3s ease',
      MozTransition: 'all .3s ease',
      MsTransition: 'all .3s ease',
      OTransition: 'all .3s ease',
      transition: 'all .3s ease',
    },
  },

  google_connect: {
    background:
      "rgb(255, 255, 255) url('https://raw.githubusercontent.com/eswarasai/social-login/master/img/google-plus.png') no-repeat scroll 5px 0px / 50px 50px padding-box border-box",
    border: '1px solid rgb(220, 74, 61)',
    '&:hover': {
      borderColor: 'rgb(220, 74, 61)',
      background:
        "rgb(220, 74, 61) url('https://raw.githubusercontent.com/eswarasai/social-login/master/img/google-plus-white.png') no-repeat scroll 5px 0px / 50px 50px padding-box border-box",
      WebkitTransition: 'all .8s ease-out',
      MozTransition: 'all .3s ease',
      MsTransition: 'all .3s ease',
      OTransition: 'all .3s ease',
      transition: 'all .3s ease-out',
    },
    '& span': {
      boxSizing: 'border-box',
      color: 'rgb(220, 74, 61)',
      cursor: 'pointer',
      textAlign: 'center',
      textTransform: 'uppercase',
      border: '0px none rgb(220, 74, 61)',
      outline: 'rgb(255, 255, 255) none 0px',
      WebkitTransition: 'all .3s ease',
      MozTransition: 'all .3s ease',
      MsTransition: 'all .3s ease',
      OTransition: 'all .3s ease',
      transition: 'all .3s ease',
    },
    '&:hover span': {
      color: '#FFF',
      WebkitTransition: 'all .3s ease',
      MozTransition: 'all .3s ease',
      MsTransition: 'all .3s ease',
      OTransition: 'all .3s ease',
      transition: 'all .3s ease',
    },
  },
});
