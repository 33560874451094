import Notistack from '@frontend/lib/notistack';
import { confirmAccount } from '@frontend/stores/UserStore';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

function DefaultConfirmationPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { confirmationCode } = useParams<{ confirmationCode: string }>();

  useEffect(() => {
    confirmAccount(confirmationCode)
      .then(() => {
        Notistack.toast(t('auth:confirmed'), { variant: 'success' });

        navigate('/');
      })
      .catch((error) => {
        Notistack.toast(t([error?.response?.data?.message, 'auth:not_found_confirmation']));

        navigate('/login');
      });
  }, []);

  return <></>;
}

export default DefaultConfirmationPage;
