/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this file,
 * You can obtain one at http://mozilla.org/MPL/2.0/. */
/* vim: set ts=8 sts=2 et sw=2 tw=80: */
/* globals Sanitizer */

// https://github.com/mozilla/sanitizer-polyfill

import { Config, AbstractSanitizer } from './types';

export * from './types';

/**
 * This function inserts the `Sanitizer` interface into `window`, if it exists.
 */
async function setup() {
  // name of our global object
  const GLOBALNAME = 'Sanitizer';

  // name of the innerHTML-setter,
  // https://github.com/WICG/sanitizer-api/issues/100
  // when changing this, also change the function declaration below manually.
  const SETTER_NAME = 'setHTML';

  if (typeof window === 'undefined') {
    return;
  }

  if (!window.isSecureContext) {
    return;
  }

  // Don't polyfill if already defined
  if (typeof window[GLOBALNAME] === 'function' && typeof window[GLOBALNAME].prototype.sanitizeFor === 'function') {
    console.log('Sanitizer API available, not using Polyfill');

    return window[GLOBALNAME];
  }

  const { DEFAULT_ALLOWED_ELEMENTS, getDefaultConfiguration, sanitizeDocFragment, _normalizeConfig } = await import(
    './sanitizer'
  );

  class Sanitizer extends AbstractSanitizer {
    get config() {
      return this.normalizedConfig;
    }

    constructor(config?: Config) {
      super(config);

      this.normalizedConfig = config ? _normalizeConfig(config) : Sanitizer.getDefaultConfiguration();
    }

    sanitize(input: HTMLElement) {
      return sanitizeDocFragment(this.config, input, false);
    }

    sanitizeFor(element: string, input: string) {
      // The inactive document does not issue requests and does not execute scripts.
      const inactiveDocument = document.implementation.createHTMLDocument();

      if (!DEFAULT_ALLOWED_ELEMENTS.has(element)) {
        throw new SanitizerError(`${element} is not an element in built-in default allow list`);
      }

      const context = inactiveDocument.createElement(element);
      context.innerHTML = input;
      sanitizeDocFragment(this.config, context);

      return context;
    }

    getConfiguration() {
      return this.config;
    }

    static getDefaultConfiguration() {
      return getDefaultConfiguration();
    }
  }

  window[GLOBALNAME] = Sanitizer;

  HTMLElement.prototype[SETTER_NAME] = function setHTML(input: string, opt?: { sanitizer?: AbstractSanitizer }): void {
    let sanitizerObj = opt && opt.sanitizer;

    if (!sanitizerObj || typeof sanitizerObj.getConfiguration !== 'function') {
      sanitizerObj = new Sanitizer();
    }

    const inactiveDocument = document.implementation.createHTMLDocument();
    const context = inactiveDocument.createElement(this.localName);
    context.innerHTML = input;
    sanitizeDocFragment(sanitizerObj.getConfiguration(), context);
    this.replaceChildren(...context.childNodes);
  };

  return Sanitizer;
}

class SanitizerError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'SanitizerError';
  }
}

export default (() => {
  let cache: Promise<typeof AbstractSanitizer>;

  return (): Promise<typeof AbstractSanitizer> => {
    if (!cache) {
      cache = setup()
        .then((sanitizer) => {
          if (!sanitizer) {
            throw new Error('Sanitizer not initialized');
          }

          return sanitizer;
        })
        .catch((err) => {
          console.error(err);
          // TODO: Report to Sentry

          throw err;
        });
    }

    return cache;
  };
})();
