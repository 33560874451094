import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { preInitStoresData, Stores } from './Stores';
import config from '@/config';

type StoreSProviderProps = {
  initializeCallback?: () => void;
  children: JSX.Element;
};

export default function StoresProvider({ initializeCallback, children }: StoreSProviderProps): JSX.Element {
  const [state, setState] = useState(preInitStoresData);

  useEffect(function () {
    // Expose the Store to the Global scope for easy Store inspection from the console (Stores.inspect())
    if (!config.isProduction || config.app.debug === true) {
      globalThis.Stores = Stores;

      Stores.inspect = () => JSON.parse(JSON.stringify(Stores.getAll()));
    }
  }, []);

  useEffect(() => {
    Stores.getAll = () => state;
  }, [state]);

  useEffect(() => {
    Stores.get = (storeName) => state[storeName];
  }, [state]);

  useEffect(() => {
    Stores.set = <TData,>(
      storeName: string,
      keyOrData: keyof TData | Partial<TData>,
      optionalData?: Partial<TData>
    ) => {
      let key: keyof TData;
      let data: Partial<TData>;

      if (optionalData) {
        data = optionalData;
      }

      if (typeof keyOrData !== 'object') {
        key = keyOrData;
      } else {
        data = keyOrData;
      }

      // Update the state with the new State
      setState((prevState) => {
        const previousStore = prevState[storeName] || {};

        const newStore = { ...previousStore };

        if (key !== undefined) {
          newStore[key] = data;
        } else {
          Object.entries(data).forEach(([name, value]) => {
            newStore[name] = value;
          });
        }

        if (isEqual(newStore, previousStore)) {
          return prevState;
        }

        // First we mutate the state directly to avoid race conditions
        prevState[storeName] = newStore;

        // Then we return a new object to React to trigger re-rendering of dependent components
        return {
          ...prevState,
          [storeName]: newStore,
        };
      });
    };
  }, [setState]);

  useEffect(() => {
    Stores.reset = <TData,>(storeName: string, data: TData) => {
      setState((prevState) => {
        const previousStore = prevState[storeName] || {};

        if (isEqual(data, previousStore)) {
          return prevState;
        }

        prevState[storeName] = data;

        return {
          ...prevState,
          [storeName]: data,
        };
      });
    };
  }, [setState]);

  useEffect(() => {
    if (initializeCallback) {
      initializeCallback();
    }
  }, []);

  let newChildren = children;

  // Set the current value of the Stores, based on the State, through the Provider
  for (const storeName in Stores.storeProviders) {
    const Provider = Stores.storeProviders[storeName];

    newChildren = <Provider value={state[storeName]}>{newChildren}</Provider>;
  }

  return newChildren;
}
