import TextField from '@mui/material/TextField';
import React from 'react';
import { connectField } from 'uniforms';

function CommaSeparatedField({ value, onChange, label, required, disabled }) {
  const handleOnChange = (incomingValue) => {
    onChange(incomingValue.split(',').map((item) => item.trim()));
  };

  return (
    <TextField
      variant="outlined"
      value={value.join(',')}
      required={required}
      disabled={disabled}
      onChange={(event) => handleOnChange(event.target.value)}
      label={label}
      fullWidth
    />
  );
}

export default connectField(CommaSeparatedField, { initialValue: true });
