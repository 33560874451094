import { Check as CheckIcon, Clear as ClearIcon, QuestionMark as QuestionMarkIcon } from '@mui/icons-material';
import React from 'react';

import { RenderProps } from './types';

export default function BooleanRenderer({ value }: RenderProps) {
  switch (value) {
    case true:
      return <CheckIcon color="primary" />;
    case false:
      return <ClearIcon color="error" />;
    default:
      return <QuestionMarkIcon color="disabled" fontSize="small" />;
  }
}
