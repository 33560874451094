import AuthLayout from '@frontend/components/auth/Layout/AuthLayout';
import Notistack from '@frontend/lib/notistack';
import { forgotPassword } from '@frontend/stores/UserStore';
import { Button } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import FormikTextField from '../FormikTextField';

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

function DefaultForgotPasswordPage() {
  const { t } = useTranslation();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>) => {
    forgotPassword(values.email)
      .then(() => {
        resetForm();
        Notistack.toast(t('auth:forgotPassword.sent'), { variant: 'success' });
      })
      .catch((e) => {
        if (e?.request?.status === 404) {
          setFieldError('email', t('auth:forgotPassword.error.emailNotFound'));
        } else {
          Notistack.toast(t([e?.response?.data?.message, 'auth:forgotPassword.error.unknown']), { variant: 'error' });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <AuthLayout heading={t('auth:forgotPassword.title')}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('auth:forgotPassword.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      <p>
        <Link to="/login">{t('auth:links.login')}</Link>
      </p>
    </AuthLayout>
  );
}

export default DefaultForgotPasswordPage;
