import { PaletteMode, ThemeOptions } from '@mui/material';

type CreateReplanThemeOptions = {
  mode: PaletteMode;
};

const createReplanTheme = ({ mode }: CreateReplanThemeOptions): ThemeOptions => {
  const replanTheme: ThemeOptions = {
    palette: {
      mode,
      primary: {
        main: mode === 'light' ? '#ff0953' : '#ce0e47',
      },
      text: {
        primary: mode === 'light' ? '#636b6f' : '#eee',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightMedium: 600,
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: 48,
        fontWeight: 400,
        lineHeight: 1.36,
      },
      h2: {
        fontSize: 32,
        fontWeight: 400,
        lineHeight: 1.36,
      },
      h3: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1.36,
      },
      body1: {
        fontSize: 16,
        lineHeight: 1.36,
      },
      body2: {
        fontSize: 14,
        lineHeight: 1.36,
      },
    },
  };

  if (mode === 'light') {
    replanTheme.palette = {
      background: {
        default: '#f8f8f8',
      },
    };
  }

  return replanTheme;
};

export default createReplanTheme;
