import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

type PageHeadingProps = {
  title: string;
  right?: React.ReactNode;
  arrowBack?: boolean;
  className?: string;
  textClassName?: string;
};

export default function PageHeading({ title, right, arrowBack = false, className, textClassName }: PageHeadingProps) {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2, minHeight: 60 }} className={className}>
        {arrowBack && (
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}

        <Typography color="text.primary" variant="h1" sx={{ flexGrow: 1, fontSize: '2.4rem', fontWeight: 700 }} className={textClassName}>
          {title}
        </Typography>

        {!!right && right}
      </Box>
    </>
  );
}
