import auth from './auth.json';
import budgets from './budgets.json';
import costsOverview from './costsOverview.json';
import crud from './crud.json';
import daysOff from './daysOff.json';
import entities from './entities.json';
import entityFields from './entityFields';
import headerLinks from './headerLinks.json';
import hoursOverview from './hoursOverview.json';
import logHours from './logHours.json';
import menu from './menu.json';
import messages from './messages.json';
import notFound from './notFound.json';
import quickSetup from './quickSetup.json';
import strings from './strings.json';
import tenants from './tenants.json';
import users from './users.json';

export default {
  auth,
  budgets,
  costsOverview,
  crud,
  daysOff,
  entities,
  entityFields,
  headerLinks,
  hoursOverview,
  logHours,
  menu,
  messages,
  notFound,
  quickSetup,
  strings,
  tenants,
  users,
};
