import React from 'react';
import { Container } from '@mui/material';
import RegisteredHours from '../RegisteredHours/RegisteredHours';
import CreateLoggedHours from '../LoggingHours/CreateLoggedHours';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  main: {
    gap: theme.spacing(4),

    gridTemplateColumns: 'minmax(320px, 1fr) 2fr',
    display: 'grid',

    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(4),
      flexWrap: 'wrap',
      display: 'flex',
    },
  },
}));

function HomePage() {
  const { classes } = useStyles();

  return (
    <Container className={classes.main} maxWidth="xl">
      <CreateLoggedHours />
      <RegisteredHours />
    </Container>
  );
}

export default HomePage;
