import PageCard from '@boilerplate/components/PageCard';
import Sanitized from '@boilerplate/components/Sanitized';
import { getTranslatedEntityName } from '@boilerplate/components/entity/helpers';
import { saveFileWithUrl } from '@boilerplate/lib/files';
import EntityLinks from '@entity/EntityLink/EntityLinks';
import { Check as CheckIcon, Clear as ClearIcon, Delete as DeleteIcon, Download as DownloadIcon } from '@mui/icons-material';
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, IconButton } from '@mui/material';
import { sentenceCase, snakeCase } from 'change-case';
import pluralize from 'pluralize';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Entity from '@/entities/tenant';
import Notistack from '@/lib/notistack';
import { canPerformAction, impersonateUser, inviteUser, useAuthenticatedUserStore } from '@/stores/UserStore';

import type { TFunction } from 'i18next';

const useStyles = makeStyles()(() => ({
  table: {
    minWidth: 650,
  },
}));

function TenantBaseView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { classes } = useStyles();
  const user = useAuthenticatedUserStore();
  const { t } = useTranslation();

  const { data } = Entity.model.useGet({
    variables: { id },
  });

  const canDelete = useMemo(() => canPerformAction('delete', 'tenant', user), [user]);
  const heading = useMemo(() => getTranslatedEntityName(t, 'tenant', 'view'), [t]);

  const deleteTenant = function () {
    Entity.model
      .delete({ variables: { id } })
      .then(() => {
        navigate('/admin/tenant');
      })
      .catch((e) => {
        console.error(e);
        Notistack.toast(e);
      });
  };

  if (!data || !data.tenant) {
    return <CircularProgress />;
  }

  return (
    <PageCard
      heading={heading}
      headingRight={
        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={deleteTenant} disabled={!canDelete} >
          {t('crud:delete')}
        </Button>
      }
      noBody
      arrowBack
    >
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableBody>
            {Entity.simpleSchema.objectKeys().map((key) => getTableRow(key, data.tenant, { t }))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageCard>
  );
};

const getTableRow = function (key: string, data: Record<string, unknown>, { t }: { t: TFunction }) {
  if (Entity.simpleSchema.get(key, 'view') === false) {
    return null;
  }

  const form = Entity.simpleSchema.getDefinition(key)?.form ?? {};
  const fieldTypes = Entity.simpleSchema.get(key, 'type').map((type: { type: { name: string } }) => type.type.name);
  const isSelect = Array.isArray(Entity.simpleSchema.getAllowedValuesForKey(key));
  const isRichText = form.component === 'RichText';
  const isUpload = form.component === 'Upload';
  const isArray = fieldTypes.includes('Array');

  const value = data[isArray ? pluralize.plural(key) : key];

  return (
    !key.endsWith('.$') && (
      <TableRow key={key}>
        <TableCell key={key} component="th" scope="row">
          {Entity.simpleSchema.label(key)}
        </TableCell>
        <TableCell align="left">{getPrintValue(value, { isSelect, isRichText, isUpload, key, t })}</TableCell>
      </TableRow>
    )
  );
};

const getPrintValue = function (
  value: any,
  {
    isRichText = false,
    isSelect = false,
    isUpload = false,
    key,
    t
  }: { isRichText?: boolean; isSelect?: boolean; isUpload?: boolean; key: string; t: TFunction }
) {
  if (isRichText) {
    return <Sanitized>{value}</Sanitized>;
  }

  if (isSelect) {
    return value ? t(`entityFields:${Entity.name}.$${snakeCase(key)}_options.${value}`, value) : '-';
  }

  if (isUpload) {
    const handleDownload = () => {
      saveFileWithUrl(value).catch((err) => {
        console.error(err);
        Notistack.toast(err);
      });
    };

    return (
      <>
        {value}
        <IconButton color="primary" size="small" onClick={handleDownload} sx={ { marginLeft: 1 } }>
          <DownloadIcon fontSize="small" />
        </IconButton>
      </>
    );
  }

  if (typeof value === 'object') {
    return <EntityLinks value={value} />;
  }

  if (value === true) {
    return <CheckIcon color="primary" fontSize="small" />;
  }

  if (value === false) {
    return <ClearIcon color="disabled" fontSize="small" />;
  }

  // For a primitive type
  if (value != null) {
    return value;
  }

  // No data
  return <span>-</span>;
};

export default TenantBaseView;
