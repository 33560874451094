import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  Email as EmailIcon,
  Login as LoginIcon,
  PersonAdd as PersonAddIcon,
  PersonOff as PersonOffIcon,
} from '@mui/icons-material';
import { ActionButtonBase } from '@boilerplate/components/entity/EntityTable/CellRenderers/ActionButton';
import type UserEntity from '@/entities/user';
import Notistack from '@/lib/notistack';
import { User } from '@/graphql';
import {
  canPerformAction,
  hasPermission,
  impersonateUser,
  inviteUser,
  useAuthenticatedUserStore,
} from '@/stores/UserStore';
import { CellRenderers } from '@/components/entity';
import { RenderProps, Actions } from '@boilerplate/components/entity/EntityTable/CellRenderers/types';
import config from '@/config';
import { camelCase } from 'lodash';

const { ActionButtons: DefaultActionButtons } = CellRenderers;

export type CustomActionButtonsProps = {
  Entity: typeof UserEntity;
  user: User;
  actions: Actions;
  icons?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
};

export function CustomActionButtons({ Entity, user, actions, icons, variant }: CustomActionButtonsProps) {
  const authenticatedUser = useAuthenticatedUserStore();
  const { t } = useTranslation();

  const { id: authUserId } = authenticatedUser;

  const [loadingImpersonate, setLoadingImpersonate] = useState(false);

  const [update, { loading }] = Entity.model.useUpdate();

  const isActive = user.active === true;

  const [canUpdate, canImpersonate] = useMemo(
    () => [
      canPerformAction('update', camelCase(Entity.name), authenticatedUser),
      hasPermission('impersonate', authenticatedUser),
    ],
    [Entity.name, authenticatedUser]
  );

  const handleChangeActivation = () => {
    update({ variables: { id: user.id, active: !isActive } })
      .then(() => actions.refetch())
      .catch((err: Error) => {
        Notistack.toast(t('users:table.activationChangeError'), { variant: 'error' });
        console.error(err);
      });
  };

  const handleImpersonate = () => {
    setLoadingImpersonate(true);
    impersonateUser(user.email)
      .then(() => {
        Notistack.toast(t('users:table.impersonateSuccess', user), { variant: 'success' });
      })
      .catch((err) => {
        Notistack.toast(`${t('users:table.impersonateError')}: ${err.message ?? err.toString()}`, {
          variant: 'error',
        });
        console.error(err);
      })
      .finally(() => {
        setLoadingImpersonate(false);
      });
  };

  const handleInvite = () => {
    inviteUser(user.email, user.name)
      .then(() => {
        Notistack.toast(t('users:invite.success'), { variant: 'success' });
      })
      .catch(() => {
        Notistack.toast(t('users:invite.error'), { variant: 'error' });
      });
  };

  if (icons) {
    return (
      <>
        {!!config.auth.impersonateEnabled && (
          <ActionButtonBase
            icon={<LoginIcon />}
            onClick={handleImpersonate}
            disabled={!canImpersonate || loadingImpersonate || user.id === authUserId}
          >
            {t('users:table.impersonate')}
          </ActionButtonBase>
        )}

        <ActionButtonBase icon={<EmailIcon />} onClick={handleInvite} disabled={!canUpdate}>
          {t('users:invite.label')}
        </ActionButtonBase>

        <ActionButtonBase
          disabled={!canUpdate || loading || user.id === authUserId}
          icon={isActive ? <PersonOffIcon /> : <PersonAddIcon />}
          onClick={handleChangeActivation}
        >
          {isActive ? t('users:table.deactivate') : t('users:table.activate')}
        </ActionButtonBase>
      </>
    );
  }

  return (
    <>
      {!!config.auth.impersonateEnabled && (
        <Button
          disabled={!canImpersonate || loadingImpersonate || user.id === authUserId}
          onClick={handleImpersonate}
          startIcon={<LoginIcon />}
          variant={variant}
        >
          {t('users:table.impersonate')}
        </Button>
      )}

      <Button variant={variant} onClick={handleInvite} startIcon={<EmailIcon />} disabled={!canUpdate}>
        {t('users:invite.label')}
      </Button>

      <Button
        variant={variant}
        onClick={handleChangeActivation}
        disabled={!canUpdate || loading || user.id === authUserId}
        startIcon={isActive ? <PersonOffIcon /> : <PersonAddIcon />}
      >
        {isActive ? t('users:table.deactivate') : t('users:table.activate')}
      </Button>
    </>
  );
}

export default function ActionButtons(props: RenderProps<typeof UserEntity.model>) {
  const { Entity, actions, row } = props;
  const authenticatedUser = useAuthenticatedUserStore();

  return (
    <DefaultActionButtons {...props} deleteDisabled={row.id === authenticatedUser.id}>
      <CustomActionButtons Entity={Entity} actions={actions} user={row as User} icons />
    </DefaultActionButtons>
  );
}
