import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EntityLink from '@entity/EntityLink/EntityLink';

import { RenderProps } from './types';

export default function AutoRenderer({ value = false, row, Entity }: RenderProps): JSX.Element {
  const { t } = useTranslation();

  const items = useMemo(() => {
    if (!value || typeof value !== 'object') {
      return null;
    }

    if ('items' in value && value.items) {
      return value.items as unknown[];
    }

    return [value];
  }, [value]);

  if (!items) {
    if (typeof value === 'string' && value.length > 144) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{`${value.slice(0, 144)}...`}</>;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{value}</>;
  }

  if (items.length === 0) {
    return <span>-</span>;
  }

  if (items.length === 1) {
    return <EntityLink data={items[0]} />;
  }

  const typeName = pluralize.plural(items[0].__typename);

  return (
    <Link to={`/admin/${Entity.name}/${row.id}`}>
      {`${value.totalCount ?? items.length} ${t(`entities:${typeName.toLowerCase()}`, typeName).toLocaleLowerCase()}`}
    </Link>
  );
}
