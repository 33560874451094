import { TextField, TextFieldProps } from '@mui/material';
import { useField, FieldHookConfig } from 'formik';
import React, { forwardRef } from 'react';

export default forwardRef<HTMLInputElement, TextFieldProps & FieldHookConfig<string>>(function FormikTextField(props, ref) {
  const [field, meta] = useField(props);

  return (
    <TextField
      id={props.name}
      {...field}
      {...props}
      margin="normal"
      variant="outlined"
      fullWidth
      inputProps={{ ref }}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched && meta.error}
    />
  );
});
