import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetDaysOffDocument,
  GetDaysOffQuery,
  GetDaysOffQueryVariables,
  GetDaysOffsDocument,
  GetDaysOffsQuery,
  GetDaysOffsQueryVariables,
  useGetDaysOffQuery,
  useGetDaysOffLazyQuery,
  useGetDaysOffsQuery,
  useGetDaysOffsLazyQuery,

    useGetAllRelatedDataForDaysOffQuery,
    useGetAllRelatedDataForDaysOffLazyQuery,
    GetAllRelatedDataForDaysOffQuery,
    GetAllRelatedDataForDaysOffQueryVariables,

  CreateDaysOffDocument,
  CreateDaysOffMutation,
  CreateDaysOffMutationVariables,
  useCreateDaysOffMutation,

  DeleteDaysOffDocument,
  DeleteDaysOffMutation,
  DeleteDaysOffMutationVariables,
  useDeleteDaysOffMutation,

  UpdateDaysOffDocument,
  UpdateDaysOffMutation,
  UpdateDaysOffMutationVariables,
  useUpdateDaysOffMutation,

    useCreatedDaysOffSubscription,
    useUpdatedDaysOffSubscription,
    useDeletedDaysOffSubscription,
    useRestoredDaysOffSubscription,
} from '@/graphql';

  type DaysOffCollection = Dictionary<NonNullable<GetDaysOffsQuery['daysOffs']['items']>[number]>;

const DaysOffBaseModel = {
  get: (options: Omit<QueryOptions<GetDaysOffQueryVariables, GetDaysOffQuery>, 'query'>) => {
    return apolloClient.query<GetDaysOffQuery, GetDaysOffQueryVariables>({
      ...options,
      query: GetDaysOffDocument,
    })
    .then(({ data }) => data.daysOff);
  },

  useGet: useGetDaysOffQuery,

  getAll: (options?: Omit<QueryOptions<GetDaysOffsQueryVariables, GetDaysOffsQuery>, 'query'>) => {
    return apolloClient
      .query<GetDaysOffsQuery, GetDaysOffsQueryVariables>({
        ...options,
        query: GetDaysOffsDocument
      })
      .then(({ data }) => data.daysOffs.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetDaysOffsQuery, GetDaysOffsQueryVariables>) => {
    const hookResult = useGetDaysOffsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.daysOffs?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForDaysOffQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForDaysOffQuery, GetAllRelatedDataForDaysOffQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForDaysOffQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetDaysOffLazyQuery,

  useGetAllLazy: useGetDaysOffsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForDaysOffLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateDaysOffMutation, CreateDaysOffMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateDaysOffMutation, CreateDaysOffMutationVariables>({
      ...options,
      mutation: CreateDaysOffDocument,
    });
  },

  useCreate: useCreateDaysOffMutation,

  update: (options: Omit<MutationOptions<UpdateDaysOffMutation, UpdateDaysOffMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateDaysOffMutation, UpdateDaysOffMutationVariables>({
      ...options,
      mutation: UpdateDaysOffDocument,
    });
  },

  useUpdate: useUpdateDaysOffMutation,

  delete: (options: Omit<MutationOptions<DeleteDaysOffMutation, DeleteDaysOffMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteDaysOffMutation, DeleteDaysOffMutationVariables>({
      ...options,
      mutation: DeleteDaysOffDocument,
    });
  },

  useDelete: useDeleteDaysOffMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetDaysOffsQuery, GetDaysOffsQueryVariables>) => {
      const [collection, setCollection] = useState<DaysOffCollection>({});

      const { items, loading, error, refetch } = DaysOffBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedDaysOffSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdDaysOff?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdDaysOff.id]: data.createdDaysOff,
            }));
          }
        },
      });

      useUpdatedDaysOffSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedDaysOff?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedDaysOff.id]: data.updatedDaysOff,
            }));
          }
        },
      });

      useDeletedDaysOffSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedDaysOff?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedDaysOff.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredDaysOffSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredDaysOff?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredDaysOff.id]: data.restoredDaysOff,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default DaysOffBaseModel;
