import TextField from '@mui/material/TextField';
import React from 'react';
import { connectField } from 'uniforms';

function NumberField({ value, onChange, label, disabled, required }) {
  return (
    <TextField
      value={value}
      onChange={(event) => onChange(parseInt(event.target.value))}
      label={label}
      required={required}
      disabled={disabled}
      type="number"
      variant="outlined"
      fullWidth
    />
  );
}

export default connectField(NumberField);
