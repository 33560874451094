import React from 'react';
import { merge } from 'lodash';
import i18n from '@/bootstrap/lib/i18n';
import createStore from './store/createStore';

type Action = {
  handle?: () => void;
  text?: string;
};

type ConfirmationDialogStoreType = {
  title: string;
  text?: string;
  denial: Action;
  approval: Action;
  open: boolean;
};

const defaultData = {
  open: false,
  title: i18n.t('strings:confirm_dialog.title'),
  text: undefined,
  denial: {
    handle: undefined,
    text: i18n.t('strings:no'),
  },
  approval: {
    handle: undefined,
    text: i18n.t('strings:yes'),
  },
};

const [ConfirmationDialogStore, useConfirmationDialogStore] = createStore<ConfirmationDialogStoreType>(
  'confirmationDialog',
  defaultData
);

export const openConfirmationDialog = (params: Omit<Partial<ConfirmationDialogStoreType>, 'open'> = {}) => {
  ConfirmationDialogStore.set(merge({}, defaultData, params, { open: true }));
};

export default ConfirmationDialogStore;
export { useConfirmationDialogStore };
