import axios from 'axios';

import config from '@/config';

import { getToken, isCsrfMethod } from './csrf';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.app.backendUrl;

if (config.auth.csrfEnabled) {
  axios.interceptors.request.use(async (options) => {
    if (options.method && isCsrfMethod(options.method)) {
      if (!options.data) {
        options.data = {};
      }

      options.data._csrf = await getToken();
    }

    return options;
  });
}

export default axios;
