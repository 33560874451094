import { GetDaysOffsQuery, GetDaysOffsQueryVariables } from '@/graphql';
import { ApolloQueryResult } from '@apollo/client';
import { Button, Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DaysOffForm from './DaysOffForm';

type CreateDaysOffDialogProps = {
  refetch: (variables?: GetDaysOffsQueryVariables) => Promise<ApolloQueryResult<GetDaysOffsQuery>>;
};

function DaysOffDialog({ refetch }: CreateDaysOffDialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button onClick={handleOpen}> {t('daysOff:request.request')} </Button>
      <Dialog maxWidth="sm" open={open} onClose={handleClose} fullWidth>
        <DaysOffForm refetch={refetch} handleClose={handleClose} />
      </Dialog>
    </>
  );
}

export default DaysOffDialog;
