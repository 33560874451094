import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const UserTenantRoleBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:userTenantRole.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'user',
        label: t(['entityFields:userTenantRole.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'tenant',
        label: t(['entityFields:userTenantRole.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'role',
        label: t(['entityFields:userTenantRole.role', 'entityFields:default.role'], 'role') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
];

const UserTenantRoleBaseTableSchema: TableSchema[] = [
  ...UserTenantRoleBaseTableSchemaWithoutActions,
  actions,
];

export { actions, UserTenantRoleBaseTableSchemaWithoutActions };
export default UserTenantRoleBaseTableSchema;
