import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Error logging
import initializeSentry from './lib/sentry';

// Translations
import './lib/i18n';

// Data transfer
import './lib/axios';
import apolloClient from './lib/apolloClient';

import Root from '@/components/root';

initializeSentry();

const container = document.getElementById('root')!;
const component = (
  <BrowserRouter>
    {/* https://github.com/vazco/uniforms/issues/1194 */}
    {/* <React.StrictMode> */}
    <Root apolloClient={apolloClient} />
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

if (container.childElementCount > 0) {
  hydrateRoot(container, component);
} else {
  createRoot(container).render(component);
}
