import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import CategoryBaseModel from '@/_base/category/CategoryBaseModel';
import CategoryBaseOrderFormSchema from '@/_base/category/CategoryBaseOrderFormSchema';
import CategoryBaseSimpleSchema from '@/_base/category/CategoryBaseSimpleSchema';
import CategoryBaseTableSchema from '@/_base/category/CategoryBaseTableSchema';
import CategoryBaseInsertForm from '@/_base/category/components/CategoryBaseInsertForm';
import CategoryBaseUpdateForm from '@/_base/category/components/CategoryBaseUpdateForm';
import CategoriesBaseTable from '@/_base/category/components/CategoryBaseTable';
import CategoryBaseView from '@/_base/category/components/CategoryBaseView';

const CategorySimpleSchema = CategoryBaseSimpleSchema.extend({
  projects: {
    optional: false,
    minCount: 1,
  },
});

const CategoryEntity: Entity<typeof CategoryBaseModel> = {
  name: 'category',

  simpleSchema: CategorySimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(CategorySimpleSchema),
    orderSchema: [...CategoryBaseOrderFormSchema],
    component: CategoryBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(CategorySimpleSchema),
    orderSchema: [...CategoryBaseOrderFormSchema],
    component: CategoryBaseUpdateForm,
  },

  view: {
    component: CategoryBaseView,
  },

  table: {
    schema: CategoryBaseTableSchema,
    component: CategoriesBaseTable,
  },

  routes: {},

  model: CategoryBaseModel,

  settings: {
    displayField: 'name',
  },
};

export default CategoryEntity;
