// Icons
import FormatBold from '@mui/icons-material/FormatBold';
import FormatClear from '@mui/icons-material/FormatClear';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import Link from '@mui/icons-material/Link';
import LinkOff from '@mui/icons-material/LinkOff';
import StrikethroughS from '@mui/icons-material/StrikethroughS';
import { Button, ButtonGroup, Grid, Divider } from '@mui/material';
import { Editor, Content, HTMLContent, JSONContent } from '@tiptap/core';
import LinkExtension from '@tiptap/extension-link';
import UnderlineExtension from '@tiptap/extension-underline';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import React from 'react';

import Fieldset from './Fieldset';

export const getText = (json: JSONContent) => {
  const getContent = (value: JSONContent) => {
    if (!value.content) {
      return '';
    }

    return value.content.flatMap((content) => [content.text || ''].concat(getContent(content)));
  };

  const content = getContent(json);

  return content.join('').trim();
};

function setLink(editor: Editor) {
  const previousUrl = editor.getAttributes('link').href;
  const url = window.prompt('URL', previousUrl);

  // cancelled
  if (url === null) {
    return;
  }

  // empty
  if (url === '') {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();

    return;
  }

  // update link
  editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
}

function MenuBar({ editor }: { editor: Editor }) {
  if (!editor) {
    return null;
  }

  const isSelectionOverLink = editor.getAttributes('link').href;

  return (
    <Grid container gap={1} flexDirection="row">
      <ButtonGroup variant="outlined">
        <Button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is-active' : ''}>
          <FormatBold fontSize="small" />
        </Button>
        <Button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is-active' : ''}>
          <FormatItalic fontSize="small" />
        </Button>
        <Button onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'is-active' : ''}>
          <FormatUnderlined fontSize="small" />
        </Button>
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        <Button onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'is-active' : ''}>
          <StrikethroughS fontSize="small" />
        </Button>
        <Button onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}>
          <FormatClear fontSize="small" />
        </Button>
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        <Button onClick={() => setLink(editor)}>
          <Link fontSize="small" />
        </Button>
        <Button disabled={!isSelectionOverLink} onClick={() => editor.chain().focus().unsetLink().run()}>
          <LinkOff fontSize="small" />
        </Button>
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          H1
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          H2
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          H3
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          H4
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          H5
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          H6
        </Button>
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        <Button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'is-active' : ''}>
          <FormatListBulleted fontSize="small" />
        </Button>
        <Button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive('orderedList') ? 'is-active' : ''}>
          <FormatListNumbered fontSize="small" />
        </Button>
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>Divider</Button>
      </ButtonGroup>
    </Grid>
  );
}

export enum ResultType {
  HTML,
  JSON,
  RAW,
}

type ResultByType = {
  [ResultType.HTML]: HTMLContent;
  [ResultType.JSON]: JSONContent;
  [ResultType.RAW]: Editor;
};

type RichTextEditorProps<TResultType extends ResultType> = {
  onChange: (value: ResultByType[TResultType]) => void;
  value: Content;
  label: string;
  resultType?: TResultType;
  required?: boolean;
  noWrapper?: boolean;
};

function RichTextEditor<TResultType extends ResultType = ResultType.HTML>({
  onChange,
  value,
  label,
  resultType = ResultType.HTML as TResultType,
  required = false,
  noWrapper = false,
}: RichTextEditorProps<TResultType>) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      UnderlineExtension,
      LinkExtension.extend({
        addKeyboardShortcuts() {
          return {
            'Mod-Shift-k': () => setLink(this.editor),
          };
        },
      }),
    ],
    content: value,
    onUpdate: (edit) => {
      onChange(
        (() => {
          switch (resultType) {
            case ResultType.JSON:
              return edit.editor.getJSON();
            case ResultType.RAW:
              return edit.editor;
            case ResultType.HTML:
            default:
              return edit.editor.getHTML();
          }
        })()
      );
    },
  });

  if (!editor) {
    return null;
  }

  const content = (
    <>
      <MenuBar editor={editor} />
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <EditorContent editor={editor} style={{ padding: '4px' }} />
    </>
  );

  if (noWrapper) {
    return content;
  }

  return (
    <Fieldset label={label} style={{ position: 'relative' }} required={required}>
      {content}
    </Fieldset>
  );
}

export default RichTextEditor;
