import * as Sentry from '@sentry/browser';
import axios, { AxiosError } from 'axios';

import { wsClient } from '@/bootstrap/lib/apollo';
import apolloClient from '@/bootstrap/lib/apolloClient';
import i18n from '@/bootstrap/lib/i18n';
import { PermissionType, typePermissions } from '@/entities/permission/type';
import { UserType } from '@/entities/user/type';

import createStore from './store/createStore';
import { StoreType } from './store/types';

const broadcastChannel = new BroadcastChannel('auth_channel');

export type UnauthenticatedUser = {
  isAuthenticated: null | false;
};

export type AuthenticatedUser = {
  isAuthenticated: true;
  isSuperAdmin: boolean;
  permissionIds?: Set<PermissionType['id']>;
} & UserType;

export type TenantifiedUser = {
  currentTenant: NonNullable<UserType['currentTenant']>;
} & Omit<AuthenticatedUser, 'currentTenant'>;

export type UserStoreType = UnauthenticatedUser | AuthenticatedUser | TenantifiedUser;

export type ServerUserDataType = {
  isAuthenticated: true;
  permissionIds?: PermissionType['id'][];
} & UserType;

const initialData = {
  isAuthenticated: null,
};

const [UserStore, useUserStore] = createStore<UserStoreType>('user', initialData);

export function useAuthenticatedUserStore(): AuthenticatedUser {
  return useUserStore() as AuthenticatedUser;
}

export function useTenantifiedUserStore(): TenantifiedUser {
  return useUserStore() as TenantifiedUser;
}

const setUserPermissions = (user: ServerUserDataType): UserStoreType => {
  const permissionIds = (() => {
    if (Array.isArray(user.permissionIds)) {
      return user.permissionIds;
    }

    if (!user.roles) {
      return [];
    }

    return user.roles.flatMap((role) => role.permissions?.map((permission) => permission.id) ?? []);
  })();

  return {
    ...user,
    currentTenant: user.currentTenant!,
    permissionIds: new Set(permissionIds),
  };
};

const loginDataTransformers: ((loginData: ServerUserDataType) => UserStoreType)[] = [setUserPermissions];

export function fetchUser() {
  return axios
    .get('/auth/user')
    .then(({ data }) => loginHandler(data))
    .catch((error) => {
      logoutHandler();

      throw error;
    });
}

if (typeof window !== 'undefined') {
  fetchUser().catch(console.error);
}

export function impersonateUser(email: string) {
  return axios.post('/auth/impersonate', { email }).then(({ data }) => {
    wsClient.terminate();

    loginHandler(data);

    return data;
  });
}

export function inviteUser(email: string, name: string): Promise<void> {
  return axios.post('/auth/invite', { email, name }).then(({ data }) => data);
}

export function loginUser(
  email: string,
  password: string,
  totpToken?: string
): Promise<{ user: UserType; totpRequired: false } | { totpRequired: true }> {
  return axios
    .post('/auth/login', { email, password, totpToken })
    .then(({ data }) => {
      wsClient.terminate();

      loginHandler(data);

      return { ...data, totpRequired: false };
    })
    .catch((err: AxiosError<{ message?: string }>) => {
      if (err.response?.data.message === 'auth:validateUser.totpTokenRequired') {
        return { totpRequired: true };
      }

      throw err;
    });
}

export function registerUser(
  name: string,
  email: string,
  password: string,
  termsAndConditions?: boolean
): Promise<UserType & { confirmAccount: boolean }> {
  return axios
    .post('/auth/register', {
      name,
      email,
      password,
      termsAndConditions,
      locale: i18n.language,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    .then(({ data }) => {
      if (!data.confirmAccount) {
        loginHandler(data);
      }

      return data;
    });
}

const afterLoginHandlers: ((user: AuthenticatedUser) => void)[] = [
  (user) => broadcastChannel.postMessage({ type: 'login', data: { userId: user.id } }),
];

export function loginHandler(data: ServerUserDataType) {
  const serverData: ServerUserDataType = {
    ...data,
    isAuthenticated: true,
  };

  const user = loginDataTransformers.reduce<UserStoreType>((obj, func) => ({ ...obj, ...func(serverData) }), serverData as UserStoreType);
  const currentTenant = (() => {
    if ('tenants' in user) {
      return user.tenants.find((tenant) => tenant.id === localStorage.getItem('last-tenant'));
    }

    return null;
  })();

  const newUser = {
    ...user,
    isAuthenticated: true,
    currentTenant,
  } as AuthenticatedUser;

  UserStore.reset(newUser);

  Sentry.setUser({
    id: data.id,
    email: data.email,
  });

  afterLoginHandlers.forEach((handler) => handler(newUser));

  return newUser;
}

export async function setTenant(tenant?: AuthenticatedUser['currentTenant']) {
  if (tenant) {
    axios.defaults.headers.common['flystart-tenant'] = tenant.id;
    localStorage.setItem('last-tenant', tenant.id);
  } else {
    delete axios.defaults.headers.common['flystart-tenant'];
    localStorage.removeItem('last-tenant');
  }

  await fetchUser();

  wsClient.terminate();

  // https://www.apollographql.com/docs/react/data/refetching/#refetch-recipes
  apolloClient?.refetchQueries({
    include: 'active',
  });
}

export function getTenant(): undefined | TenantifiedUser['currentTenant'] {
  return (UserStore as StoreType<AuthenticatedUser>).get('currentTenant');
}

export function onLogin(func: () => void) {
  if (UserStore.get('isAuthenticated')) {
    func();
  } else {
    afterLoginHandlers.push(func);
  }
}

export function forgotPassword(email: string) {
  return axios.post('/password/email', { email });
}

export function resetPassword(email: string, token: string, password: string, passwordConfirmation: string, termsAndConditions?: boolean) {
  return axios.post('/password/reset', {
    email,
    token,
    password,
    password_confirmation: passwordConfirmation,
    termsAndConditions,
  });
}

export function resendInvite(token: string) {
  return axios.post('/auth/resend-invite', { token });
}

export function uploadAvatar(email: string, file: File) {
  const formData = new FormData();

  formData.append('avatar', file, file.name);

  return axios.post('/users/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function updatePassword({ currentPassword, password }: { currentPassword: string; password: string }) {
  return axios.post('/users/password', { currentPassword, password });
}

export function logout() {
  return axios.post('/auth/logout').then(({ data }) => {
    if (data.wasImpersonating) {
      return {
        goToLogin: false,
      };
    }

    logoutHandler();

    return {
      goToLogin: true,
    };
  });
}

const logoutHandlers: (() => void)[] = [() => broadcastChannel.postMessage({ type: 'logout' })];

function logoutHandler() {
  UserStore.reset({ isAuthenticated: false });

  wsClient.terminate();

  Sentry.setUser(null);

  logoutHandlers.forEach((handler) => handler());
}

export function onLogout(func: () => void) {
  if (UserStore.get('isAuthenticated') === false) {
    func();
  } else {
    logoutHandlers.push(func);
  }
}

export function hasPermission(permissionId: PermissionType['id'], user?: UnauthenticatedUser | AuthenticatedUser) {
  if (!user?.isAuthenticated) {
    return false;
  }

  return user.permissionIds?.has(permissionId) ?? false;
}

export const canPerformAction = (() => {
  type PermissionTypeEntry = (typeof typePermissions)[number];
  type PermissionTypesByEntity = Record<string, Record<PermissionTypeEntry, boolean>>;

  const permissionCache = new WeakMap<AuthenticatedUser, PermissionTypesByEntity>();

  const normalizePermissions = (user: AuthenticatedUser): PermissionTypesByEntity => {
    const cachedValue = permissionCache.get(user);

    if (cachedValue) {
      return cachedValue;
    }

    if (!user.permissionIds) {
      return {};
    }

    const entityNames: string[] = [];
    // Removes all scopes from the permission keys
    const normalizedPermissions = [...user.permissionIds.keys()]
      .filter((permission) => permission.includes(':'))
      .map((permission) => {
        const [entityName, permissionDetails] = permission.split(':');

        entityNames.push(entityName);

        return `${entityName}:${permissionDetails.split('-')[0]}`;
      });

    // Group permission types ('create', 'update', 'delete', etc) by entity name
    const permissionTypesByEntity = Object.fromEntries(
      [...new Set(entityNames)].map((entityName) => {
        // For every permission type, check if the user has a permission of that type for this particular entity
        const typeDetails = Object.fromEntries(
          typePermissions.map((type) => [type, normalizedPermissions.includes(`${entityName}:${type}`)])
        ) as Record<PermissionTypeEntry, boolean>;

        return [entityName, typeDetails];
      })
    );

    permissionCache.set(user, permissionTypesByEntity);

    return permissionTypesByEntity;
  };

  return (action: PermissionTypeEntry, entityName: string, user: AuthenticatedUser): boolean => {
    const permissions = normalizePermissions(user);

    return permissions[entityName]?.[action] === true;
  };
})();

export function checkPasswordResetToken(token: string) {
  return axios.post('/password/checkToken', {
    token,
  });
}

export function confirmAccount(confirmationCode: string) {
  return axios.post(`/auth/confirm/${confirmationCode}`).catch((error) => {
    console.error(error);

    throw error;
  });
}

export function resendConfirmAccount(email: string) {
  return axios.post('/auth/resend-confirm', { email });
}

export type TwoFAInfo = { enabled: boolean; remainingBackupCodes: number; required: boolean };

export async function get2FAInfo(): Promise<TwoFAInfo> {
  return axios.get('/mfa/info').then(({ data }) => data);
}

{
  broadcastChannel.addEventListener('messageerror', console.error);
  broadcastChannel.addEventListener('message', (evt) => {
    if (!(typeof evt.data === 'object')) {
      return;
    }

    switch (evt.data.type) {
      case 'login':
        if ((UserStore as StoreType<AuthenticatedUser>).get('id') !== evt.data.data?.userId) {
          window.location.reload();
        }

        break;
      case 'logout':
        if (UserStore.get('isAuthenticated')) {
          window.location.reload();
        }

        break;
    }
  });
}

export { useUserStore };

export default UserStore;
