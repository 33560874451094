import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        active: {
          type: Boolean,
          defaultValue: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        projects: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'projects.$': {
          type: String,
          optional: true,
        },
        tenant: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'tenant',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:customer.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:customer.name', 'entityFields:default.name'], '') || undefined,
        active: t(['entityFields:customer.active', 'entityFields:default.active'], '') || undefined,
        projects: t(['entityFields:customer.project', 'entityFields:default.project'], '') || undefined,
        tenant: t(['entityFields:customer.tenant', 'entityFields:default.tenant'], '') || undefined,
        createdAt: t(['entityFields:customer.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:customer.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:customer.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;