import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

export type UpdateAvailableProps = {
  onClick: () => void;
};

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    flexDirection: 'column',
    marginLeft: spacing(1),
    display: 'flex',
    gap: spacing(1),
  },
  text: {
    marginLeft: 5,
  },
}));

function UpdateAvailable({ onClick }: UpdateAvailableProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{t('strings:updateNotification.message')}</Typography>
      <Button onClick={onClick} color="inherit" variant="text" size="small">
        {t('strings:updateNotification.button')}
      </Button>
    </div>
  );
}

export default UpdateAvailable;
