import React, { ComponentType, useContext } from 'react';
import { pick, upperFirst } from 'lodash';
import { StoreType } from './types';
import { preInitStoresData, Stores } from './Stores';

export default function createStore<TData extends Record<string, any>>(
  storeName: string,
  initialData: TData
): [
  store: StoreType<TData>,
  useStore: () => TData,
  withStore: <THocProps>(
    Component: ComponentType<THocProps & Partial<TData>>,
    keys?: keyof TData[]
  ) => (hocProps: THocProps) => JSX.Element
] {
  const preInitData = preInitStoresData[storeName];

  if (preInitData) {
    Object.assign(initialData, preInitData);
  }

  preInitStoresData[storeName] = initialData;

  const Store: StoreType<TData> = {
    name: storeName,

    set: <K extends keyof TData>(keyOrData: K | Partial<TData>, optionalData?: TData[K]) => {
      if (typeof keyOrData !== 'object') {
        if (optionalData !== undefined) {
          return Stores.set<TData>(storeName, keyOrData, optionalData);
        }

        throw new Error(`${storeName}Store.set requires data argument when key is given`);
      }

      return Stores.set<TData>(storeName, keyOrData);
    },

    reset: (data) => {
      return Stores.reset<TData>(storeName, data);
    },

    getAll: () => {
      return Stores.get<TData>(storeName) || {};
    },

    get: (key) => {
      return Store.getAll()[key];
    },
  };

  const StoreContext = React.createContext(initialData);
  StoreContext.displayName = `${upperFirst(storeName)}Store`;

  Stores.storeProviders[storeName] = StoreContext.Provider;

  return [
    Store,

    () => useContext(StoreContext),

    function withStore<THocProps>(Component: ComponentType<THocProps & Partial<TData>>, keys?: keyof TData[]) {
      function WithStore(hocProps: THocProps) {
        const store = useContext(StoreContext) || {};
        const extraProps: Partial<TData> & { store?: TData } = {};

        if (keys) {
          const entries = pick(store, keys);
          Object.assign(extraProps, entries);
        } else {
          extraProps.store = store;
        }

        return <Component {...hocProps} {...extraProps} />;
      }

      WithStore.displayName = `With${storeName}Store(${getDisplayName(Component)})`;

      return WithStore;
    },
  ];
}

function getDisplayName(WrappedComponent: ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
