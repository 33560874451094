import ClearIcon from '@mui/icons-material/Clear';
import { TextField, IconButton, Select, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterProps } from './types';

export default function TextFilter({ fieldName, filterValue, setFilter, fieldSchema }: FilterProps) {
  const { t } = useTranslation();

  const updateFilter = (value) => {
    setFilter(value);
  };

  if (fieldSchema.filterOptions) {
    return (
      <Select
        labelId={`${fieldName}-label`}
        id={fieldName}
        size="small"
        sx={{ minWidth: '12ch' }}
        value={filterValue || ''}
        onChange={(e) => updateFilter(e.target.value)}
        placeholder="Select"
      >
        <MenuItem value="">
          <i>{t('crud:all')}</i>
        </MenuItem>
        {fieldSchema.filterOptions.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <TextField
      value={filterValue || ''}
      size="small"
      sx={{ minWidth: '12ch' }}
      onChange={(e) => updateFilter(e.target.value)}
      InputProps={{
        endAdornment: filterValue && (
          <IconButton size="small" onClick={() => updateFilter('')}>
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
      placeholder={t('crud:search')}
      name={fieldName}
    />
  );
}
