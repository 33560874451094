import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import TenantBaseModel from '@/_base/tenant/TenantBaseModel';
import TenantBaseOrderFormSchema from '@/_base/tenant/TenantBaseOrderFormSchema';
import TenantBaseSimpleSchema from '@/_base/tenant/TenantBaseSimpleSchema';
import TenantBaseTableSchema from '@/_base/tenant/TenantBaseTableSchema';
import TenantBaseInsertForm from '@/_base/tenant/components/TenantBaseInsertForm';
import TenantBaseTable from '@/_base/tenant/components/TenantBaseTable';
import TenantBaseUpdateForm from '@/_base/tenant/components/TenantBaseUpdateForm';
import TenantBaseView from '@/_base/tenant/components/TenantBaseView';

const TenantEntity: Entity<typeof TenantBaseModel> = {
  name: 'tenant',

  simpleSchema: TenantBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(TenantBaseSimpleSchema),
    orderSchema: [...TenantBaseOrderFormSchema],
    component: TenantBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(TenantBaseSimpleSchema),
    orderSchema: [...TenantBaseOrderFormSchema],
    component: TenantBaseUpdateForm,
  },

  table: {
    schema: TenantBaseTableSchema,
    component: TenantBaseTable,
  },

  view: {
    component: TenantBaseView,
  },

  model: TenantBaseModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default TenantEntity;
