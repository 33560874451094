const schema = [
        ['name'],
        ['addToNewProjects'],
        ['projects'],
        ['hasHours'],
        ['hasInvoices'],
        ['parentCategory'],
        ['tenant'],
];

export default schema;
