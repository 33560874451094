import { passwordStrength, PasswordStrengthScore } from '@frontend/lib/passwordStrength';
import { Collapse } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  strength: PasswordStrengthScore;
}

const strengthColor = (strength: PasswordStrengthScore) => {
  switch (strength) {
    case 5:
      return '#04bb3a';
    case 4:
      return '#89c82d';
    case 3:
      return '#ffcb00';
    case 2:
      return '#ff6000';
    case 1:
    default:
      return 'red';
  }
};

const widthBar = (strength: PasswordStrengthScore) => {
  if (strength === 0) {
    return '0%';
  }

  return `${strength * 20}%`;
};

const textPosition = (strength: PasswordStrengthScore) => {
  if (strength === 1 || strength === 2) {
    return `${strength * 20}%`;
  }

  return 0;
};

const useStyles = makeStyles<StyleProps>()((theme, { strength }) => ({
  container: {
    position: 'relative',
    height: theme.spacing(5),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  strengthMeter: {
    position: 'absolute',
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: strengthColor(strength),
    width: widthBar(strength),
    transition: '.5s ease background-color, .2s ease width',
  },
  strengthText: {
    position: 'absolute',
    left: textPosition(strength),
    lineHeight: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    transition: '.2s ease left',
  },
}));

interface PasswordStrengthIndicatorProps {
  password: string;
}

function PasswordStrengthIndicator({ password }: PasswordStrengthIndicatorProps): JSX.Element {
  const { t } = useTranslation();
  const strength = useMemo(() => passwordStrength(password), [password]);
  const { classes } = useStyles({ strength });

  return (
    <Collapse in={!!password}>
      <div className={classes.container}>
        <div className={classes.strengthMeter} />
        <div className={classes.strengthText}>{t(`auth:passwordStrength.${strength}`)}</div>
      </div>
    </Collapse>
  );
}

export default PasswordStrengthIndicator;
