import { Paper } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import EditLoggedHourDialog from './EditLoggedHourDialog';
import RegisteredHoursDisplay from './RegisteredHoursDisplay';

import { useDeleteLoggedHourMutation, LoggedHour } from '@/graphql';
import Notistack from '@/lib/notistack';
import { openConfirmationDialog } from '@/stores/ConfirmationDialogStore';

export type ProcessedLoggedHoursType = { [key: string]: { totalTime: number; loggedHours: LoggedHour[] } };

const useStyles = makeStyles()(() => ({
  container: {
    overflow: 'hidden',
  },
}));

function RegisteredHours() {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [deleteLoggedHourMutation] = useDeleteLoggedHourMutation();

  const [editLoggedHourOpen, setEditLoggedHourOpen] = useState(false);
  const [loggedHourToEdit, setLoggedHourToEdit] = useState<LoggedHour | undefined>();

  const handleEdit = useCallback(
    (loggedHour?: LoggedHour) => {
      setEditLoggedHourOpen((prevEditLoggedHourOpen) => {
        setLoggedHourToEdit(!prevEditLoggedHourOpen ? loggedHour : undefined);

        return !prevEditLoggedHourOpen;
      });
    },
    [setLoggedHourToEdit, setEditLoggedHourOpen]
  );

  const deleteLoggedHour = useCallback(
    (loggedHour: LoggedHour) => {
      deleteLoggedHourMutation({
        variables: {
          id: loggedHour.id,
        },
      })
        .then(() => {
          Notistack.toast?.(t('logHours:message.success.delete'), { variant: 'success' });
        })
        .catch((err) => {
          console.error(err);
          Notistack.toast?.(t('logHours:message.error.delete'), { variant: 'error' });
        });
    },
    [t, deleteLoggedHourMutation]
  );

  const handleDelete = (loggedHour: LoggedHour) => {
    openConfirmationDialog({
      text: t('logHours:text.delete'),
      approval: {
        handle: () => deleteLoggedHour(loggedHour),
      },
    });
  };

  return (
    <Paper sx={{ width: '100%' }} className={classes.container}>
      <RegisteredHoursDisplay onEdit={handleEdit} onDelete={handleDelete} />
      <EditLoggedHourDialog open={editLoggedHourOpen} onClose={() => handleEdit()} loggedHour={loggedHourToEdit} />
    </Paper>
  );
}

export default RegisteredHours;
