const schema = [
        ['name'],
        ['active'],
        ['description'],
        ['startDate'],
        ['endDate'],
        ['customer'],
        ['categories'],
        ['tenant'],
];

export default schema;
