import React, { ReactNode } from 'react';

type TabPanelProps = {
  children: ReactNode;
  value: string | number;
  index: number;
};

const TabPanel = ({ children, value, index }: TabPanelProps) => {
  if (value !== index) {
    return null;
  }

  return <>{children}</>;
};

export default TabPanel;
