import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  GetUsersDocument,
  GetUsersQuery,
  GetUsersQueryVariables,
  useGetUserQuery,
  useGetUserLazyQuery,
  useGetUsersQuery,
  useGetUsersLazyQuery,

    useGetAllRelatedDataForUserQuery,
    useGetAllRelatedDataForUserLazyQuery,
    GetAllRelatedDataForUserQuery,
    GetAllRelatedDataForUserQueryVariables,

  CreateUserDocument,
  CreateUserMutation,
  CreateUserMutationVariables,
  useCreateUserMutation,

  DeleteUserDocument,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  useDeleteUserMutation,

  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  useUpdateUserMutation,

    useCreatedUserSubscription,
    useUpdatedUserSubscription,
    useDeletedUserSubscription,
    useRestoredUserSubscription,
} from '@/graphql';

  type UserCollection = Dictionary<NonNullable<GetUsersQuery['users']['items']>[number]>;

const UserBaseModel = {
  get: (options: Omit<QueryOptions<GetUserQueryVariables, GetUserQuery>, 'query'>) => {
    return apolloClient.query<GetUserQuery, GetUserQueryVariables>({
      ...options,
      query: GetUserDocument,
    })
    .then(({ data }) => data.user);
  },

  useGet: useGetUserQuery,

  getAll: (options?: Omit<QueryOptions<GetUsersQueryVariables, GetUsersQuery>, 'query'>) => {
    return apolloClient
      .query<GetUsersQuery, GetUsersQueryVariables>({
        ...options,
        query: GetUsersDocument
      })
      .then(({ data }) => data.users.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) => {
    const hookResult = useGetUsersQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.users?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForUserQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForUserQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetUserLazyQuery,

  useGetAllLazy: useGetUsersLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForUserLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateUserMutation, CreateUserMutationVariables>({
      ...options,
      mutation: CreateUserDocument,
    });
  },

  useCreate: useCreateUserMutation,

  update: (options: Omit<MutationOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
      ...options,
      mutation: UpdateUserDocument,
    });
  },

  useUpdate: useUpdateUserMutation,

  delete: (options: Omit<MutationOptions<DeleteUserMutation, DeleteUserMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteUserMutation, DeleteUserMutationVariables>({
      ...options,
      mutation: DeleteUserDocument,
    });
  },

  useDelete: useDeleteUserMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) => {
      const [collection, setCollection] = useState<UserCollection>({});

      const { items, loading, error, refetch } = UserBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedUserSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdUser?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdUser.id]: data.createdUser,
            }));
          }
        },
      });

      useUpdatedUserSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedUser?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedUser.id]: data.updatedUser,
            }));
          }
        },
      });

      useDeletedUserSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedUser?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedUser.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredUserSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredUser?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredUser.id]: data.restoredUser,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default UserBaseModel;
