import { Container } from '@mui/material';
import React from 'react';

import DefaultProfilePage from '@boilerplate/components/app/Profile/DefaultProfilePage';

export default function ProfilePage() {
  return (
    <Container>
      <DefaultProfilePage />
    </Container>
  );
}
