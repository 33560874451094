import Notistack from '@frontend/lib/notistack';
import { useTheme } from '@mui/material';
import copy from 'copy-to-clipboard';
import React, { useCallback, useMemo } from 'react';

import { RenderProps } from './types';

function IdRenderer({ value = '' }: Pick<RenderProps, 'value'>) {
  const handleClick = useCallback(() => {
    copy(value, {
      format: 'text/plain',
    });

    Notistack.toast('Copied');
  }, [value]);

  const theme = useTheme();
  const codeStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.action.selected,
      paddingLeft: 5,
      paddingRight: 5,
      cursor: 'pointer',
      color: theme.palette.text.primary,
    }),
    [theme]
  );

  return (
    <code title={value} style={codeStyle} onClick={handleClick}>
      {value.slice(-6)}
    </code>
  );
}

export default IdRenderer;
