import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetRoleDocument,
  GetRoleQuery,
  GetRoleQueryVariables,
  GetRolesDocument,
  GetRolesQuery,
  GetRolesQueryVariables,
  useGetRoleQuery,
  useGetRoleLazyQuery,
  useGetRolesQuery,
  useGetRolesLazyQuery,

    useGetAllRelatedDataForRoleQuery,
    useGetAllRelatedDataForRoleLazyQuery,
    GetAllRelatedDataForRoleQuery,
    GetAllRelatedDataForRoleQueryVariables,

  CreateRoleDocument,
  CreateRoleMutation,
  CreateRoleMutationVariables,
  useCreateRoleMutation,

  DeleteRoleDocument,
  DeleteRoleMutation,
  DeleteRoleMutationVariables,
  useDeleteRoleMutation,

  UpdateRoleDocument,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
  useUpdateRoleMutation,

    useCreatedRoleSubscription,
    useUpdatedRoleSubscription,
    useDeletedRoleSubscription,
    useRestoredRoleSubscription,
} from '@/graphql';

  type RoleCollection = Dictionary<NonNullable<GetRolesQuery['roles']['items']>[number]>;

const RoleBaseModel = {
  get: (options: Omit<QueryOptions<GetRoleQueryVariables, GetRoleQuery>, 'query'>) => {
    return apolloClient.query<GetRoleQuery, GetRoleQueryVariables>({
      ...options,
      query: GetRoleDocument,
    })
    .then(({ data }) => data.role);
  },

  useGet: useGetRoleQuery,

  getAll: (options?: Omit<QueryOptions<GetRolesQueryVariables, GetRolesQuery>, 'query'>) => {
    return apolloClient
      .query<GetRolesQuery, GetRolesQueryVariables>({
        ...options,
        query: GetRolesDocument
      })
      .then(({ data }) => data.roles.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) => {
    const hookResult = useGetRolesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.roles?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForRoleQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForRoleQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetRoleLazyQuery,

  useGetAllLazy: useGetRolesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForRoleLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateRoleMutation, CreateRoleMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateRoleMutation, CreateRoleMutationVariables>({
      ...options,
      mutation: CreateRoleDocument,
    });
  },

  useCreate: useCreateRoleMutation,

  update: (options: Omit<MutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateRoleMutation, UpdateRoleMutationVariables>({
      ...options,
      mutation: UpdateRoleDocument,
    });
  },

  useUpdate: useUpdateRoleMutation,

  delete: (options: Omit<MutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteRoleMutation, DeleteRoleMutationVariables>({
      ...options,
      mutation: DeleteRoleDocument,
    });
  },

  useDelete: useDeleteRoleMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) => {
      const [collection, setCollection] = useState<RoleCollection>({});

      const { items, loading, error, refetch } = RoleBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedRoleSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdRole?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdRole.id]: data.createdRole,
            }));
          }
        },
      });

      useUpdatedRoleSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedRole?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedRole.id]: data.updatedRole,
            }));
          }
        },
      });

      useDeletedRoleSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedRole?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedRole.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default RoleBaseModel;
