import { Add as AddIcon } from '@mui/icons-material';
import { TextField, Autocomplete, IconButton, Stack } from '@mui/material';
import { isEqual } from 'lodash';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'uniforms';

import InsertDialog from '../InsertDialog';

function AutocompleteField(props) {
  const { label, name, relationsOptions, refetchRelations, entity } = props;
  const [{ value, onChange, relation, required, disabled, allowedValues }] = useField(name, props);
  const { name: relationName } = relation;
  const multiple = ['BelongsToMany', 'HasMany', 'OneToMany'].includes(relationName);
  const showAddButton = ['HasOne', 'OneToMany'].includes(relationName);
  const prevAllowedValues = useRef(allowedValues);
  const InsertForm = entity?.form?.insert;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const filteredOptions = useMemo(
    () =>
      allowedValues
        ? allowedValues.map((allowedValue) => relationsOptions.find((option) => option.value === allowedValue)).filter(Boolean)
        : relationsOptions,
    [allowedValues, relationsOptions]
  );

  const [currentValue, setCurrentValue] = useState(() => {
    if (value) {
      if (Array.isArray(value)) {
        return value.filter((entry) => entry !== undefined);
      }

      return value;
    }

    if (required && filteredOptions[0]) {
      onChange(filteredOptions[0].value);

      return filteredOptions[0].value;
    }

    return null;
  });

  const handleChange = useCallback(
    (option) => {
      const newValue = (() => {
        if (!option) {
          return option;
        }

        if (multiple) {
          return option.map((o) => {
            if (typeof o === 'string') {
              return o;
            }

            return o.value;
          });
        }

        return option.value;
      })();

      onChange(newValue);
      setCurrentValue(newValue);
    },
    [multiple, onChange]
  );

  useEffect(() => {
    if (!allowedValues) {
      return;
    }

    // We deep check allowedValues against the previousAllowedValues so that we do not cause an infinite loop.
    if (isEqual(allowedValues, prevAllowedValues.current)) {
      return;
    }

    prevAllowedValues.current = allowedValues;

    if (Array.isArray(value)) {
      handleChange(value.filter((v) => allowedValues.includes(v)));
    } else {
      handleChange(allowedValues.find((allowedValue) => allowedValue === value) ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedValues]);

  const handleInsert = (result) => {
    const newOption = { value: result.id };

    handleChange(multiple ? [...value, newOption] : newOption);
  };

  return (
    <>
      <Stack gap={1} flexDirection="row" alignItems="center" justifyContent="stretch">
        <Autocomplete
          multiple={multiple}
          fullWidth
          filterSelectedOptions={multiple}
          onChange={(event, option) => handleChange(option)}
          key={name}
          options={filteredOptions}
          value={currentValue}
          getOptionKey={(option) => option.value}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return filteredOptions.find((o) => o.value === option)?.label ?? '';
            }

            return option.label;
          }}
          isOptionEqualToValue={(option, incomingValue) => option.value === incomingValue}
          variant="outlined"
          size="small"
          required={required}
          disabled={filteredOptions.length === 0 || disabled}
          renderInput={(params) => <TextField required={required} {...params} label={label} />}
        />

        {!!showAddButton && !!InsertForm && (
          <IconButton onClick={() => setAddOpen(true)}>
            <AddIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>

      {!!InsertForm && (
        <InsertDialog
          open={addOpen}
          onClose={() => setAddOpen(false)}
          name={name}
          onChange={handleInsert}
          InsertForm={InsertForm}
          refetchRelations={refetchRelations}
        />
      )}
    </>
  );
}

export default AutocompleteField;
