import { Visibility as VisibilityIcon, Edit as EditIcon } from '@mui/icons-material';
import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import ActionButton from './ActionButton';
import DeleteButton from './DeleteButton';
import { RenderProps } from './types';

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    gap: spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ActionButtons({
  row = {},
  Entity,
  actions,
  children,
  viewDisabled,
  deleteDisabled,
  updateDisabled,
}: RenderProps & { children?: ReactNode; deleteDisabled?: boolean; updateDisabled?: boolean; viewDisabled?: boolean }) {
  const { classes } = useStyles();
  const { id } = row;

  return (
    <div className={classes.container}>
      <ActionButton
        key="view"
        Entity={Entity}
        entityId={id}
        actionType="read"
        icon={<VisibilityIcon fontSize="inherit" />}
        disabled={viewDisabled}
      />
      <ActionButton
        key="update"
        Entity={Entity}
        entityId={id}
        actionType="update"
        icon={<EditIcon fontSize="inherit" />}
        disabled={updateDisabled}
      />
      <DeleteButton entityId={id} Entity={Entity} actions={actions} disabled={deleteDisabled} />
      {children}
    </div>
  );
}
