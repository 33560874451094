import config from '@frontend/config';
import Notistack from '@frontend/lib/notistack';
import { Download as DownloadIcon } from '@mui/icons-material';
import { useTheme, Button, CircularProgress, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

import Fieldset from '@boilerplate/components/Fieldset';
import { saveFileWithUrl } from '@boilerplate/lib/files';

function ImagePreview({ url, alt }) {
  const [loading, setLoading] = useState(true);
  const container = useRef();

  const fileName = useMemo(() => (url ? new URL(url).pathname.split('/').at(-1) : null), [url]);

  useEffect(() => {
    if (!url) {
      return;
    }

    setLoading(true);

    const imgElement = new Image(100, 100);

    imgElement.style.objectFit = 'contain';
    imgElement.style.height = 100;
    imgElement.style.width = 100;
    imgElement.alt = alt;

    const handleEvent = (wasSuccessful) => {
      imgElement.onerror = null;
      imgElement.onload = null;

      if (imgElement.src === url) {
        if (container.current) {
          container.current.innerHTML = '';

          if (wasSuccessful) {
            container.current.appendChild(imgElement);
          }
        }

        setLoading(false);
      }
    };

    imgElement.onerror = () => handleEvent(false);
    imgElement.onload = () => handleEvent(true);

    imgElement.src = url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alt, url]);

  return (
    <>
      <div ref={container} />
      {!!loading && (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress size={24} thickness={6} />
        </Stack>
      )}
      <Typography>{fileName}</Typography>
    </>
  );
}

function UploadField({ value, onChange, label, disabled, required }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    axios
      .post(`${config.app.backendUrl}/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        setUploadedFile(data);
        onChange(data.url);
      })
      .catch((err) => {
        console.error(err);
        Notistack.toast(err);
      });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: false, onDrop, disabled });

  const imageUrl = uploadedFile ? uploadedFile.url : value;
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleDownload = () => {
    setDownloadLoading(true);
    saveFileWithUrl(imageUrl)
      .catch((err) => {
        console.error(err);
        Notistack.error(err);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  return (
    <Fieldset label={label} required={required}>
      <div
        {...getRootProps({
          className: 'dropzone',
          disabled,
          style: {
            border: theme?.palette?.mode === 'light' ? '1px dashed rgba(0, 0, 0, 0.23)' : '1px dashed rgba(255, 255, 255, 0.23)',
            padding: 10,
            borderRadius: 3,
          },
        })}
      >
        <input {...getInputProps()} />
        <p>{t('strings:uploadField.dragAndDropHere')}</p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
      {imageUrl && (
        <Stack gap={2}>
          <ImagePreview url={imageUrl} alt="preview" />
          <Button variant="outlined" size="small" startIcon={<DownloadIcon />} onClick={handleDownload} disabled={downloadLoading}>
            {t('crud:download')}
          </Button>
        </Stack>
      )}
    </Fieldset>
  );
}

export default connectField(UploadField, { initialValue: true });
