import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

function ApproveField({ value, onChange, label, disabled, required }) {
  const { t } = useTranslation();
  const betterValue = value === null ? '' : value;

  return (
    <FormControl fullWidth sx={{ mt: 2, mb: 2 }} disabled={disabled} required={required}>
      <InputLabel id={`${label}-label`} shrink>
        {label}
      </InputLabel>
      <Select
        id={label}
        labelId={`${label}-label`}
        disabled={disabled}
        required={required}
        autoWidth
        value={betterValue}
        onChange={(e) => {
          onChange(e.target.value === '' ? null : e.target.value);
        }}
        displayEmpty
        defaultValue={null}
      >
        <MenuItem value={false}>{t('strings:approveField.refuse')}</MenuItem>
        <MenuItem value>{t('strings:approveField.approve')}</MenuItem>
        <MenuItem value="">{t('strings:approveField.pending')}</MenuItem>
      </Select>
    </FormControl>
  );
}

export default connectField(ApproveField);
