import ApproveField from './ApproveField';
import AutocompleteField from './AutocompleteField';
import BooleanField from './BooleanField';
import CommaSeparatedField from './CommaSeparatedField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import HiddenField from './HiddenField';
import LongTextField from './LongTextField';
import NumberField from './NumberField';
import RichTextField from './RichTextField';
import UploadField from './UploadField';

export default {
  ApproveField,
  AutocompleteField,
  BooleanField,
  CommaSeparatedField,
  DateField,
  DateTimeField,
  HiddenField,
  LongTextField,
  NumberField,
  RichTextField,
  UploadField,
};
