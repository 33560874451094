import { isEmpty, merge } from 'lodash';
import { StoresType } from './types';

export const preInitStoresData: { [storeName: string]: any } = {};

export const Stores: StoresType = {
  set: <TData>(storeName: string, keyOrData: keyof TData | Partial<TData>, optionalData?: Partial<TData>) => {
    let preInitStore = preInitStoresData[storeName] || {};
    let key: keyof TData | undefined;
    let data: Partial<TData> | undefined;

    if (optionalData) {
      data = optionalData;
    }

    if (typeof keyOrData !== 'object') {
      key = keyOrData;
    } else {
      data = keyOrData;
    }

    if (key !== undefined) {
      preInitStore[key] = data;
    } else {
      preInitStore = isEmpty(data) ? data : merge(preInitStore, data);
    }

    preInitStoresData[storeName] = preInitStore;
  },

  reset: <TData>(storeName: string, data: TData) => {
    preInitStoresData[storeName] = data;
  },

  getAll: () => {
    console.warn('Stores not initialized yet');

    return preInitStoresData;
  },

  get: (storeName: string) => {
    console.warn('Store not initialized yet', storeName);

    return preInitStoresData[storeName] || {};
  },

  storeProviders: {},
};
