import { canPerformAction, useAuthenticatedUserStore } from '@frontend/stores/UserStore';
import { Tooltip, IconButton } from '@mui/material';
import { PropsFor } from '@mui/system';
import { camelCase } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type ActionButtonBaseProps = PropsFor<typeof IconButton> & {
  children: string;
  icon: ReactNode;
  [key: string]: unknown;
};

export function ActionButtonBase({ children, icon, ...props }: ActionButtonBaseProps) {
  return (
    <Tooltip title={children} placement="top">
      <IconButton size="small" {...props}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default function ActionButton({ Entity, actionType, entityId, icon, disabled }: any) {
  const user = useAuthenticatedUserStore();
  const { t } = useTranslation();

  const canPerformThisAction = useMemo(() => canPerformAction(actionType, camelCase(Entity.name), user), [Entity.name, actionType, user]);

  const route = useMemo(() => {
    if (actionType === 'read') {
      return `/admin/${Entity.name}/${entityId}`;
    }

    if (actionType === 'update') {
      return `/admin/${Entity.name}/${entityId}/edit`;
    }

    return '';
  }, [actionType, Entity.name, entityId]);

  return (
    <ActionButtonBase component={Link} to={route} icon={icon} disabled={!canPerformThisAction || disabled}>
      {t(`crud:${actionType}`)}
    </ActionButtonBase>
  );
}
