import { ApolloQueryResult } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Dialog, Icon, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { isNull } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DaysOffEntity from '@/entities/daysOff';
import { DaysOff, GetDaysOffsQuery, GetDaysOffsQueryVariables, useDeletedDaysOffSubscription } from '@/graphql';
import Notistack from '@/lib/notistack';
import { openConfirmationDialog } from '@/stores/ConfirmationDialogStore';

import DaysOffForm from './DaysOffForm';

function DaysOffDisplay({
  daysOff = [],
  refetch,
}: {
  daysOff?: GetDaysOffsQuery['daysOffs']['items'];
  refetch: (variables?: GetDaysOffsQueryVariables) => Promise<ApolloQueryResult<GetDaysOffsQuery>>;
}) {
  const [daysOffDelete] = DaysOffEntity.model.useDelete();
  const [pageSize, setPageSize] = React.useState<number>(50);
  const { t } = useTranslation();

  const [editFormOpen, setEditFormOpen] = useState<boolean>(false);
  const [dayOffToEdit, setDayOffToEdit] = useState<DaysOff | undefined>(undefined);

  useDeletedDaysOffSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      refetch();
    },
  });

  const handleDelete = (rowId: string) => {
    daysOffDelete({ variables: { id: rowId } })
      .then(() => {
        Notistack.toast?.(t('daysOff:error.update'));
      })
      .catch(() => {
        Notistack.toast?.(t('daysOff:error.delete'));
      });
  };

  const deleteDaysOffRequest = (rowId: string) => {
    openConfirmationDialog({
      text: t('daysOff:text.delete'),
      approval: {
        handle: () => handleDelete(rowId),
      },
    });
  };

  const handleOpenEditForm = (row: DaysOff) => {
    setDayOffToEdit(row);
    setEditFormOpen(true);
  };

  const handleCloseEditForm = () => {
    setEditFormOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'startDate',
      headerName: t('daysOff:start_date'),
      width: 276,
      valueFormatter: (params) => {
        return format(new Date(params.value), 'MM/dd/yyyy hh:mm');
      },
    },
    {
      field: 'endDate',
      headerName: t('daysOff:end_date'),
      width: 276,
      valueFormatter: (params) => {
        return format(new Date(params.value), 'MM/dd/yyyy hh:mm');
      },
    },

    {
      field: 'note',
      headerName: t('daysOff:note'),
      type: 'string',
      width: 275,
    },
    {
      field: 'approved',
      headerName: t('daysOff:status'),
      sortable: true,
      width: 255,
      renderCell: (params) => {
        if (params.row.approved) {
          return <DoneIcon />;
        }

        if (isNull(params.row.approved)) {
          return <QuestionMarkIcon />;
        }

        if (!params.row.approved) {
          return <CloseIcon />;
        }
      },
    },

    {
      field: 'actions',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      headerName: t('entityFields:default.actions'),

      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => handleOpenEditForm(params.row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => deleteDaysOffRequest(params.row.id)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      {' '}
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'startDate', sort: 'desc' }],
          },
        }}
        getRowId={(row) => row.id}
        rows={daysOff}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        autoHeight={false}
      />
      <Dialog maxWidth="sm" open={editFormOpen} onClose={handleCloseEditForm} fullWidth>
        <DaysOffForm refetch={refetch} handleClose={handleCloseEditForm} dayOff={dayOffToEdit} />
      </Dialog>
    </>
  );
}

export default DaysOffDisplay;
