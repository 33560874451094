import { LoggedHourHo } from '@/graphql';
import { Dialog } from '@mui/material';
import React from 'react';
import EditLoggedHours from '../LoggingHours/EditLoggedHours';

type EditLoggedHourDialogProps = {
  open: boolean;
  onClose: () => void;
  loggedHour?: LoggedHourHo;
};

function EditLoggedHourDialog({ open, onClose: handleClose, loggedHour }: EditLoggedHourDialogProps) {
  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose} fullWidth>
      <EditLoggedHours loggedHour={loggedHour} onSuccess={handleClose} />
    </Dialog>
  );
}

export default EditLoggedHourDialog;
