import EntityType from '@boilerplate/types/entity';

import { FieldType, getFieldType, SchemaEntry } from './simpleSchema';

export type EntityData<T> = Record<string, T> | Record<string, T>[] | { items: T[] };

export default function getFormData<T = any>(
  Entity: EntityType,
  data: EntityData<T> = {},
  type: 'insert' | 'update',
  { initialData = {} }: { initialData?: Record<string, T> } = {}
) {
  const formData = { ...initialData };

  Object.entries(data).forEach(([key, value]) => {
    const field: SchemaEntry = Entity[type].simpleSchema.getDefinition(key);

    if (!field) {
      return;
    }

    if (field.relation) {
      if (field.relation.type === 'ManyToOne' && value?.id) {
        formData[key] = value?.id;
      } else if (value?.items) {
        formData[key] = value.items?.map?.((item) => item.id).filter(Boolean) ?? [];
      } else {
        formData[key] = value;
      }

      return;
    }

    const fieldType = getFieldType(field);

    if (fieldType === null) {
      console.error(`Unknown field type for '${key}'!`);
    }

    if (fieldType === FieldType.ARRAY) {
      formData[key] = value.map(({ id }) => id) as T;
    } else {
      formData[key] = value;
    }
  });

  return formData;
}
