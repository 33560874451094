import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormUpdateProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import UserEntity from '@/entities/user';
import { catchGraphQlError } from '@/lib/catchError';


function UserBaseUpdateForm({ formOnly = false, onClose, Entity = UserEntity, id: propId, ...props }: BaseFormUpdateProps<typeof UserEntity.model>) {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const id = propId || paramId;

  const Component = formOnly ? EntityForm : EntityFormPage;

  const { loading, data } = UserEntity.model.useGet({
    variables: {
      id,
    },
  });

  const [updateMutation, { loading: updateLoading }] = UserEntity.model.useUpdate();

  const relationsOptionsQuery = UserEntity.model.useRelationsOptions({
    variables: {
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (newData) => {
    newData.variables = getGraphqlData(Entity, newData.variables, 'update');

    updateMutation(newData)
      .then((result) => {
        Notistack.toast(t('crud:updatedItem', { item: t('entities:user') }), { variant: 'success' });

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.updateUser);
      })
      .catch(catchGraphQlError);
  };

  if (loading || relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="update"
      Entity={Entity}
      loading={updateLoading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
      data={data.user}
    />
  );
}

export default UserBaseUpdateForm;
