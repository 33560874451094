import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetProjectDocument,
  GetProjectQuery,
  GetProjectQueryVariables,
  GetProjectsDocument,
  GetProjectsQuery,
  GetProjectsQueryVariables,
  useGetProjectQuery,
  useGetProjectLazyQuery,
  useGetProjectsQuery,
  useGetProjectsLazyQuery,

    useGetAllRelatedDataForProjectQuery,
    useGetAllRelatedDataForProjectLazyQuery,
    GetAllRelatedDataForProjectQuery,
    GetAllRelatedDataForProjectQueryVariables,

  CreateProjectDocument,
  CreateProjectMutation,
  CreateProjectMutationVariables,
  useCreateProjectMutation,

  DeleteProjectDocument,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  useDeleteProjectMutation,

  UpdateProjectDocument,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  useUpdateProjectMutation,

    useCreatedProjectSubscription,
    useUpdatedProjectSubscription,
    useDeletedProjectSubscription,
    useRestoredProjectSubscription,
} from '@/graphql';

  type ProjectCollection = Dictionary<NonNullable<GetProjectsQuery['projects']['items']>[number]>;

const ProjectBaseModel = {
  get: (options: Omit<QueryOptions<GetProjectQueryVariables, GetProjectQuery>, 'query'>) => {
    return apolloClient.query<GetProjectQuery, GetProjectQueryVariables>({
      ...options,
      query: GetProjectDocument,
    })
    .then(({ data }) => data.project);
  },

  useGet: useGetProjectQuery,

  getAll: (options?: Omit<QueryOptions<GetProjectsQueryVariables, GetProjectsQuery>, 'query'>) => {
    return apolloClient
      .query<GetProjectsQuery, GetProjectsQueryVariables>({
        ...options,
        query: GetProjectsDocument
      })
      .then(({ data }) => data.projects.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) => {
    const hookResult = useGetProjectsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.projects?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForProjectQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForProjectQuery, GetAllRelatedDataForProjectQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForProjectQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetProjectLazyQuery,

  useGetAllLazy: useGetProjectsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForProjectLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateProjectMutation, CreateProjectMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateProjectMutation, CreateProjectMutationVariables>({
      ...options,
      mutation: CreateProjectDocument,
    });
  },

  useCreate: useCreateProjectMutation,

  update: (options: Omit<MutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateProjectMutation, UpdateProjectMutationVariables>({
      ...options,
      mutation: UpdateProjectDocument,
    });
  },

  useUpdate: useUpdateProjectMutation,

  delete: (options: Omit<MutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteProjectMutation, DeleteProjectMutationVariables>({
      ...options,
      mutation: DeleteProjectDocument,
    });
  },

  useDelete: useDeleteProjectMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) => {
      const [collection, setCollection] = useState<ProjectCollection>({});

      const { items, loading, error, refetch } = ProjectBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedProjectSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdProject?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdProject.id]: data.createdProject,
            }));
          }
        },
      });

      useUpdatedProjectSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedProject?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedProject.id]: data.updatedProject,
            }));
          }
        },
      });

      useDeletedProjectSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedProject?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedProject.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredProjectSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredProject?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredProject.id]: data.restoredProject,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default ProjectBaseModel;
