import Loading from '@boilerplate/components/Loading';
import { Close as RemoveIcon } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetRolesQuery,
  GetTenantsQuery,
  GetUserTenantRolesQuery,
  RoleSortFields,
  SortDirection,
  TenantSortFields,
  useCreateUserTenantRoleMutation,
  useDeleteUserTenantRoleMutation,
  useGetRolesQuery,
  useGetTenantsQuery,
} from '@/graphql';
import Notistack from '@/lib/notistack';
import { useTenantifiedUserStore } from '@/stores/UserStore';
import { catchGraphQlError } from '@/lib/catchError';

type Data = NonNullable<GetUserTenantRolesQuery['userTenantRoles']['items']>;
type Entry = { id: string; role: NonNullable<Data[number]['role']>; tenant: NonNullable<Data[number]['tenant']> };
type Refetch = () => Promise<unknown>;

function TenantRoleEntry({
  tenantId,
  userId,
  data,
  roles,
  refetch,
}: {
  tenantId: string;
  userId: string;
  data?: Entry[];
  roles: NonNullable<GetRolesQuery['roles']['items']>;
  refetch: Refetch;
}) {
  const { t } = useTranslation();

  const [createUserTenantRole] = useCreateUserTenantRoleMutation();
  const [deleteUserTenantRole] = useDeleteUserTenantRoleMutation();

  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [loading, setLoading] = useState(false);

  const unassignedRoles = useMemo(() => {
    const assignedRoleIds = data?.map((entry) => entry.role.id) ?? [];

    return roles.filter((role) => !assignedRoleIds.includes(role.id));
  }, [data, roles]);

  const handleAddRole = (addRoleId: string) => {
    setLoading(true);
    createUserTenantRole({
      variables: {
        roleId: addRoleId,
        tenantId,
        userId,
      },
    })
      .then(refetch)
      .then(() => {
        setSelectedRoleId('');
      })
      .catch(catchGraphQlError)
      .finally(() => {
        setLoading(false);
      });
  };

  const removeRole = (userTenantRoleId: string) => {
    setLoading(true);
    deleteUserTenantRole({
      variables: {
        id: userTenantRoleId,
      },
    })
      .then(refetch)
      .catch(catchGraphQlError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <List dense>
        {!data || data.length <= 0 ? (
          <ListItem dense disableGutters>
            <ListItemText primary={<i>{t('users:form.noRoles')}</i>} />
          </ListItem>
        ) : (
          <>
            {data.map(({ id, role }) => (
              <ListItem key={role.id} dense disableGutters>
                <ListItemText primary={role.displayName} secondary={role.description} />
                <IconButton size="small" onClick={() => removeRole(id)} disabled={loading}>
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </ListItem>
            ))}
          </>
        )}
      </List>

      <Stack flexDirection="row" alignItems="center" gap={1}>
        <FormControl size="small" disabled={unassignedRoles.length <= 0} fullWidth>
          <InputLabel id="role-select-label">{t('users:form.addRole')}</InputLabel>
          <Select
            onChange={(e) => handleAddRole(e.target.value)}
            label={t('users:form.addRole')}
            labelId="role-select-label"
            value={selectedRoleId}
          >
            {unassignedRoles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}

function Content({
  userId,
  data,
  tenants,
  roles,
  refetch,
}: {
  userId: string;
  data: Data;
  tenants: NonNullable<GetTenantsQuery['tenants']['items']>;
  roles: NonNullable<GetRolesQuery['roles']['items']>;
  refetch: Refetch;
}) {
  const { currentTenant } = useTenantifiedUserStore();
  const { t } = useTranslation();

  const dataByTenant = useMemo(
    () =>
      groupBy(
        data.filter((entry): entry is Entry => entry.tenant !== undefined && entry.role !== undefined),
        (entry) => entry.tenant.id
      ),
    [data]
  );

  // TODO check if tenant list is filtered on tenants that you have access to.
  // TODO check if user can assign higher role than themselves (to others and themselves).
  // TODO order tenants, current tenant always first. Then order by tenant.name.

  return (
    <Stack gap={4} divider={<Divider flexItem />}>
      {tenants.map((tenant) => (
        <div key={tenant.id}>
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Typography>{tenant.name}</Typography>
            {currentTenant?.id === tenant.id ? <Chip label={t('tenants:currentTenant')} color="primary" size="small" /> : null}
          </Stack>
          <TenantRoleEntry tenantId={tenant.id} userId={userId} data={dataByTenant[tenant.id]} roles={roles} refetch={refetch} />
        </div>
      ))}
    </Stack>
  );
}

type TenantRolesOverviewProps = {
  userId: string;
  data?: GetUserTenantRolesQuery;
  loading: boolean;
  error?: Error;
  refetch: Refetch;
};

export default function TenantRolesOverview({ userId, data, loading, error, refetch }: TenantRolesOverviewProps) {
  const { t } = useTranslation();

  const {
    data: tenants,
    loading: tenantsLoading,
    error: tenantsError,
  } = useGetTenantsQuery({
    variables: {
      sorting: {
        field: TenantSortFields.Name,
        direction: SortDirection.Asc,
      },
    },
  });

  const {
    data: roles,
    loading: rolesLoading,
    error: rolesError,
  } = useGetRolesQuery({
    variables: {
      sorting: {
        field: RoleSortFields.DisplayName,
        direction: SortDirection.Asc,
      },
    },
  });

  if (loading || tenantsLoading || rolesLoading) {
    return (
      <Card>
        <CardContent>
          <Stack alignItems="center" justifyContent="center">
            <Loading />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return <Alert severity="error">{error.message || error.toString()}</Alert>;
  }

  if (tenantsError) {
    return <Alert severity="error">{tenantsError.message || tenantsError.toString()}</Alert>;
  }

  if (rolesError) {
    return <Alert severity="error">{rolesError.message || rolesError.toString()}</Alert>;
  }

  if (!data?.userTenantRoles?.items || !tenants?.tenants.items || tenants.tenants.items.length <= 0 || !roles?.roles.items) {
    return (
      <Card>
        <CardContent>
          <Typography>{t('crud:noData')}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Content
          userId={userId}
          data={data.userTenantRoles.items}
          tenants={tenants.tenants.items}
          roles={roles.roles.items}
          refetch={refetch}
        />
      </CardContent>
    </Card>
  );
}
