import { FormFields } from '@frontend/components/entity';
import { snakeCase } from 'change-case';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoField } from 'uniforms-mui-5';

import { RelationsOptions } from '@boilerplate/lib/relatedDataToOptions';
import { FormOptions } from '@boilerplate/lib/simpleSchema';

import AutocompleteField from './Fields/AutocompleteField';

const { HiddenField } = FormFields;

export type Field = {
  data: any;
  form: FormOptions;
  relation?: { name: string; model: string };
  type: { name?: string } | [{ type: unknown }];
  label?: string | (() => string);
};

export default function AutoFormField<Data extends Record<string, unknown> = Record<string, unknown>>({
  data,
  name,
  field,
  relationsOptions = {},
  allowedValues,
  entityName,
}: {
  data: Data;
  name: string;
  field?: Field;
  relationsOptions?: RelationsOptions;
  allowedValues?: string[];
  entityName: string;
}): JSX.Element {
  const { form = {}, relation = {}, type, label } = field ?? {};
  const { t } = useTranslation();

  // Find Custom Field Component (like DateTimePicker)
  const { component = type?.name, helpText, disabled } = form;

  const helpTextComponent = useMemo(() => {
    if (typeof helpText === 'function') {
      const HelpText = helpText;

      return <HelpText />;
    }

    return helpText;
  }, [helpText]);

  const options = useMemo(
    () =>
      allowedValues
        ? allowedValues.map((allowedValue) => ({
            label: t(`entityFields:${entityName}.$${snakeCase(name)}_options.${allowedValue}`, allowedValue),
            value: allowedValue,
          }))
        : undefined,
    [allowedValues, entityName, name, t]
  );

  if (!form) {
    return <HiddenField key={name} />;
  }

  const Component = FormFields[`${component}Field`];

  if (Component) {
    return <Component data={data} key={name} name={name} label={label} disabled={disabled} />;
  }

  if (relation.model) {
    const entityPlural = pluralize.plural(relation.model);
    const { options: entityOptions = [], entity, refetchRelations } = relationsOptions[entityPlural] ?? {};

    return (
      <AutocompleteField
        key={name}
        name={name}
        disabled={disabled}
        relationsOptions={entityOptions}
        entity={entity}
        label={label}
        refetchRelations={refetchRelations}
      />
    );
  }

  return (
    <AutoField
      key={name}
      name={name}
      label={label}
      options={options}
      helperText={helpTextComponent}
      disabled={disabled}
      variant="outlined"
      size="small"
    />
  );
}
