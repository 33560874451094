import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import SimpleSchema2Bridge from 'uniforms-bridge-simple-schema-2';
import { AutoForm, ErrorsField, SubmitField } from 'uniforms-mui-5';

import PageCard from '@boilerplate/components/PageCard';
import getFormData from '@boilerplate/lib/getFormData';
import { SchemaEntry, FormOptions } from '@boilerplate/lib/simpleSchema';
import { PreFilledFields } from '@boilerplate/types/entity';
import AutoFormField from '@entity/EntityForm/AutoFormField';

import { getTranslatedEntityName } from '../helpers';

import type { RelationsOptions } from '@boilerplate/lib/relatedDataToOptions';
import type EntityType from '@boilerplate/types/entity';

const useStyles = makeStyles()(({ spacing }) => ({
  form: {
    flexDirection: 'column',
    display: 'flex',
    gap: spacing(2),
  },
  formRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    gap: spacing(1),

    // Make all form children in form full width
    '&>*': {
      minWidth: '50ch',
      width: '100%',
      flex: 1,
    },
  },
}));

export type EntityFormProps<Data extends Record<string, unknown>> = {
  type: 'insert' | 'update';
  Entity: EntityType;
  loading: boolean;
  handleSubmit: (data: { variables: Data }) => void;
  relationsOptions?: RelationsOptions;
  data?: Data;
  onChange?: (data: Data) => void;
  hiddenFields?: string[];
  preFilledFields?: PreFilledFields<Data>;
};

export function EntityForm<Data extends Record<string, unknown> = Record<string, unknown>>({
  Entity,
  data,
  type,
  relationsOptions,
  loading,
  hiddenFields = [],
  preFilledFields = {},
  onChange,
  handleSubmit,
}: EntityFormProps<Data>) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const formData = useMemo(() => {
    const initialDoc = Object.fromEntries(
      Object.entries(preFilledFields)
        .filter(([, value]) => !!value)
        .map(([key, value]) => [key, value!.value])
    );

    return {
      ...getFormData(Entity, data, type, {
        initialData: initialDoc,
      }),
      ...initialDoc,
    };
  }, [Entity, data, preFilledFields, type]);

  const formattedFields = useMemo(
    () =>
      Entity[type].orderSchema
        .map((row) => {
          const fields = row.map<SchemaEntry & { name: string }>((field) => ({
            ...Entity[type].simpleSchema.getDefinition(field),
            name: field,
          }));

          const filteredFields = fields.filter((field) => {
            if (!field.form) {
              return false;
            }

            if (hiddenFields.includes(field.name)) {
              if (!field.optional && type === 'insert') {
                // eslint-disable-next-line no-console
                console.warn(`Field '${field.name}' is supposed to be hidden, but is not optional and will thus be shown`);
              } else {
                return false;
              }
            }

            return true;
          });

          return {
            fields: filteredFields.map((field) => field.name),
            elements: filteredFields
              .map((field) => {
                const allowedValues = Entity[type].simpleSchema.getAllowedValuesForKey(field.name);
                const fieldForm = { ...(field.form as FormOptions) };

                if (preFilledFields[field.name]?.disabled === true) {
                  fieldForm.disabled = true;
                }

                return (
                  <AutoFormField
                    data={data}
                    key={field.name}
                    name={field.name}
                    entityName={Entity.name}
                    allowedValues={allowedValues}
                    relationsOptions={relationsOptions}
                    field={{ ...field, form: fieldForm }}
                  />
                );
              })
              .filter(Boolean),
          };
        })
        .filter((row) => row.elements.length > 0),
    [Entity, type, hiddenFields, preFilledFields, data, relationsOptions]
  );

  return (
    <AutoForm
      schema={new SimpleSchema2Bridge(Entity[type].simpleSchema)}
      model={formData}
      onChangeModel={onChange}
      onSubmit={(doc) => handleSubmit({ variables: doc })}
      className={classes.form}
    >
      {formattedFields.map((row) => (
        <div key={row.fields.join('-')} className={classes.formRow}>
          {row.elements}
        </div>
      ))}
      <ErrorsField />
      <SubmitField label={t('crud:save')} disabled={loading} style={{ width: 'fit-content' }} />
    </AutoForm>
  );
}

function EntityFormPage<Data extends Record<string, unknown> = Record<string, unknown>>(props: EntityFormProps<Data>) {
  const { Entity, type = 'insert' } = props;
  const { t } = useTranslation();

  const heading = useMemo(() => getTranslatedEntityName(t, Entity.name, type), [Entity.name, type, t]);

  return (
    <Container>
      <PageCard heading={heading} arrowBack>
        <EntityForm {...props} />
      </PageCard>
    </Container>
  );
}

export default EntityFormPage;
