import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const ProjectBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:project.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:project.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'active',
        label: t(['entityFields:project.active', 'entityFields:default.active'], 'active') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'description',
        label: t(['entityFields:project.description', 'entityFields:default.description'], 'description') || undefined,
          cell: CellRenderers['LongTextRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['LongTextFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'startDate',
        label: t(['entityFields:project.start_date', 'entityFields:default.start_date'], 'startDate') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'endDate',
        label: t(['entityFields:project.end_date', 'entityFields:default.end_date'], 'endDate') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'customer',
        label: t(['entityFields:project.customer', 'entityFields:default.customer'], 'customer') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'tenant',
        label: t(['entityFields:project.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:project.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:project.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const ProjectBaseTableSchema: TableSchema[] = [
  ...ProjectBaseTableSchemaWithoutActions,
  actions,
];

export { actions, ProjectBaseTableSchemaWithoutActions };
export default ProjectBaseTableSchema;
