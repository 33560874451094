import createStore from './store/createStore';

type LogHoursStoreType = {
  date: Date;
};

const initialData = {
  date: new Date(),
};

const [LogHoursStore, useLogHoursStore] = createStore<LogHoursStoreType>('logHours', initialData);

export { LogHoursStore, useLogHoursStore };
