import config from '@frontend/config';
import { loginHandler } from '@frontend/stores/UserStore';
import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

function DefaultLoggedInCallbackPage() {
  const [searchParams] = useSearchParams();
  const nav = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!config.flybase.oAuthEnabled) {
      nav('/login');
    }

    if (searchParams.get('state') === window.localStorage.getItem('state')) {
      axios
        .post(`${config.app.backendUrl}/auth/flybase`, {
          code: searchParams.get('code'),
          redirectUri: window.localStorage.getItem('redirectUri'),
        })
        .then((response) => loginHandler(response.data))
        .catch((error) => {
          console.log(error.response?.data?.message);
        });
    } else {
      nav('/login');
    }
  }, []);

  return (
    <Typography mt={3} variant="h5" component="h5" style={{ fontStyle: 'italic', textAlign: 'center' }}>
      {t('auth:login.redirect.afterFlybase')}
    </Typography>
  );
}

export default DefaultLoggedInCallbackPage;
