import React from 'react';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import UserStore from '@/stores/UserStore';
import Notistack from '@/lib/notistack';
import axios from 'axios';

type ErrorBoundaryComponentProps = {
  t: TFunction;
  pathname: string;
};

type ErrorBoundaryComponentState = {
  hasError: boolean;
  errorFeedbackText: string;
};

class ErrorBoundaryComponent extends React.Component<ErrorBoundaryComponentProps, ErrorBoundaryComponentState> {
  state = {
    hasError: false,
    errorFeedbackText: '',
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryComponentProps) {
    const { hasError } = this.state;
    const { pathname } = this.props;

    if (hasError && pathname !== prevProps.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.captureException(error, (scope) => {
      scope.setExtra('component_stack', errorInfo);

      return scope;
    });

    try {
      axios.post('/error-log', { exception: error?.message, stack: error?.stack });
    } catch (e) {
      // server is most likely dead...
    }
  }

  handleCrashReport() {
    const { errorFeedbackText } = this.state;

    if (errorFeedbackText.length === 0) {
      return;
    }

    const email = UserStore.get('email');

    axios.post('/feedback/crash', { feedback: errorFeedbackText, email }).then(() => {
      const { t } = this.props;

      this.setState({
        errorFeedbackText: '',
      });

      Notistack.toast(t('strings:error.send'));

      setTimeout(() => {
        window.location.href = '/';
      }, 3500);
    });
  }

  render() {
    const { errorFeedbackText, hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <Box height="100%" width="clamp(270px, 100vw, 50vw)" marginX="auto">
          <Typography variant="h1">Error</Typography>
          <hr />
          <Typography variant="h4">Crash Report</Typography>
          <TextField
            placeholder={t('strings:error.helpText')}
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            value={errorFeedbackText}
            onChange={(event) => this.setState({ errorFeedbackText: event.target.value })}
          />
          <Button style={{ float: 'right' }} onClick={() => this.handleCrashReport()}>
            {t('strings:send')}
          </Button>
        </Box>
      );
    }

    return children;
  }
}

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryComponent t={t} pathname={pathname}>
      {children}
    </ErrorBoundaryComponent>
  );
}
