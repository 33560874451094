import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { FieldHookConfig, useField } from 'formik';
import React, { forwardRef } from 'react';

type FormikCheckboxProps = {
  label: string | React.ReactElement;
};

export default forwardRef<HTMLInputElement, FormikCheckboxProps & FieldHookConfig<string>>(function FormikCheckbox({ label, ...props }, ref) {
  const [field, meta] = useField(props);

  return (
    <FormControl component="fieldset" error={meta.touched && meta.error !== undefined}>
      <FormControlLabel
        control={<Checkbox {...field} {...props} checked={field.value} ref={ref} sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />}
        label={label}
        componentsProps={{ typography: { color: meta.touched && meta.error ? '#d32f2f' : undefined } }}
      />
      {meta.touched && meta.error && <FormHelperText sx={{ marginBottom: 2 }}>{meta.error}</FormHelperText>}
    </FormControl>
  );
});
