import Entity from '@boilerplate/types/entity';
import { QueryResult } from '@apollo/client';
import { camelCase } from 'change-case';
import pluralize from 'pluralize';
import { groupBy } from 'lodash';

type QueryBase = {
  [key: string]: {
    items?: {
      id: string;
      displayField: string;
    }[];
  };
};

type RelatedKeys<TQuery> = keyof Omit<TQuery, '__typename'>;
type RelationOption = {
  value: string;
  label: string;
};
type RelationsOptionsEntry<TQuery extends QueryBase = QueryBase, TEntity extends Entity = Entity> = {
  refetchRelations: QueryResult<TQuery>['refetch'];
  options: RelationOption[];
  entity?: TEntity;
};
export type RelationsOptions<TQuery extends QueryBase = QueryBase, TEntity extends Entity = Entity> = {
  [Property in RelatedKeys<TQuery>]: RelationsOptionsEntry<TQuery, TEntity>;
};

export const addEntityToRelatedDataOptions = <TQuery extends QueryBase = QueryBase, TEntity extends Entity = Entity>(
  relationsOptions: QueryResult<TQuery> & { items: Record<RelatedKeys<TQuery>, RelationOption[]> },
  relatedEntities: TEntity[]
): RelationsOptions<TQuery, TEntity> | undefined => {
  if (!relationsOptions) {
    return undefined;
  }

  type RelationsOptionsKeys = keyof typeof relationsOptions.items;
  const entries = Object.entries(relationsOptions.items ?? {}) as [RelationsOptionsKeys, RelationOption[]][];

  const entitiesByName = groupBy(
    relatedEntities.map((entity) => ({ ...entity, camelName: camelCase(pluralize.plural(entity.name)) })),
    'camelName'
  ) as Record<RelationsOptionsKeys, (TEntity & { camelName: RelationsOptionsKeys })[]>;

  return Object.fromEntries(
    entries.map<[RelationsOptionsKeys, RelationsOptionsEntry<TQuery, TEntity>]>(([key, options]) => [
      key,
      { options, entity: entitiesByName[key]?.[0], refetchRelations: relationsOptions.refetch },
    ])
  ) as RelationsOptions<TQuery, TEntity>;
};

export default function relatedDataToOptions<TQuery extends QueryBase = QueryBase>(
  data: TQuery
): Record<RelatedKeys<TQuery>, RelationOption[]> {
  const relationsOptions = {} as Record<RelatedKeys<TQuery>, RelationOption[]>;

  let relation: keyof TQuery;

  for (relation in data) {
    if (relation === '__typename') {
      continue;
    }

    const relationResult: {
      id: string;
      displayField: string;
    }[] = data[relation].items || [];

    relationsOptions[relation as RelatedKeys<TQuery>] = relationResult.map<RelationOption>((item) => ({
      value: item.id,
      label: item.displayField,
    }));
  }

  return relationsOptions;
}
