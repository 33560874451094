import { BaseFormInsertProps } from '@boilerplate/types/entity';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import UserBaseUpdateForm from '@/_base/user/components/UserBaseUpdateForm';
import UserEntity from '@/entities/user';
import { useGetUserTenantRolesQuery } from '@/graphql';

import TenantRolesOverview from './UserTenantRolesOverview';

function UserUpdateForm(props: BaseFormInsertProps<typeof UserEntity.model>) {
  const { id } = useParams();

  const { data, loading, error, refetch } = useGetUserTenantRolesQuery({
    variables: {
      filter: {
        user: {
          id: { eq: id },
        },
      },
      withTenant: true,
      withRole: true,
    },
  });

  return (
    <Container fixed>
      <Stack gap={4}>
        <div>
          <UserBaseUpdateForm {...props} />
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Rollen</Typography>
            <TenantRolesOverview userId={id} data={data} loading={loading} error={error} refetch={refetch} />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}

export default UserUpdateForm;
