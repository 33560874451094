import PageCard from '@boilerplate/components/PageCard';
import IdRenderer from '@boilerplate/components/entity/EntityTable/CellRenderers/IdRenderer';
import { Actions } from '@boilerplate/components/entity/EntityTable/CellRenderers/types';
import EntityTable from '@boilerplate/components/entity/EntityTable/EntityTable';
import { Delete as DeleteIcon, Edit as EditIcon, Check as CheckIcon, Remove as CrossIcon } from '@mui/icons-material';
import { Avatar, Button, Card, CardContent, CircularProgress, Tooltip, Typography } from '@mui/material';
import { camelCase } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Entity from '@/entities/user';
import UserTenantRoleEntityBase from '@/entities/userTenantRole';
import { GetUserQuery, useCreatedUserSubscription, useUpdatedUserSubscription, useDeletedUserSubscription, User } from '@/graphql';
import { catchGraphQlError } from '@/lib/catchError';
import { canPerformAction, useAuthenticatedUserStore } from '@/stores/UserStore';

import { CustomActionButtons } from './ActionButtons';

const avatarSize = { width: 144, height: 144 };

const UserTenantRoleEntity = {
  ...UserTenantRoleEntityBase,
  table: {
    ...UserTenantRoleEntityBase.table,
    schema: UserTenantRoleEntityBase.table.schema.filter((tableSchema) => tableSchema.field !== 'user'),
  },
};

const useStyles = makeStyles()(({ spacing, palette, breakpoints }) => ({
  pageHeading: {
    [breakpoints.down('xl')]: {
      flexWrap: 'wrap',
    },
  },
  pageHeadingText: {
    whiteSpace: 'nowrap',
  },
  headerButtons: {
    gridTemplateColumns: 'repeat(5, 1fr)',
    display: 'grid',
    gap: spacing(2),

    [breakpoints.down('xl')]: {
      width: '100%',
    },

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  header: {
    gridTemplateColumns: '144px 1fr',
    alignItems: 'center',
    position: 'relative',
    padding: spacing(4),
    display: 'grid',
    gap: spacing(4),

    [breakpoints.down('sm')]: {
      gridTemplateRows: '144px 1fr',
      gridTemplateColumns: 'unset',
    },
  },
  avatar: {
    fontSize: '6rem',
    margin: 'auto',
  },
  emailField: {
    display: 'flex',
    gap: spacing(0.5),

    '&>svg': {
      fontSize: '1rem',
    },
  },
  statusIndicator: {
    borderRadius: '100%',
    height: 20,
    width: 20,

    position: 'absolute',
    right: spacing(2),
    top: spacing(2),
  },
  active: { backgroundColor: palette.success.main },
  inactive: { backgroundColor: palette.error.main },

  card: {
    marginTop: spacing(4),
  },
}));

export default function UserView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState<GetUserQuery>();

  const user = useAuthenticatedUserStore();

  const actions = Entity.model.getFull({
    variables: {
      id: id!,
    },
    onCompleted: (res) => {
      setData(res);
    },
  });

  const [remove] = Entity.model.useDelete();

  const [canUpdate, canDelete] = useMemo(() => {
    const entityName = camelCase(Entity.name);

    return [canPerformAction('update', entityName, user), canPerformAction('delete', entityName, user)];
  }, [user]);

  const formattedActions = useMemo<Actions>(
    () => ({ loading: actions.loading, refetch: actions.refetch, remove }),
    [actions.loading, actions.refetch, remove]
  );

  const [deleteMutation] = Entity.model.useDelete();

  const formattedCreatedAt = useMemo(
    () =>
      data?.user?.createdAt ? new Date(data.user.createdAt).toLocaleString(i18n.languages, { dateStyle: 'medium', timeStyle: 'short' }) : null,
    [data?.user?.createdAt, i18n.languages]
  );

  const formatData = useCallback(
    (name: keyof User) =>
      data?.user?.[name]?.items ? { totalCount: data.user[name].totalCount, rows: data.user[name].items } : { totalCount: 0, rows: [] },
    [data?.user]
  );

  const userTenantRolesData = useMemo(() => formatData('userTenantRoles'), [formatData]);

  useCreatedUserSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ subscriptionData }) => {
      setData({ user: subscriptionData.data?.createdUser });
    },
  });

  useUpdatedUserSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ subscriptionData }) => {
      setData({ user: subscriptionData.data?.updatedUser });
    },
  });

  useDeletedUserSubscription({
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      navigate('/admin/user');
    },
  });

  const deleteUser = function () {
    deleteMutation({ variables: { id: id! } })
      .then(() => {
        navigate('/admin/user');
      })
      .catch(catchGraphQlError);
  };

  if (!data || !data.user) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageCard
        heading={t('users:view.view')}
        headingClassName={classes.pageHeading}
        headingTextClassName={classes.pageHeadingText}
        headingRight={
          <div className={classes.headerButtons}>
            <CustomActionButtons variant="outlined" Entity={Entity} actions={formattedActions} user={data.user ?? {}} />
            <Button variant="outlined" startIcon={<EditIcon />} component={Link} to="edit" disabled={!canUpdate}>
              {t('crud:update')}
            </Button>
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={deleteUser} disabled={!canDelete}>
              {t('crud:delete')}
            </Button>
          </div>
        }
        noBody
        arrowBack
      >
        <div className={classes.header}>
          <Avatar alt="Profile avatar" className={classes.avatar} src={data.user.avatar ?? undefined} sx={avatarSize}>
            {data.user.name?.[0] || '?'}
          </Avatar>

          <div>
            <Typography variant="h1">{data.user.name}</Typography>
            <Typography variant="body1" className={classes.emailField} gutterBottom>
              {data.user.email}
              <Tooltip title={t(data.user.emailVerifiedAt ? 'users:view.verified' : 'users:view.notVerified')}>
                {data.user.emailVerifiedAt ? (
                  <CheckIcon aria-label={t('users:view.verified')} tabIndex={0} />
                ) : (
                  <CrossIcon aria-label={t('users:view.notVerified')} tabIndex={0} />
                )}
              </Tooltip>
            </Typography>
            <div>
              <IdRenderer value={data.user.id} />
              &nbsp;&bull;&nbsp;
              <Typography variant="caption" title={data.user.createdAt}>
                {formattedCreatedAt}
              </Typography>
            </div>
          </div>

          <Tooltip title={t(data.user.active ? 'users:view.active' : 'users:view.inactive')} placement="top">
            <div
              className={`${classes.statusIndicator} ${data.user.active ? classes.active : classes.inactive}`}
              aria-label={t(data.user.active ? 'users:view.active' : 'users:view.inactive')}
              tabIndex={0}
            />
          </Tooltip>
        </div>
      </PageCard>

      {!!data.user.userTenantRoles?.items?.length && (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="p" gutterBottom>
              {t('users:view.userTenantRoles')}
            </Typography>
          </CardContent>

          <EntityTable Entity={UserTenantRoleEntity} data={userTenantRolesData} actions={formattedActions} noWrapper />
        </Card>
      )}
    </>
  );
}
