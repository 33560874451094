import { snakeCase } from 'change-case';
import { useTranslation } from 'react-i18next';

import { RenderProps } from './types';

export default function SelectRenderer({ value, Entity, field }: RenderProps): JSX.Element {
  const { t } = useTranslation();

  return value ? t(`entityFields:${Entity.name}.$${snakeCase(field)}_options.${value}`, value) : '';
}
