import { useAuthenticatedUserStore } from '@frontend/stores/UserStore';
import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogBase from '@boilerplate/components/DialogBase';
import EnableDialog from '@boilerplate/components/app/Profile/TwoFactor/EnableDialog';

import { use2FAInfo } from '.';

function TwoFARequired() {
  const { id: userId, isAuthenticated = false } = useAuthenticatedUserStore();
  const [enableOpen, setEnableOpen] = useState(false);
  const prevUserId = useRef('');
  const { t } = useTranslation();
  const { data, refetch } = use2FAInfo();

  useEffect(() => {
    if (isAuthenticated && prevUserId.current !== userId) {
      prevUserId.current = userId;
      refetch();
    }
  }, [isAuthenticated, refetch, userId]);

  if (!data) {
    return null;
  }

  return (
    <>
      <DialogBase
        gutterBottom
        id="2fa-required"
        open={data.required === true && !data.enabled}
        title={t('auth:twoFactor.required.title')}
        description={t('auth:twoFactor.required.description')}
      >
        <Button onClick={() => setEnableOpen(true)} variant="outlined" fullWidth>
          {t('auth:twoFactor.required.button')}
        </Button>
      </DialogBase>

      <EnableDialog open={enableOpen} onClose={() => setEnableOpen(false)} refetch={refetch} />
    </>
  );
}

export default TwoFARequired;
