import config from '@frontend/config';
import { hasPermission, useUserStore } from '@frontend/stores/UserStore';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

type FooterItemProps = React.HTMLProps<HTMLParagraphElement> & {
  style?: React.CSSProperties;
};

function FooterItem({ style, children, ...props }: FooterItemProps) {
  return (
    <p style={{ marginTop: 6, marginBottom: 6, ...style }} {...props}>
      {children}
    </p>
  );
}

export default function Footer() {
  const theme = useTheme();
  const user = useUserStore();

  return (
    <footer
      style={{
        backgroundColor: theme.palette.mode === 'light' ? '#ebebeb' : '#272727',
        paddingTop: 64,
        paddingBottom: 64,
      }}
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={12} md={9}>
            <Typography variant="h3">{config.app.name}</Typography>
            <FooterItem>&copy; {new Date().getFullYear()}</FooterItem>

            {hasPermission('admin-dashboard', user) && (
              <FooterItem>
                Build: <code>{config.app.gitCommit}</code>
              </FooterItem>
            )}
          </Grid>

          <Grid item sm={12} md={3}>
            <Typography variant="h3">Contact</Typography>
            <FooterItem>Duodeka Coöperatie U.A.</FooterItem>
            <FooterItem>Professor Verbernelaan 80</FooterItem>
            <FooterItem>
              <a href="tel:0031132032265" style={{ color: 'inherit' }}>
                +31 13 203 2264
              </a>
            </FooterItem>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
