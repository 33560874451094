import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()(() => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
}));

function PageLoading(): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress thickness={4} size={120} />
    </div>
  );
}

export default PageLoading;
