import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        machineName: {
          type: String,
          form: true,
          view: true,
        },
        displayName: {
          type: String,
          form: true,
          view: true,
        },
        description: {
          type: String,
          form: true,
          view: true,
        },
        permissions: {
          type: Array,
          relation: {
            name: 'HasMany',
            type: 'ManyToMany',
            model: 'permission',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        'permissions.$': {
          type: String,
          optional: true,
        },
        userTenantRoles: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'userTenantRole',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'userTenantRoles.$': {
          type: String,
          optional: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:role.id', 'entityFields:default.id'], '') || undefined,
        machineName: t(['entityFields:role.machine_name', 'entityFields:default.machine_name'], '') || undefined,
        displayName: t(['entityFields:role.display_name', 'entityFields:default.display_name'], '') || undefined,
        description: t(['entityFields:role.description', 'entityFields:default.description'], '') || undefined,
        permissions: t(['entityFields:role.permission', 'entityFields:default.permission'], '') || undefined,
        userTenantRoles: t(['entityFields:role.user_tenant_role', 'entityFields:default.user_tenant_role'], '') || undefined,
});

export default schema;