import { TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterProps } from './types';

export default function TextFilter({ fieldName, filterValue, setFilter }: FilterProps) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { value = '' } = event.target;

    setFilter(value);
  };

  return (
    <TextField
      type="number"
      size="small"
      sx={{ minWidth: '6ch' }}
      value={filterValue || ''}
      onChange={handleChange}
      placeholder={t('crud:search')}
      name={fieldName}
    />
  );
}
