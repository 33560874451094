import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import PermissionBaseOrderFormSchema from '@/_base/permission/PermissionBaseOrderFormSchema';
import PermissionBaseModel from '@/_base/permission/PermissionBaseModel';
import PermissionBaseSimpleSchema from '@/_base/permission/PermissionBaseSimpleSchema';
import PermissionBaseTableSchema from '@/_base/permission/PermissionBaseTableSchema';
import PermissionBaseInsertForm from '@/_base/permission/components/PermissionBaseInsertForm';
import PermissionBaseUpdateForm from '@/_base/permission/components/PermissionBaseUpdateForm';
import PermissionBaseTable from '@/_base/permission/components/PermissionBaseTable';
import PermissionBaseView from '@/_base/permission/components/PermissionBaseView';

const PermissionEntity: Entity<typeof PermissionBaseModel> = {
  name: 'permission',

  simpleSchema: PermissionBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(PermissionBaseSimpleSchema),
    orderSchema: [...PermissionBaseOrderFormSchema],
    component: PermissionBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(PermissionBaseSimpleSchema),
    orderSchema: [...PermissionBaseOrderFormSchema],
    component: PermissionBaseUpdateForm,
  },

  table: {
    schema: PermissionBaseTableSchema,
    component: PermissionBaseTable,
  },

  view: {
    component: PermissionBaseView,
  },

  model: PermissionBaseModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default PermissionEntity;
