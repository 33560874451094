import { UnfoldMore as UnfoldMoreIcon, UnfoldLess as UnfoldLessIcon } from '@mui/icons-material';
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
  Paper,
  Box,
  Autocomplete,
  Grid,
  CardContent,
  Stack,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import ProjectEntity from '@/entities/project';
import { LoggedHourHo } from '@/graphql';
import { dateOnly, dateToHHMM, HHMMToMinutes, minutesToHHMM } from '@/lib/formatTime';
import { isAppleDevice } from '@/lib/misc';
import { useLogHoursStore } from '@/stores/LogHoursStore';

import useStyles from './LogHoursStyles';

type OptionType = {
  id: string;
  label: string;
};

type ProjectOptionType = OptionType & {
  active: boolean;
};

export interface LoggedHoursFormData {
  project: ProjectOptionType | null;
  category: OptionType | null;
  description: string;
  date: Date;
  start: string;
  end: string;
  break: string;
  duration: string;
}
type LoggedHoursFormInputs = Omit<LoggedHoursFormData, 'duration' | 'date'> & { date?: Date };

const defaultLoggedHoursFormInputs: Omit<LoggedHoursFormInputs, 'date'> = {
  project: null,
  category: null,
  description: '',
  start: '',
  end: '',
  break: '00:00',
};

export type LoggedHourSubmitAction = 'create' | 'edit';

type LoggedHoursFormProps = {
  loggedHour?: LoggedHourHo;
  handleSubmit: (data: LoggedHoursFormData, callback?: (success: boolean) => void) => void;
  event?: React.FormEvent<HTMLFormElement>;

  /**
   * Use the tablet variant of the form.
   *
   * default: `true`
   */
  spread?: boolean;
};

function LoggedHoursForm({ loggedHour, handleSubmit }: LoggedHoursFormProps) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const { date } = useLogHoursStore();

  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(true);

  const { projects: projectsWithCategories = [], loading: loadingProjectsWithCategories } =
    ProjectEntity.model.useGetProjectsWithCategoriesHasHours();

  const edit = useMemo(() => !!loggedHour, [loggedHour]);

  const [formInputs, setFormInputs] = useState<LoggedHoursFormInputs>(() => {
    if (edit) {
      return {
        description: loggedHour?.description || '',
        start: dateToHHMM(loggedHour?.startDatetime, { leadingZero: true }),
        end: dateToHHMM(loggedHour?.endDatetime, { leadingZero: true }),
        break: minutesToHHMM(loggedHour?.break || 0),
        date: new Date(dateOnly(loggedHour?.startDatetime)),
        project: loggedHour?.project ? { id: loggedHour.project.id, label: loggedHour.project.name, active: loggedHour.project.active } : null,
        category: loggedHour?.category ? { id: loggedHour?.category.id, label: loggedHour?.category.name } : null,
      };
    }

    return {
      ...defaultLoggedHoursFormInputs,
      start: dateToHHMM(undefined, { leadingZero: true }),
      end: dateToHHMM(undefined, { leadingZero: true }),
    };
  });

  const projectOptions = useMemo(() => {
    return projectsWithCategories.map((project) => {
      return {
        id: project.id,
        active: project.active,
        label: [project?.customer?.name, `[${project.name}]`].filter(Boolean).join(' '),
      };
    });
  }, [projectsWithCategories]);

  const duration = useMemo(() => {
    const startHHMMToMinutes = HHMMToMinutes(formInputs.start);
    const startMinutes = startHHMMToMinutes || HHMMToMinutes(dateToHHMM());

    const endHHMMToMinutes = HHMMToMinutes(formInputs.end);
    const endMinutes = endHHMMToMinutes || HHMMToMinutes(dateToHHMM());

    let endStartDiff = endMinutes - startMinutes;

    if (endStartDiff < 0) {
      endStartDiff += 24 * 60;
    }

    const _break = HHMMToMinutes(formInputs.break);

    const calculated = endStartDiff - _break;

    return minutesToHHMM(calculated < 0 ? 0 : calculated);
  }, [formInputs.break, formInputs.end, formInputs.start]);

  const handleInputChange = useCallback((input: Partial<LoggedHoursFormInputs>) => {
    setFormInputs((prev) => ({ ...prev, ...input }));
  }, []);

  const categoryOptions = useMemo<OptionType[]>(() => {
    const projectWithCategories = projectsWithCategories.find(({ id }) => id === formInputs.project?.id);

    if (!projectWithCategories) {
      return [];
    }

    return projectWithCategories?.categories.items.map(({ id, name }) => ({
      id,
      label: name,
    }));
  }, [formInputs.project, projectsWithCategories]);

  useEffect(() => {
    /**
     * Upon changing the project, check if the selected child entries are part of the newly selected item.
     * If it is, keep it selected.
     * Otherwise, set it to `null`.
     */
    if (formInputs.project && !loadingProjectsWithCategories && !projectOptions.find(({ id }) => id === formInputs.project?.id)) {
      handleInputChange({ project: null });
    }

    if (formInputs.category && !loadingProjectsWithCategories && !categoryOptions.find(({ id }) => id === formInputs.category?.id)) {
      handleInputChange({ category: null });
    }
  }, [categoryOptions, formInputs.category, formInputs.project, handleInputChange, projectOptions, loadingProjectsWithCategories]);

  useEffect(() => {
    setFormInputs((prev) => ({
      ...prev,
      project: prev.project || (projectOptions.length === 1 ? projectOptions[0] : null),
    }));
  }, [projectOptions]);

  useEffect(() => {
    setFormInputs((prev) => ({
      ...prev,
      category: prev.category || (categoryOptions.length === 1 ? categoryOptions[0] : null),
    }));
  }, [categoryOptions]);

  const getSubmitData = () => (edit ? ({ ...formInputs, duration } as LoggedHoursFormData) : { ...formInputs, date, duration });

  const handleFormSubmit = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    handleSubmit(getSubmitData(), (success) => {
      setSubmitting(false);

      if (!success || edit) {
        return;
      }

      setFormInputs((prevFormInputs) => ({
        ...defaultLoggedHoursFormInputs,
        start: prevFormInputs.end,
        end: prevFormInputs.end,
      }));
    });
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleHeaderClick = () => {
    if (isSmallScreen) {
      setOpen((prev) => !prev);
    }
  };

  const handleKeyEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    if (submitting || event.code !== 'Enter') {
      return;
    }

    if (isAppleDevice() && !event.metaKey) {
      return;
    }

    if (!event.ctrlKey) {
      return;
    }

    const submitData = getSubmitData();

    // Check if the required fields are set
    if (!submitData.project || !submitData.category || !submitData.description || !submitData.duration || submitData.duration === '00:00') {
      return;
    }

    handleFormSubmit();
  };

  return (
    <Paper>
      <Box className={classes.heading} onClick={handleHeaderClick} component={isSmallScreen ? Button : 'div'}>
        <Typography variant="h5">{edit ? t('logHours:heading.edit') : t('logHours:heading.create')}</Typography>
        {open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </Box>

      <Collapse in={!isSmallScreen || open}>
        <CardContent onKeyDown={handleKeyEvent}>
          <Stack spacing={2}>
            {projectOptions.length !== 1 && (
              <Autocomplete
                fullWidth
                disablePortal
                options={projectOptions}
                groupBy={(option) => (option.active ? t('logHours:active') : t('logHours:inactive'))}
                value={formInputs.project} // This has to be null to be unset, currently removed undefined to hotfix this.
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => handleInputChange({ project: value })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: !!formInputs.project,
                      className: formInputs.project ? undefined : classes.autocompleteLabel,
                    }}
                    variant="outlined"
                    label={t('logHours:project_placeholder')}
                  />
                )}
                autoHighlight
                autoSelect
                disableClearable
                disabled={loadingProjectsWithCategories || projectOptions.length <= 1}
                classes={{
                  input: classes.autocompleteInput,
                  root: classes.autocompleteRoot,
                }}
              />
            )}

            {categoryOptions.length !== 1 && (
              <Autocomplete
                fullWidth
                disablePortal
                options={categoryOptions}
                value={formInputs.category}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => handleInputChange({ category: value })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: !!formInputs.category,
                      className: formInputs.category ? undefined : classes.autocompleteLabel,
                    }}
                    variant="outlined"
                    label={t('logHours:category_placeholder')}
                  />
                )}
                autoHighlight
                autoSelect
                disableClearable
                // groupBy, renderGroup
                disabled={loadingProjectsWithCategories}
                classes={{
                  input: classes.autocompleteInput,
                  root: classes.autocompleteRoot,
                }}
              />
            )}

            <TextField
              fullWidth
              label={t('logHours:description')}
              variant="outlined"
              multiline
              minRows={2}
              maxRows={5}
              value={formInputs.description}
              onChange={(e) => handleInputChange({ description: e.target.value })}
              InputProps={{
                classes: {
                  multiline: classes.noPadding,
                  input: classes.input,
                },
              }}
            />

            {edit && (
              <MobileDatePicker
                renderInput={(props) => <TextField {...props} InputProps={{ classes: { input: classes.input } }} variant="outlined" />}
                value={formInputs.date}
                onChange={(_date) => handleInputChange({ date: _date === null ? undefined : _date })}
              />
            )}

            <Grid container gap={1}>
              <Grid item flex={1}>
                <InputMask
                  mask="29:59"
                  formatChars={{
                    2: '[0-2]',
                    5: '[0-5]',
                    9: '[0-9]',
                  }}
                  alwaysShowMask
                  value={formInputs.start}
                  onChange={(e) => handleInputChange({ start: e.target.value })}
                >
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      fullWidth
                      label={t('logHours:start_time')}
                      variant="outlined"
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item flex={1}>
                <InputMask
                  mask="29:59"
                  formatChars={{
                    2: '[0-2]',
                    5: '[0-5]',
                    9: '[0-9]',
                  }}
                  alwaysShowMask
                  value={formInputs.end}
                  onChange={(e) => handleInputChange({ end: e.target.value })}
                >
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      fullWidth
                      label={t('logHours:end_time')}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.input },
                        endAdornment: (
                          <InputAdornment position="end" style={{ marginRight: '0.6rem', marginLeft: 0, position: 'absolute', right: 0 }}>
                            <a
                              onClick={() => handleInputChange({ end: dateToHHMM(undefined, { leadingZero: true }) })}
                              className={classes.nowButton}
                            >
                              {t('logHours:now')}
                            </a>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item flex={1}>
                <InputMask
                  mask="29:59"
                  formatChars={{
                    2: '[0-2]',
                    5: '[0-5]',
                    9: '[0-9]',
                  }}
                  alwaysShowMask
                  value={formInputs.break}
                  onChange={(e) => handleInputChange({ break: e.target.value })}
                >
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      fullWidth
                      label={t('logHours:break')}
                      variant="outlined"
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>

            <Grid container gap={1} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h3">{duration}</Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.logButton} onClick={handleFormSubmit} disabled={submitting}>
                  {edit ? t('logHours:submit_button.edit') : t('logHours:submit_button.create')}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Collapse>
    </Paper>
  );
}

export default LoggedHoursForm;
