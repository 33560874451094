import TenantMenu from '@frontend/components/app/Layout/TenantMenu';
import { PaletteModeContext } from '@frontend/components/root';
import config from '@frontend/config';
import { logout, useUserStore } from '@frontend/stores/UserStore';
import {
  ExitToApp as ExitToAppIcon,
  DarkModeOutlined as DarkModeOutlinedIcon,
  LightModeOutlined as LightModeOutlinedIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  useTheme,
} from '@mui/material';
import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PaperMenuProps } from './DefaultHeaderStyles';

export type DefaultHeaderProps = Parameters<typeof AppBar>[0] & {
  brandLink?: string;
  children?: ReactNode;
  startIcon?: ReactNode;
  noContainer?: boolean;
  hideTenantMenu?: boolean;
};

export default function DefaultHeader({
  brandLink = '/',
  children = null,
  startIcon = null,
  noContainer = false,
  hideTenantMenu = false,
  ...props
}: DefaultHeaderProps) {
  const { t } = useTranslation();
  const user = useUserStore();

  const theme = useTheme();
  const { togglePaletteMode } = useContext(PaletteModeContext);

  const isLogin = useLocation().pathname === '/login';

  const content = (
    <>
      {startIcon}

      <Button variant="text" component={Link} to={brandLink} sx={{ color: '#fff' }}>
        {config.app.name}
      </Button>

      <Box flexGrow={1} />

      {user.isAuthenticated && !hideTenantMenu && (
        <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
          <TenantMenu sx={{ color: 'white' }} variant="text" />
        </Box>
      )}

      <IconButton onClick={togglePaletteMode} sx={{ color: '#fff' }}>
        {theme?.palette?.mode === 'light' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
      </IconButton>

      <Box sx={{ display: 'inline' }}>
        {user.isAuthenticated && <AccountButton hideTenantMenu={hideTenantMenu}>{children}</AccountButton>}

        {!user.isAuthenticated && !isLogin && (
          <Button variant="text" component={Link} to="/login" sx={{ color: '#fff' }}>
            {t('headerLinks:link.login')}
          </Button>
        )}
      </Box>
    </>
  );

  return (
    <AppBar position="fixed" {...props}>
      <Toolbar sx={{ gap: 2 }}>
        {noContainer ? (
          content
        ) : (
          <Container maxWidth="lg">
            <Box display="flex" alignItems="center" gap={2}>
              {content}
            </Box>
          </Container>
        )}
      </Toolbar>
    </AppBar>
  );
}

type AccountButtonProps = {
  children?: ReactNode;
  hideTenantMenu: boolean;
};

function AccountButton({ children, hideTenantMenu }: AccountButtonProps) {
  const { t } = useTranslation();
  const user = useUserStore();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLabel = useMemo(() => {
    if (!user.isAuthenticated) {
      return '';
    }

    if (user.impersonatedBy) {
      return `${user.name} (${t('headerLinks:impersonating').toUpperCase()})`;
    }

    return user.name;
  }, [t, user.impersonatedBy, user.isAuthenticated, user.name]);

  return (
    <>
      <Tooltip title={t('headerLinks:link.profile')}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ color: 'white' }}
          aria-controls={open ? 'auth-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar alt={userLabel} src={user?.avatar}>
            {user?.name?.[0] ?? null}
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        open={open}
        anchorEl={anchorEl}
        id="auth-menu"
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={PaperMenuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>{userLabel}</MenuItem>

        {children}

        {user.isAuthenticated && !hideTenantMenu && (
          <Box component="li" sx={{ display: { xs: 'block', md: 'none' } }}>
            <Divider />
            <TenantMenu variant="text" sx={{ borderRadius: 0 }} fullWidth />
          </Box>
        )}

        <Divider sx={{ margin: { md: '8px 0' } }} />

        <MenuItem
          onClick={() => {
            logout().then(({ goToLogin }) => {
              if (goToLogin) {
                navigate('/login');
              } else {
                window.location.reload();
              }
            });
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          {t('headerLinks:link.logOut')}
        </MenuItem>
      </Menu>
    </>
  );
}
