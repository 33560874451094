import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function Loading() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '12px 0' }}>
      <CircularProgress />
    </div>
  );
}
