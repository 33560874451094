import Notistack from '@frontend/lib/notistack';
import { fetchUser, useAuthenticatedUserStore } from '@frontend/stores/UserStore';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageCard from '../PageCard';

function DefaultCompleteRegistrationPage() {
  const user = useAuthenticatedUserStore();
  const nav = useNavigate();
  const [newTenantName, setNewTenantName] = useState('');
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    axios
      .post('/tenant', { name: newTenantName, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then(() => {
        Notistack.toast(t('auth:completeRegistration.success'), { variant: 'success' });

        fetchUser().then(() => {
          nav('/profile');
        });
      })
      .catch(() => {
        Notistack.toast(t('auth:completeRegistration.error'), { variant: 'error' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  useEffect(() => {
    if (user.tenants.length > 0) {
      nav('/');
    }
  }, []);

  return (
    <PageCard heading={t('auth:completeRegistration.heading')}>
      <p>{t('tenants:createTenant')}</p>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={newTenantName}
          onChange={(e) => {
            setNewTenantName(e.target.value);
          }}
          sx={{ marginBottom: '1rem' }}
        />
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
          {t('crud:create')}
        </Button>
      </form>
    </PageCard>
  );
}

export default DefaultCompleteRegistrationPage;
