import config from '@frontend/config';
import UserEntity from '@frontend/entities/user';
import { useUpdateUserMutation } from '@frontend/graphql';
import Notistack from '@frontend/lib/notistack';
import { passwordStrength } from '@frontend/lib/passwordStrength';
import { fetchUser, updatePassword, uploadAvatar, useAuthenticatedUserStore } from '@frontend/stores/UserStore';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Avatar, Badge, Button, Card, CardContent, Link, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import React, { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/es/lib/isEmail';

import FormikSelect from '@boilerplate/components/FormikSelect';
import PageHeading from '@boilerplate/components/PageHeading';

import avatarPlaceholder from '../../../images/avatar-placeholder.jpg';
import DropzoneAnimation from '../../DropzoneAnimation';
import FormikTextField from '../../FormikTextField';
import PasswordStrengthIndicator from '../../auth/PasswordStrengthIndicator';

import TwoFactor from './TwoFactor';
import useDefaultProfilePageStyles from './useDefaultProfilePageStyles';

interface PasswordValues {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const initialPasswordValues: PasswordValues = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};

interface BaseValues {
  email: string;
  name: string;
  locale: string;
  timeZone: string;
}

export default function DefaultProfilePage({ noAvatar = false }: { noAvatar?: boolean }) {
  const { t } = useTranslation();

  const { classes } = useDefaultProfilePageStyles();
  const user = useAuthenticatedUserStore();
  const [update] = useUpdateUserMutation();

  const { id, name: oldName, email: oldEmail, avatar: oldAvatar, locale: oldLocale, timeZone: oldTimeZone } = user;

  const localeOptions = useMemo(
    () =>
      UserEntity.simpleSchema
        .getAllowedValuesForKey('locale')
        .map((locale) => ({ value: locale, label: t(`entityFields:user.$locale_options.${locale}`) })),
    [t]
  );
  const timeZoneOptions = useMemo(
    () =>
      UserEntity.simpleSchema
        .getAllowedValuesForKey('timeZone')
        .map((timeZone) => ({ value: timeZone, label: t(`entityFields:user.$time_zone_options.${timeZone}`) })),
    [t]
  );

  const updateAvatar = (incomingEmail: string, incomingFile: any) => {
    uploadAvatar(incomingEmail, incomingFile).then(() => {
      fetchUser();
    });
  };

  const validateBase = (values: BaseValues) => {
    const errors: FormikErrors<BaseValues> = {};

    if (!values.name) {
      errors.name = t('auth:validation.required');
    }

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    return errors;
  };

  const updateUser = (values: BaseValues, { setSubmitting }: FormikHelpers<BaseValues>) => {
    update({
      variables: {
        id,
        name: values.name,
        email: values.email,
        locale: values.locale,
        timeZone: values.timeZone,
      },
    })
      .then(() => {
        fetchUser();

        Notistack.toast(t('crud:updatedItem', { item: t('auth:profile.title') }), {
          variant: 'success',
        });
      })
      .catch((error: AxiosError) => {
        Notistack.toast(t(error?.response?.data?.message, 'auth:register.error.unknown'), { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validatePassword = (values: PasswordValues) => {
    const errors: FormikErrors<PasswordValues> = {};

    if (!values.currentPassword) {
      errors.currentPassword = t('auth:validation.required');
    }

    if (!values.password) {
      errors.password = t('auth:validation.required');
    } else if (passwordStrength(values.password) < config.auth.passwordStrength) {
      errors.password = t('auth:validation.passwordTooWeak');
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t('auth:validation.passwordConfirmationMustMatch');
    }

    return errors;
  };

  const handleUpdatePassword = (values: PasswordValues, { setSubmitting, resetForm }: FormikHelpers<PasswordValues>) => {
    updatePassword({
      currentPassword: values.currentPassword,
      password: values.password,
    })
      .then(() => {
        resetForm();

        Notistack.toast(t('crud:updatedItem', { item: t('auth:fields.password') }), {
          variant: 'success',
        });

        return fetchUser();
      })
      .catch((error: AxiosError) => {
        Notistack.toast(t([error?.response?.data?.message, 'auth:register.error.unknown']), { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleOAuthUpdatePassword = () => {
    window.location.href = `${config.flybase.frontendBaseUrl}/oauth/forgot-password?client_id=${config.flybase.publicKey}`;
  };

  return (
    <>
      <PageHeading title={t('crud:updateItem', { item: t('auth:profile.title') })} />

      <Stack spacing={4}>
        <Card>
          <CardContent>
            {!noAvatar && (
              <Dropzone multiple={false} onDrop={(acceptedFiles) => updateAvatar(oldEmail, acceptedFiles[0])}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div className={classes.profile} {...getRootProps()}>
                    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<CloudUploadIcon />}>
                      <input {...getInputProps()} />
                      <Avatar src={oldAvatar || avatarPlaceholder} alt="Profile avatar" className={classes.profileImage} />
                      {isDragActive && <DropzoneAnimation />}
                    </Badge>
                  </div>
                )}
              </Dropzone>
            )}

            <Formik
              initialValues={{ name: oldName, email: oldEmail, locale: oldLocale, timeZone: oldTimeZone }}
              validate={validateBase}
              onSubmit={updateUser}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormikTextField name="name" label={t('auth:fields.name')} />
                  <FormikTextField type="email" name="email" label={t('auth:fields.email')} disabled={config.flybase.oAuthEnabled} />
                  <FormikSelect name="locale" label={t('entityFields:user.locale')} options={localeOptions} />
                  <FormikSelect name="timeZone" label={t('entityFields:user.time_zone')} options={timeZoneOptions} />
                  <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
                    {t('crud:updateItem', { item: t('auth:profile.title') })}
                  </Button>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h2">{t('auth:changePassword.title')}</Typography>
            {config.flybase.oAuthEnabled ? (
              <Button sx={{ marginTop: '1rem' }} onClick={handleOAuthUpdatePassword}>
                {t('auth:changePassword.title')}
              </Button>
            ) : (
              <Formik initialValues={initialPasswordValues} validate={validatePassword} onSubmit={handleUpdatePassword}>
                {({ isSubmitting, values }) => (
                  <Form>
                    <FormikTextField type="password" name="currentPassword" label={t('auth:fields.currentPassword')} />
                    <FormikTextField type="password" name="password" label={t('auth:fields.newPassword')} />
                    <PasswordStrengthIndicator password={values.password} />
                    <FormikTextField type="password" name="passwordConfirmation" label={t('auth:fields.newPasswordConfirmation')} />
                    <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
                      {t('auth:changePassword.submit')}
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              {t('auth:twoFactor.title')}
            </Typography>
            <Typography variant="caption" component="p" marginBottom={3} gutterBottom>
              {t('auth:twoFactor.description')}
              <br />
              <Link href={t('auth:twoFactor.learnMore.url')} target="_blank" rel="noreferrer">
                {t('auth:twoFactor.learnMore.text')}
              </Link>
            </Typography>

            <TwoFactor user={user} />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
