import { catchGraphQlError } from '@frontend/lib/catchError';
import { openConfirmationDialog } from '@frontend/stores/ConfirmationDialogStore';
import { canPerformAction, useAuthenticatedUserStore } from '@frontend/stores/UserStore';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { camelCase } from 'change-case';
import React, { useMemo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import EntityType from '@boilerplate/types/entity';

import { ActionButtonBase } from './ActionButton';
import { Actions } from './types';

export default function DeleteButton({
  entityId,
  Entity,
  actions,
  disabled,
  onClick,
  onRemove,
}: {
  entityId: string;
  Entity: EntityType;
  actions: Actions;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onRemove?: () => void;
}) {
  const user = useAuthenticatedUserStore();
  const { t } = useTranslation();

  const canDelete = useMemo(() => canPerformAction('delete', camelCase(Entity.name), user), [Entity.name, user]);

  const handleDelete = (evt: MouseEvent<HTMLButtonElement>) => {
    onClick?.(evt);
    openConfirmationDialog({
      text: t('crud:delete'),
      approval: {
        handle: () => {
          actions
            .remove({ variables: { id: entityId } })
            .then(() => {
              onRemove?.();

              return actions.refetch();
            })
            .catch(catchGraphQlError);
        },
      },
    });
  };

  return (
    <ActionButtonBase icon={<DeleteIcon fontSize="inherit" />} onClick={handleDelete} disabled={!canDelete || disabled}>
      {t('crud:delete')}
    </ActionButtonBase>
  );
}
