import { camelCase, sentenceCase } from 'change-case';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import type { TFunction } from 'react-i18next';
import { BaseModel, Entity as EntityType } from './EntityTable/CellRenderers/types';
import { MapQueryBuilder } from './EntityTable/EntityTable';

const RANDOM_LEN = 16;
const ENCODING = '0123456789ABCDEFGHJKMNPQRSTVWXYZ'; // Crockford's Base32
const TIME_LEN = 10;

function isValidUlid(id: string): boolean {
  return (
    typeof id === 'string' &&
    id.length === TIME_LEN + RANDOM_LEN &&
    id
      .toUpperCase()
      .split('')
      .every((char) => ENCODING.indexOf(char) !== -1)
  );
}

/**
 *  Returns a sentenceCase pluralized Entity name.
 *
 * E.g. (t, 'user')=>  'Users'
 */
export const getTranslatedEntityNamePlural = (t: TFunction, entityName: string): string =>
  sentenceCase(t(`entities:${pluralize(camelCase(entityName))}`));

/**
 * Returns a capitalized translation of an entity and it's CRUD operation.
 *
 * E.g. (t, 'user', 'insert') => Add User
 */
export const getTranslatedEntityName = (t: TFunction, entityName: string, type: 'insert' | 'update' | 'view'): string =>
  capitalize(t(`crud:${type}Item`, { item: sentenceCase(t(`entities:${camelCase(entityName)}`), { stripRegexp: new RegExp('') }) }));

export const buildQueryFilter = (Entity: EntityType<BaseModel>, fieldName: string, value: string, mapQueryBuilder: MapQueryBuilder = {}) => {
  const { simpleSchema } = Entity;

  const relationDisplayField = simpleSchema._schema[fieldName]?.relation?.displayField as string | undefined;
  const type = simpleSchema.getDefinition(fieldName)?.type[0]?.type;
  const fieldSchema = Entity.table.schema;
  const isUlid = isValidUlid(value);

  if (typeof mapQueryBuilder[fieldName] === 'function') {
    return mapQueryBuilder[fieldName](value);
  }

  if (relationDisplayField && isUlid) {
    return {
      [fieldName]: {
        id: {
          eq: value,
        },
      },
    };
  }

  if (type?.name === 'String') {
    return relationDisplayField
      ? {
          [fieldName]: {
            [relationDisplayField]: {
              like: `%${value}%`,
            },
          },
        }
      : {
          [fieldName]: {
            like: fieldSchema.filterOptions ? value : `%${value}%`,
          },
        };
  } else if (type?.name === 'Number') {
    return { [fieldName]: { eq: parseInt(value, 10) } };
  } else if (type?.name === 'Boolean') {
    return { [fieldName]: value === 'unset' ? { isNull: true } : { eq: value === 'true' } };
  }

  return {};
};
