import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useField, FieldHookConfig } from 'formik';
import React, { ForwardedRef, Key, forwardRef } from 'react';

export type Option = { label: string; value: Key };

export type FormikSelectProps = {
  options: Option[];
};

export type FullFormikSelectProps = SelectProps & FormikSelectProps & FieldHookConfig<string>;

function FormikSelect(props: FullFormikSelectProps, ref: ForwardedRef<HTMLInputElement>) {
  const { label, options, required = false, disabled = false } = props;
  const [field, meta] = useField(props);

  return (
    <FormControl margin="normal" disabled={disabled || options.length <= 1} fullWidth>
      <InputLabel id={`${field.name}-select`} required={required}>
        {label}
      </InputLabel>
      <Select
        id={field.name}
        {...field}
        {...props}
        label={label}
        inputProps={{ ref }}
        labelId={`${field.name}-select`}
        error={meta.touched && meta.error !== undefined}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {!!meta.touched && !!meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

export default forwardRef<HTMLInputElement, FullFormikSelectProps>(FormikSelect);
