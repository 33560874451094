import config from '@frontend/config';
import { TextField, Typography, Button, Stepper, Step, StepLabel, List, ListItem, ListItemText, DialogContentText } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState, useEffect, MouseEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { makeStyles } from 'tss-react/mui';

import DialogBase from '@boilerplate/components/DialogBase';
import Loading from '@boilerplate/components/Loading';
import useCountdown from '@boilerplate/lib/useCountdown';

import { DialogProps } from './types';

export const useEnableDialogStyles = makeStyles()(({ spacing, palette }) => ({
  secretQr: {
    display: 'block',
    margin: 'auto',

    marginBottom: spacing(1),
    marginTop: spacing(2),
  },
  secretCode: {
    fontFamily: 'monospace',
    display: 'block',
  },
  stepper: {
    marginBottom: spacing(2),
    minWidth: 550,
  },
  backupCodes: {
    gridTemplateColumns: '1fr 1fr',
    maxWidth: '36ch',
    display: 'grid',
    margin: 'auto',
    marginBottom: spacing(1),
    marginTop: spacing(2),
    padding: 0,

    borderColor: palette.grey[700],
    borderStyle: 'solid',
    borderWidth: 1,

    '& li': {
      borderColor: palette.grey[700],
      borderStyle: 'dashed',
      borderWidth: 1,

      borderBottom: 'none',
      borderLeft: 'none',
    },

    '& li:nth-of-type(even)': {
      borderRight: 'none',
    },

    '& li:nth-of-type(-n + 2)': {
      borderTop: 0,
    },

    '& li span': {
      fontFamily: 'monospace',
      textAlign: 'center',
    },
  },
}));

export const saveToFile = (backupCodes: string[], date: string) => {
  const file = new File([backupCodes.join('\n')], `${config.app.name} backup codes - ${date}.txt`, { type: 'text/plain' });
  const a = document.createElement('a');
  const url = URL.createObjectURL(file);

  a.href = url;
  a.download = file.name;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 0);
};

function EnableDialog({ open, isRegenerate, onClose, refetch }: DialogProps & { isRegenerate?: boolean }) {
  const [secret, setSecret] = useState<{ secret: string; uri: string } | null>(null);
  const [error, setError] = useState<AxiosError<{ message?: string }>>();
  const [setupLoading, setSetupLoading] = useState(false);
  const [closeTimeout, setCloseTimeout] = useCountdown(0);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const { t, i18n } = useTranslation();
  const { classes } = useEnableDialogStyles();

  const handleSaveToFile = () => {
    saveToFile(backupCodes, new Date().toLocaleString(i18n.language, { dateStyle: 'short' }));

    setCloseTimeout(0);
  };

  const handleClose = () => {
    if (closeTimeout <= 0) {
      if (backupCodes.length > 0) {
        refetch();
      }

      onClose();
    }
  };

  const handleSetupFinish = (event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setError(undefined);

    axios
      .post(isRegenerate ? '/mfa/regenerate_backup_2fa' : '/mfa/verify_2fa', { password, token })
      .then(({ data }) => {
        setBackupCodes(data.backupCodes);

        // Prevent the dialog from closing for a couple of seconds
        setLoading(true);
        setCloseTimeout(15);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open && !isRegenerate) {
      setSetupLoading(true);
      setLoading(true);

      axios
        .post('/mfa/setup_2fa')
        .then(({ data }) => setSecret(data))
        .catch(setError)
        .finally(() => {
          setSetupLoading(false);
          setLoading(false);
        });
    }
  }, [open, isRegenerate]);

  useEffect(() => {
    if (!open) {
      setSecret(null);
      setSetupLoading(false);
      setCloseTimeout(0);
      setBackupCodes([]);
      setLoading(false);
      setPassword('');
      setToken('');
      setError(undefined);
    }
  }, [open, setCloseTimeout]);

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      title={t('auth:twoFactor.enable.title')}
      id="enable-2fa"
      noDefaultButtons
      buttons={
        <>
          <Button onClick={handleClose} variant="text" disabled={closeTimeout > 0}>
            {backupCodes.length <= 0 ? t('auth:twoFactor.close') : t('auth:twoFactor.enable.close')}
            {closeTimeout > 0 && <>&nbsp; ({closeTimeout}s)</>}
          </Button>
          {backupCodes.length <= 0 && (
            <Button variant="text" onClick={handleSetupFinish} disabled={(isRegenerate ? !password : !token || !password) || loading}>
              {t('auth:twoFactor.enable.next')}
            </Button>
          )}
        </>
      }
    >
      {!setupLoading ? (
        <>
          <Stepper className={classes.stepper} activeStep={backupCodes.length > 0 ? 1 : 0}>
            <Step>
              <StepLabel>{t('auth:twoFactor.enable.steps.configure')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('auth:twoFactor.enable.steps.saveBackup')}</StepLabel>
            </Step>
          </Stepper>

          <DialogContentText id="enable-2fa-dialog-description" gutterBottom>
            {backupCodes.length <= 0
              ? t(
                  isRegenerate ? 'auth:twoFactor.enable.description.configure.regenerate' : 'auth:twoFactor.enable.description.configure.enable'
                )
              : t('auth:twoFactor.enable.description.saveBackup.main')}
          </DialogContentText>

          {backupCodes.length <= 0 ? (
            <form onSubmit={handleSetupFinish}>
              {!isRegenerate && (
                <>
                  {secret?.uri && <QRCode value={secret.uri} className={classes.secretQr} />}
                  <Typography className={classes.secretCode} variant="caption" align="center" gutterBottom>
                    {secret?.secret}
                  </Typography>
                </>
              )}
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                label={t('auth:twoFactor.enable.password')}
                value={password}
                type="password"
                margin="dense"
                fullWidth
                required
              />
              {!isRegenerate && (
                <TextField
                  onChange={(e) => setToken(e.target.value)}
                  label={t('auth:twoFactor.enable.token')}
                  value={token}
                  margin="dense"
                  type="text"
                  fullWidth
                  required
                />
              )}
              <input type="submit" hidden />
            </form>
          ) : (
            <>
              <Typography gutterBottom>{t('auth:twoFactor.enable.description.saveBackup.warning')}</Typography>
              <List className={classes.backupCodes}>
                {backupCodes.map((code) => (
                  <ListItem key={code}>
                    <ListItemText primary={code} />
                  </ListItem>
                ))}
              </List>
              <Button variant="outlined" onClick={handleSaveToFile} fullWidth>
                {t('auth:twoFactor.enable.saveFile')}
              </Button>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
      {!!error && (
        <Typography color="error" align="center">
          {t(error.response?.data?.message, error.response?.data?.message || error.message || error.toString())}
        </Typography>
      )}
    </DialogBase>
  );
}

export default EnableDialog;
