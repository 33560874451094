import { Box } from '@mui/material';
import { addDays } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useCreateLoggedHourMutation } from '@/graphql';
import { dateToHHMM, getHoursMinutesFormattedDate, HHMMToMinutes } from '@/lib/formatTime';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

import LoggedHoursForm, { LoggedHoursFormData } from './LoggedHoursForm';

const useStyles = makeStyles()(() => ({
  formContainer: {
    width: '100%',
  },
}));

function CreateLoggedHours() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [createLoggedHour] = useCreateLoggedHourMutation();
  const { id: userId } = useAuthenticatedUserStore();
  const authenticatedUser = useAuthenticatedUserStore();

  const handleSubmit = (data: LoggedHoursFormData, callback?: (success: boolean) => void) => {
    if (data.start.includes('_') || data.end.includes('_')) {
      Notistack.toast?.(t('logHours:message.error.log'), { variant: 'error' });

      callback?.(false);

      return;
    }

    const startTime = HHMMToMinutes(data.start);
    const endTime = HHMMToMinutes(data.end);

    const endDate = endTime < startTime ? addDays(data.date, 1) : data.date;

    if (!data.project?.id) {
      Notistack.toast?.(t('logHours:message.error.no_project'), { variant: 'error' });
      callback?.(false);

      return;
    }

    if (!data.category?.id) {
      Notistack.toast?.(t('logHours:message.error.no_category'), { variant: 'error' });
      callback?.(false);

      return;
    }

    let endDatetime;
    let startDatetime;

    try {
      endDatetime = getHoursMinutesFormattedDate(endDate, HHMMToMinutes(data.end) ? data.end : dateToHHMM());
      startDatetime = getHoursMinutesFormattedDate(data.date, HHMMToMinutes(data.start) ? data.start : dateToHHMM());
    } catch (error) {
      console.error(error);
      Notistack.toast?.(t('logHours:message.error.log'), { variant: 'error' });
      callback?.(false);

      return;
    }

    createLoggedHour({
      variables: {
        categoryId: data.category?.id,
        break: HHMMToMinutes(data.break),
        endDatetime,
        startDatetime,
        declarableTime: null,
        description: data.description,
        projectId: data.project?.id,
        status: false,
        tenantId: authenticatedUser.currentTenant?.id,
        userId,
      },
    })
      .then(() => {
        Notistack.toast?.(t('logHours:message.success.log'), { variant: 'success' });
        callback?.(true);
      })
      .catch(({ message }) => {
        Notistack.toast?.(t([message, 'logHours:message.error.log']), { variant: 'error' });
        callback?.(false);
      });
  };

  return (
    <Box className={classes.formContainer}>
      <LoggedHoursForm handleSubmit={handleSubmit} />
    </Box>
  );
}

export default CreateLoggedHours;
