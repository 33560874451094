import TextField from '@mui/material/TextField';
import React from 'react';
import { connectField } from 'uniforms';

function LongTextField({ value, onChange, label, disabled, required }) {
  return (
    <TextField
      onChange={(event) => onChange(event.target.value)}
      disabled={disabled}
      required={required}
      value={value}
      label={label}
      multiline
      fullWidth
    />
  );
}

export default connectField(LongTextField);
