import { TFunction } from 'react-i18next';

import type { CellComponent, Schema } from '@boilerplate/components/entity/EntityTable/types';

const customActions = (cell: CellComponent, t: TFunction<string, undefined>, args: Schema = {}): Schema => ({
  label: t('entityFields:default.actions', 'Actions'),
  field: 'actions',
  sortable: false,
  cell,
  align: 'right',
  ...args,
});

export default customActions;
