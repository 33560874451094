import config from '@/config';
import * as Sentry from '@sentry/browser';

export default function initializeSentry() {
  if (config.sentry.dsn) {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.app.branch,
      release: config.app.gitCommit,
    });
  }
}
