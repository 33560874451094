import React from 'react';
import Button from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Notistack from '@/lib/notistack';

import { ActionButtonBase } from '@boilerplate/components/entity/EntityTable/CellRenderers/ActionButton';
import type DaysOffEntity from '@/entities/daysOff';

import { DaysOff } from '@/graphql';
import { CellRenderers } from '@/components/entity';
import { RenderProps, Actions } from '@boilerplate/components/entity/EntityTable/CellRenderers/types';

import config from '@/config';
import { camelCase } from 'lodash';

const { ActionButtons: DefaultActionButtons } = CellRenderers;

export type CustomActionButtonsProps = {
  Entity: typeof DaysOffEntity;
  dayOff: DaysOff;
  actions: Actions;
  icons?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
};

export function CustomActionButtons({ Entity, actions, icons, variant, dayOff }: CustomActionButtonsProps) {
  const [update, { loading }] = Entity.model.useUpdate();

  const handleAccept = () => {
    update({ variables: { id: dayOff.id, approved: true } })
      .then(() => actions.refetch())
      .catch((err: Error) => {
        Notistack.toast('Error accepting request', { variant: 'error' });
        console.error(err);
      });
  };

  const handleDeny = () => {
    update({ variables: { id: dayOff.id, approved: false } })
      .then(() => actions.refetch())
      .catch((err: Error) => {
        Notistack.toast('Error denying request', { variant: 'error' });
        console.error(err);
      });
  };

  return (
    <>
      <ActionButtonBase icon={<ThumbUpAltIcon />} onClick={handleAccept}>
        Accept
      </ActionButtonBase>
      <ActionButtonBase icon={<ThumbDownAltIcon />} onClick={handleDeny}>
        Deny
      </ActionButtonBase>
    </>
  );
}

export default function ActionButtons(props: RenderProps<typeof DaysOffEntity.model>) {
  const { Entity, actions, row } = props;

  return (
    <DefaultActionButtons {...props}>
      <CustomActionButtons Entity={Entity} actions={actions} dayOff={row as DaysOff} icons={true} />
    </DefaultActionButtons>
  );
}
