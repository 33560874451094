import { AuthenticatedUser, get2FAInfo, TwoFAInfo } from '@frontend/stores/UserStore';
import { Button, Switch, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import Loading from '@boilerplate/components/Loading';

import DisableDialog from './DisableDialog';
import EnableDialog from './EnableDialog';

export type TwoFactorProps = {
  user: AuthenticatedUser;
};

const useStyles = makeStyles()(({ spacing }) => ({
  switch: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    marginBottom: spacing(2),
    marginTop: spacing(1),
  },
  regenerateButton: {
    marginTop: spacing(2),
  },
}));

export function use2FAInfo() {
  const [data, setData] = useState<TwoFAInfo>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError<{ message?: string }>>();

  const refetch = useCallback(() => {
    setLoading(true);
    get2FAInfo()
      .then(setData)
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { data, error, loading, refetch };
}

function TwoFactor({ user }: TwoFactorProps) {
  const [regenerateOpen, setRegenerateOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data, loading, error, refetch } = use2FAInfo();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const disabled = useMemo(() => loading || (data?.enabled === true && data?.required === true), [data?.enabled, loading, data?.required]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Typography color="error">{error.response?.data?.message || error.message || error.toString()}</Typography>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {!!data?.required && <Typography gutterBottom>{t('auth:twoFactor.required.cantDisable')}</Typography>}

      <label className={classes.switch}>
        {t('auth:twoFactor.switch.off')}
        <Switch checked={data.enabled ?? false} disabled={disabled} onChange={() => setDialogOpen(true)} />
        {t('auth:twoFactor.switch.on')}
      </label>

      {!!data.enabled && (
        <>
          <Typography gutterBottom>{t('auth:twoFactor.remaining', data)}</Typography>

          <Button variant="outlined" className={classes.regenerateButton} onClick={() => setRegenerateOpen(true)}>
            {t('auth:twoFactor.regenerate.title')}
          </Button>
        </>
      )}

      <DisableDialog open={dialogOpen && !!data.enabled} onClose={() => setDialogOpen(false)} refetch={refetch} />
      <EnableDialog
        open={(dialogOpen && !data.enabled) || regenerateOpen}
        isRegenerate={regenerateOpen}
        refetch={refetch}
        onClose={() => {
          setDialogOpen(false);
          setRegenerateOpen(false);
        }}
      />
    </>
  );
}

export default TwoFactor;
