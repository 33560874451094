import { Maybe } from '@/graphql';
import { Scalars } from '@/graphql';

export const getDayOfWeek = (day: number, date?: Date | string) => {
  if (!date) {
    date = new Date();
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const _date = new Date(date);
  const first = _date.getDate() - (_date.getDay() === 0 ? 7 : _date.getDay()) + 1;
  const nth = first + day;

  const nthDay = new Date(_date.setDate(nth));

  return nthDay;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform#examples

export const isAppleDevice = () => navigator.platform.indexOf('Mac') === 0 || navigator.platform === 'iPhone';

export function convertToJSDate(graphqlDateTime: Maybe<Scalars['DateTime']>): Date {
  if (graphqlDateTime?.length > 0) {
    const date = new Date(graphqlDateTime);

    if (!isNaN(date)) {
      return date;
    }
  }

  return null;
}
