import { Typography } from '@mui/material';
import React from 'react';

function AuthLayoutRight() {
  return (
    <>
      <Typography variant="h2">Replan</Typography>

      <ul>
        <li>Log worked hours</li>
        <li>Get insights from the logged hours of your employees</li>
        <li>Get insights from projects and budgeting</li>
      </ul>
    </>
  );
}

export default AuthLayoutRight;
