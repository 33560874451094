import { TextField, Typography, Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogBase from '@boilerplate/components/DialogBase';

import { DialogProps } from './types';

function DisableDialog({ open, onClose, refetch }: DialogProps) {
  const [error, setError] = useState<AxiosError<{ message?: string }>>();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSave = () => {
    setLoading(true);
    axios
      .post('/mfa/disable_2fa', { password })
      .then(() => {
        onClose();
        refetch();
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      id="disable-2fa"
      title={t('auth:twoFactor.disable.title')}
      description={t('auth:twoFactor.disable.description')}
      gutterBottom
      buttons={
        <Button onClick={handleSave} variant="text" disabled={!password || loading}>
          {t('auth:twoFactor.disable.save')}
        </Button>
      }
    >
      <TextField
        onChange={(e) => setPassword(e.target.value)}
        label={t('auth:twoFactor.disable.label')}
        value={password}
        type="password"
        margin="dense"
        autoFocus
        fullWidth
        required
      />
      {!!error && (
        <Typography color="error" align="center">
          {t(error.response?.data?.message, error.response?.data?.message || error.message || error.toString())}
        </Typography>
      )}
    </DialogBase>
  );
}

export default DisableDialog;
