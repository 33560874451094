import budget from './budget.json';
import category from './category.json';
import content from './content.json';
import contentFill from './contentFill.json';
import customer from './customer.json';
import daysOff from './daysOff.json';
import defaultTranslations from './default.json';
import errorLog from './errorLog.json';
import eventLog from './eventLog.json';
import job from './job.json';
import loggedHour from './loggedHour.json';
import mailLog from './mailLog.json';
import mailTemplate from './mailTemplate.json';
import mailTemplateFill from './mailTemplateFill.json';
import permission from './permission.json';
import project from './project.json';
import resetPassword from './resetPassword.json';
import reportRecipient from './reportRecipient.json';
import role from './role.json';
import salary from './salary.json';
import tenant from './tenant.json';
import user from './user.json';
import userTenantRole from './userTenantRole.json';

export default {
  budget,
  category,
  content,
  contentFill,
  customer,
  daysOff,
  default: defaultTranslations,
  errorLog,
  eventLog,
  job,
  loggedHour,
  mailLog,
  mailTemplate,
  mailTemplateFill,
  permission,
  project,
  resetPassword,
  reportRecipient,
  role,
  salary,
  tenant,
  user,
  userTenantRole,
};
