import { Stack, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogBase from '@boilerplate/components/DialogBase';
import { BaseFormInsertProps } from '@boilerplate/types/entity';

import { Entity } from '../EntityTable/CellRenderers/types';
import { getTranslatedEntityName } from '../helpers';

export type InsertDialogProps = {
  InsertForm: Entity['form']['insert'];
  open: boolean;
  onClose: () => void;
  refetchRelations: () => Promise<void>;
  onChange?: (result: any) => void;
  name: string;
  insertFormProps?: BaseFormInsertProps<Entity['model']>;
};

function InsertDialog({ open, name, onClose, onChange, refetchRelations, InsertForm, insertFormProps }: InsertDialogProps): JSX.Element {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleInsertClose = async (result) => {
    setLoading(true);
    await refetchRelations();
    setLoading(false);

    onChange?.(result);
    onClose();
  };

  return (
    <DialogBase open={open} onClose={onClose} title={getTranslatedEntityName(t, name, 'insert')}>
      {!loading ? (
        <InsertForm {...insertFormProps} onClose={handleInsertClose} formOnly />
      ) : (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
    </DialogBase>
  );
}

export default InsertDialog;
