import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterProps } from './types';

export default function BooleanFilter({ fieldName, filterValue, setFilter, fieldSchema }: FilterProps) {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value = '' } = event.target;

    setFilter(value);
  };

  return (
    <Select
      labelId={`${fieldName}-label`}
      id={fieldName}
      size="small"
      sx={{ minWidth: '12ch' }}
      value={filterValue || ''}
      onChange={handleChange}
      placeholder={t('crud:select')}
    >
      <MenuItem value="">
        <i>{t('crud:all')}</i>
      </MenuItem>
      {!!fieldSchema.optional && <MenuItem value="unset">{t('crud:unset')}</MenuItem>}
      <MenuItem value="true">{t('crud:true')}</MenuItem>
      <MenuItem value="false">{t('crud:false')}</MenuItem>
    </Select>
  );
}
