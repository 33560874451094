import { addDays } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LoggedHoursForm, { LoggedHoursFormData } from './LoggedHoursForm';

import { LoggedHourHo, useUpdateLoggedHourMutation } from '@/graphql';
import { dateToHHMM, getHoursMinutesFormattedDate, HHMMToMinutes } from '@/lib/formatTime';
import Notistack from '@/lib/notistack';

interface EditLoggedHoursProps {
  loggedHour?: LoggedHourHo;
  onSuccess?: () => void;
}

function EditLoggedHours({ loggedHour, onSuccess }: EditLoggedHoursProps) {
  const { t } = useTranslation();
  const [updateLoggedHour] = useUpdateLoggedHourMutation();

  const handleSubmit = (data: LoggedHoursFormData, callback?: (success: boolean) => void) => {
    const startTime = HHMMToMinutes(data.start);
    const endTime = HHMMToMinutes(data.end);

    const endDate = endTime < startTime ? addDays(data.date, 1) : data.date;
    updateLoggedHour({
      variables: {
        id: loggedHour!.id,
        categoryId: data.category?.id,
        break: HHMMToMinutes(data.break),
        startDatetime: getHoursMinutesFormattedDate(data.date, HHMMToMinutes(data.start) ? data.start : dateToHHMM()),
        endDatetime: getHoursMinutesFormattedDate(endDate, HHMMToMinutes(data.end) ? data.end : dateToHHMM()),
        description: data.description,
        projectId: data.project?.id,
        tenantId: data.tenant?.id,
        status: false,
      },
    })
      .then(() => {
        Notistack.toast?.(t('logHours:message.success.edit'), { variant: 'success' });
        onSuccess?.();
        callback?.(true);
      })
      .catch(({ message }) => {
        Notistack.toast?.(t([message, 'logHours:message.error.edit']), { variant: 'error' });
        callback?.(false);
      });
  };

  return <LoggedHoursForm loggedHour={loggedHour} handleSubmit={handleSubmit} />;
}

export default EditLoggedHours;
