import { map, sortBy } from 'lodash';
import { useMemo } from 'react';

import ProjectBaseModel from '@/_base/project/ProjectBaseModel';
import { useGetProjectsWithCategoriesQuery } from '@/graphql';

const ProjectModel = {
  ...ProjectBaseModel,
  useGetProjectsWithCategories: () => {
    const hookResult = useGetProjectsWithCategoriesQuery();

    const projects = useMemo(() => {
      if (!hookResult.data?.projectWithCategories) {
        return [];
      }

      return sortBy(
        map(hookResult.data?.projectWithCategories?.items, (project) => ({
          ...project,
          categories: {
            items: sortBy(project.categories.items, ['name']).filter((category) => {
              if (category.subCategories?.items?.length) {
                return false;
              }

              return true;
            }),
          },
        })),
        ['customer.name', 'name']
      );
    }, [hookResult]);

    return {
      ...hookResult,
      projects,
    };
  },
  useGetProjectsWithCategoriesHasHours: () => {
    const hookResult = useGetProjectsWithCategoriesQuery();

    const projects = useMemo(() => {
      if (!hookResult.data?.projectWithCategories) {
        return [];
      }

      const formattedProjects = hookResult.data?.projectWithCategories?.items?.map((project) => ({
        ...project,
        categories: {
          items: sortBy(project.categories.items, ['name']).filter((category) => {
            if (!category.hasHours) {
              return false;
            }

            if (category.subCategories?.items?.length) {
              return false;
            }

            return true;
          }),
        },
      }));

      return sortBy(formattedProjects, ['customer.name', 'name']) as typeof formattedProjects;
    }, [hookResult]);

    return {
      ...hookResult,
      projects,
    };
  },
};

export default ProjectModel;
