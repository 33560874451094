// https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
const saveFileWithFSAA = async (file: File): Promise<void> => {
  const extension = file.name.split('.').at(-1);

  const newHandle = await window.showSaveFilePicker({
    suggestedName: file.name,
    types:
      extension && file.type
        ? [
            {
              accept: {
                [file.type]: `.${extension}`,
              },
            },
          ]
        : undefined,
  });

  const writableStream = await newHandle.createWritable();

  await writableStream.write(file);

  await writableStream.close();
};

export const saveFileWithUrl = (url: string, fileName?: string) => {
  const a = document.createElement('a');

  a.href = url;
  a.style.display = 'none';
  a.download = fileName ?? new URL(url).pathname.split('/').at(-1);
  a.rel = 'noopener';
  a.target = '_blank';

  document.body.appendChild(a);
  a.click();

  a.remove();
  URL.revokeObjectURL(a.href);

  return Promise.resolve();
};

export const saveFile = async (file: File): Promise<void> => {
  if ('showSaveFilePicker' in window) {
    return saveFileWithFSAA(file);
  }

  const url = URL.createObjectURL(file);
  const result = await saveFileWithUrl(url, file.name);
  URL.revokeObjectURL(url);

  return result;
};
