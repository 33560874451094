import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { PaperMenuProps, useDropdownStyles } from '@boilerplate/components/app/Layout/Header/DefaultHeaderStyles';
import { ArrowDropDown } from '@mui/icons-material';
import { UserType } from '@/entities/user/type';
import { setTenant, useAuthenticatedUserStore } from '@/stores/UserStore';

export default function TenantMenu(props: Parameters<typeof Button>[0]) {
  const { t } = useTranslation();
  const location = useLocation();
  const { classes: dropdownClasses, cx } = useDropdownStyles();

  const authUser = useAuthenticatedUserStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const allowAll = authUser.isSuperAdmin && location.pathname.startsWith('/admin');

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSetTenant = (tenant?: UserType['currentTenant']) => {
    setTenant(tenant).catch(console.error);
    setAnchorEl(null);
  };

  if (!authUser || authUser.tenants.length <= 1 || allowAll) {
    return null;
  }

  return (
    <>
      <Button onClick={handleClick} {...props}>
        {authUser.currentTenant?.name ?? t('tenants:allTenants')}

        <ArrowDropDown
          className={cx({
            [dropdownClasses.caret]: true,
            [dropdownClasses.caretActive]: Boolean(anchorEl),
          })}
        />
      </Button>
      <Menu
        id="tenant-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={PaperMenuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {authUser.tenants.map((tenant) => {
          return (
            <MenuItem key={tenant.id} onClick={() => handleSetTenant(tenant)}>
              {tenant.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
