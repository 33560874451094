import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetPermissionDocument,
  GetPermissionQuery,
  GetPermissionQueryVariables,
  GetPermissionsDocument,
  GetPermissionsQuery,
  GetPermissionsQueryVariables,
  useGetPermissionQuery,
  useGetPermissionLazyQuery,
  useGetPermissionsQuery,
  useGetPermissionsLazyQuery,

    useGetAllRelatedDataForPermissionQuery,
    useGetAllRelatedDataForPermissionLazyQuery,
    GetAllRelatedDataForPermissionQuery,
    GetAllRelatedDataForPermissionQueryVariables,

  CreatePermissionDocument,
  CreatePermissionMutation,
  CreatePermissionMutationVariables,
  useCreatePermissionMutation,

  DeletePermissionDocument,
  DeletePermissionMutation,
  DeletePermissionMutationVariables,
  useDeletePermissionMutation,

  UpdatePermissionDocument,
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables,
  useUpdatePermissionMutation,

    useCreatedPermissionSubscription,
    useUpdatedPermissionSubscription,
    useDeletedPermissionSubscription,
    useRestoredPermissionSubscription,
} from '@/graphql';

  type PermissionCollection = Dictionary<NonNullable<GetPermissionsQuery['permissions']['items']>[number]>;

const PermissionBaseModel = {
  get: (options: Omit<QueryOptions<GetPermissionQueryVariables, GetPermissionQuery>, 'query'>) => {
    return apolloClient.query<GetPermissionQuery, GetPermissionQueryVariables>({
      ...options,
      query: GetPermissionDocument,
    })
    .then(({ data }) => data.permission);
  },

  useGet: useGetPermissionQuery,

  getAll: (options?: Omit<QueryOptions<GetPermissionsQueryVariables, GetPermissionsQuery>, 'query'>) => {
    return apolloClient
      .query<GetPermissionsQuery, GetPermissionsQueryVariables>({
        ...options,
        query: GetPermissionsDocument
      })
      .then(({ data }) => data.permissions.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) => {
    const hookResult = useGetPermissionsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.permissions?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForPermissionQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForPermissionQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetPermissionLazyQuery,

  useGetAllLazy: useGetPermissionsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForPermissionLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreatePermissionMutation, CreatePermissionMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreatePermissionMutation, CreatePermissionMutationVariables>({
      ...options,
      mutation: CreatePermissionDocument,
    });
  },

  useCreate: useCreatePermissionMutation,

  update: (options: Omit<MutationOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdatePermissionMutation, UpdatePermissionMutationVariables>({
      ...options,
      mutation: UpdatePermissionDocument,
    });
  },

  useUpdate: useUpdatePermissionMutation,

  delete: (options: Omit<MutationOptions<DeletePermissionMutation, DeletePermissionMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeletePermissionMutation, DeletePermissionMutationVariables>({
      ...options,
      mutation: DeletePermissionDocument,
    });
  },

  useDelete: useDeletePermissionMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) => {
      const [collection, setCollection] = useState<PermissionCollection>({});

      const { items, loading, error, refetch } = PermissionBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedPermissionSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdPermission?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdPermission.id]: data.createdPermission,
            }));
          }
        },
      });

      useUpdatedPermissionSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedPermission?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedPermission.id]: data.updatedPermission,
            }));
          }
        },
      });

      useDeletedPermissionSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedPermission?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedPermission.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default PermissionBaseModel;
