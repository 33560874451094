export const minutesToHHMM = (minutes: number | string | undefined): string => {
  if (typeof minutes === 'string') {
    minutes = parseInt(minutes);
  }

  if (typeof minutes === 'undefined') {
    return '';
  }

  const hh = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  const mm = Math.floor(minutes % 60)
    .toString()
    .padStart(2, '0');

  return `${hh}:${mm}`;
};

export const HHMMToMinutes = (duration: string) => {
  const s = duration.split(':');

  const hours = parseInt(s[0]?.slice(0, 2)) || 0;

  if (hours > 24) {
    return 0;
  }

  const minutes = s[1]?.length < 2 ? 0 : parseInt(s[1]?.slice(0, 2)) || 0;

  if (minutes > 60) {
    return 0;
  }

  return hours * 60 + minutes;
};

type ConvertToHHMMOptions = {
  /**
   * Add a leading zero to the hours
   *
   * @defaultValue `false`
   */
  leadingZero?: boolean;
};

export const dateToHHMM = (date?: Date | string, options?: ConvertToHHMMOptions): string => {
  if (!date) {
    date = new Date();
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  let hours = date.getHours().toString();

  if (options?.leadingZero) {
    hours = hours.padStart(2, '0');
  }

  // Minutes with leading zero's
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const dateOnly = (date?: Date | string): string => {
  if (!date) {
    date = new Date();
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
};

export const getHoursMinutesFormattedDate = (date: string | Date, timeString: string) => {
  let newDate;

  if (typeof date === 'string') {
    newDate = new Date(date);
  } else {
    newDate = date;
  }

  const s = timeString.split(':');

  newDate.setHours(parseInt(s[0]));
  newDate.setMinutes(parseInt(s[1]));

  return newDate.toISOString();
};
