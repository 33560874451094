import FormikCheckBox from '@boilerplate/components/FormikCheckBox';
import FormikTextField from '@boilerplate/components/FormikTextField';
import PasswordStrengthIndicator from '@boilerplate/components/auth/PasswordStrengthIndicator';
import { Button } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import config from '@/config';
import Notistack from '@/lib/notistack';
import { passwordStrength } from '@/lib/passwordStrength';
import { registerUser } from '@/stores/UserStore';

import DefaultAuthLayout from './Layout/AuthLayout';

interface FormValues {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAndConditions?: boolean;
}

const initialValues: FormValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  termsAndConditions: false,
};

export default function RegisterPage() {
  const { t } = useTranslation();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.name) {
      errors.name = t('auth:validation.required');
    }

    if (config.auth.termsAndConditionsEnabled && !values.termsAndConditions) {
      errors.termsAndConditions = t('auth:validation.required');
    }

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    if (!values.password) {
      errors.password = t('auth:validation.required');
    } else if (passwordStrength(values.password) < config.auth.passwordStrength) {
      errors.password = t('auth:validation.passwordTooWeak');
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t('auth:validation.passwordConfirmationMustMatch');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>) => {
    registerUser(values.name, values.email, values.password, values.termsAndConditions)
      .then((data) => {
        resetForm();

        Notistack.toast(t(`auth:register.success.${data.confirmAccount ? 'confirmation' : 'general'}`), {
          variant: 'success',
        });
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          setFieldError('email', t('auth:validation.emailAlreadyRegistered'));
        } else {
          Notistack.toast(t('auth:register.error.unknown'), { variant: 'error' });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <DefaultAuthLayout heading={t('auth:register.title')}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <Form>
            <FormikTextField name="name" label={t('auth:fields.name')} />

            <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

            <FormikTextField type="password" name="password" label={t('auth:fields.password')} />

            <PasswordStrengthIndicator password={values.password} />

            <FormikTextField type="password" name="passwordConfirmation" label={t('auth:fields.passwordConfirmation')} />

            {config.auth.termsAndConditionsEnabled && (
              <FormikCheckBox
                name="termsAndConditions"
                label={
                  <Trans
                    i18nKey="auth:fields.termsAndConditions"
                    components={{
                      a: <a href="/Algemene_voorwaarden.pdf" target="_blank" />,
                    }}
                  />
                }
              />
            )}

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('auth:register.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      <p>
        <Link to="/login">{t('auth:links.alreadyHaveAnAccountLogin')}</Link>
      </p>
    </DefaultAuthLayout>
  );
}
