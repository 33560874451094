import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetCategoryDocument,
  GetCategoryQuery,
  GetCategoryQueryVariables,
  GetCategoriesDocument,
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
  useGetCategoryQuery,
  useGetCategoryLazyQuery,
  useGetCategoriesQuery,
  useGetCategoriesLazyQuery,

    useGetAllRelatedDataForCategoryQuery,
    useGetAllRelatedDataForCategoryLazyQuery,
    GetAllRelatedDataForCategoryQuery,
    GetAllRelatedDataForCategoryQueryVariables,

  CreateCategoryDocument,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
  useCreateCategoryMutation,

  DeleteCategoryDocument,
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
  useDeleteCategoryMutation,

  UpdateCategoryDocument,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
  useUpdateCategoryMutation,

    useCreatedCategorySubscription,
    useUpdatedCategorySubscription,
    useDeletedCategorySubscription,
    useRestoredCategorySubscription,
} from '@/graphql';

  type CategoryCollection = Dictionary<NonNullable<GetCategoriesQuery['categories']['items']>[number]>;

const CategoryBaseModel = {
  get: (options: Omit<QueryOptions<GetCategoryQueryVariables, GetCategoryQuery>, 'query'>) => {
    return apolloClient.query<GetCategoryQuery, GetCategoryQueryVariables>({
      ...options,
      query: GetCategoryDocument,
    })
    .then(({ data }) => data.category);
  },

  useGet: useGetCategoryQuery,

  getAll: (options?: Omit<QueryOptions<GetCategoriesQueryVariables, GetCategoriesQuery>, 'query'>) => {
    return apolloClient
      .query<GetCategoriesQuery, GetCategoriesQueryVariables>({
        ...options,
        query: GetCategoriesDocument
      })
      .then(({ data }) => data.categories.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) => {
    const hookResult = useGetCategoriesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.categories?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForCategoryQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForCategoryQuery, GetAllRelatedDataForCategoryQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForCategoryQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetCategoryLazyQuery,

  useGetAllLazy: useGetCategoriesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForCategoryLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateCategoryMutation, CreateCategoryMutationVariables>({
      ...options,
      mutation: CreateCategoryDocument,
    });
  },

  useCreate: useCreateCategoryMutation,

  update: (options: Omit<MutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateCategoryMutation, UpdateCategoryMutationVariables>({
      ...options,
      mutation: UpdateCategoryDocument,
    });
  },

  useUpdate: useUpdateCategoryMutation,

  delete: (options: Omit<MutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteCategoryMutation, DeleteCategoryMutationVariables>({
      ...options,
      mutation: DeleteCategoryDocument,
    });
  },

  useDelete: useDeleteCategoryMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) => {
      const [collection, setCollection] = useState<CategoryCollection>({});

      const { items, loading, error, refetch } = CategoryBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedCategorySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdCategory?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdCategory.id]: data.createdCategory,
            }));
          }
        },
      });

      useUpdatedCategorySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedCategory?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedCategory.id]: data.updatedCategory,
            }));
          }
        },
      });

      useDeletedCategorySubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedCategory?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedCategory.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredCategorySubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredCategory?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredCategory.id]: data.restoredCategory,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default CategoryBaseModel;
