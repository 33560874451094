import React from 'react';

import { RenderProps } from './types';

export default function CodeRenderer({ value = '' }: RenderProps) {
  if (!value) {
    return null;
  }

  return <code>{value.length > 144 ? `${value.substring(0, 144)}...` : value}</code>;
}
