import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import DaysOffContainer from '@/components/app/DaysOff/containers/DaysOffContainer';
import HomePage from '@/components/app/Home/HomePage';
import ContainedAppLayout from '@/components/app/Layout/ContainedAppLayout';
import ProfilePage from '@/components/app/Profile/ProfilePage';
import CompleteRegistrationPage from '@/components/auth/CompleteRegistrationPage';
import ConfirmationPage from '@/components/auth/ConfirmationPage';
import PageNotFound from '@/components/shared/PageNotFound';

import { Public, RequireAuth, RequireTenant } from './lib';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <ContainedAppLayout>
      <Routes location={location}>
        <Route
          key="home"
          path="/"
          element={
            <RequireAuth>
              <RequireTenant>
                <HomePage />
              </RequireTenant>
            </RequireAuth>
          }
        />
        <Route
          key="daysOff"
          path="/daysOff"
          element={
            <RequireAuth>
              <DaysOffContainer />
            </RequireAuth>
          }
        />
        <Route key="completeRegistration" path="completeRegistration" element={<CompleteRegistrationPage />} />
        <Route
          key="profile"
          path="/profile/*"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          key="confirm"
          path="/confirm/:confirmationCode"
          element={
            <Public>
              <ConfirmationPage />
            </Public>
          }
        />
        <Route
          key="error"
          path="*"
          element={
            <Public>
              <PageNotFound />
            </Public>
          }
        />
      </Routes>
    </ContainedAppLayout>
  );
}
