import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const CustomerBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:customer.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:customer.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'active',
        label: t(['entityFields:customer.active', 'entityFields:default.active'], 'active') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'projects',
        label: t(['entityFields:customer.project', 'entityFields:default.project'], 'project') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'tenant',
        label: t(['entityFields:customer.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:customer.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:customer.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const CustomerBaseTableSchema: TableSchema[] = [
  ...CustomerBaseTableSchemaWithoutActions,
  actions,
];

export { actions, CustomerBaseTableSchemaWithoutActions };
export default CustomerBaseTableSchema;
