import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetTenantDocument,
  GetTenantQuery,
  GetTenantQueryVariables,
  GetTenantsDocument,
  GetTenantsQuery,
  GetTenantsQueryVariables,
  useGetTenantQuery,
  useGetTenantLazyQuery,
  useGetTenantsQuery,
  useGetTenantsLazyQuery,

    useGetAllRelatedDataForTenantQuery,
    useGetAllRelatedDataForTenantLazyQuery,
    GetAllRelatedDataForTenantQuery,
    GetAllRelatedDataForTenantQueryVariables,

  CreateTenantDocument,
  CreateTenantMutation,
  CreateTenantMutationVariables,
  useCreateTenantMutation,

  DeleteTenantDocument,
  DeleteTenantMutation,
  DeleteTenantMutationVariables,
  useDeleteTenantMutation,

  UpdateTenantDocument,
  UpdateTenantMutation,
  UpdateTenantMutationVariables,
  useUpdateTenantMutation,

    useCreatedTenantSubscription,
    useUpdatedTenantSubscription,
    useDeletedTenantSubscription,
    useRestoredTenantSubscription,
} from '@/graphql';

  type TenantCollection = Dictionary<NonNullable<GetTenantsQuery['tenants']['items']>[number]>;

const TenantBaseModel = {
  get: (options: Omit<QueryOptions<GetTenantQueryVariables, GetTenantQuery>, 'query'>) => {
    return apolloClient.query<GetTenantQuery, GetTenantQueryVariables>({
      ...options,
      query: GetTenantDocument,
    })
    .then(({ data }) => data.tenant);
  },

  useGet: useGetTenantQuery,

  getAll: (options?: Omit<QueryOptions<GetTenantsQueryVariables, GetTenantsQuery>, 'query'>) => {
    return apolloClient
      .query<GetTenantsQuery, GetTenantsQueryVariables>({
        ...options,
        query: GetTenantsDocument
      })
      .then(({ data }) => data.tenants.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) => {
    const hookResult = useGetTenantsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.tenants?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForTenantQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForTenantQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetTenantLazyQuery,

  useGetAllLazy: useGetTenantsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForTenantLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateTenantMutation, CreateTenantMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateTenantMutation, CreateTenantMutationVariables>({
      ...options,
      mutation: CreateTenantDocument,
    });
  },

  useCreate: useCreateTenantMutation,

  update: (options: Omit<MutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateTenantMutation, UpdateTenantMutationVariables>({
      ...options,
      mutation: UpdateTenantDocument,
    });
  },

  useUpdate: useUpdateTenantMutation,

  delete: (options: Omit<MutationOptions<DeleteTenantMutation, DeleteTenantMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteTenantMutation, DeleteTenantMutationVariables>({
      ...options,
      mutation: DeleteTenantDocument,
    });
  },

  useDelete: useDeleteTenantMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) => {
      const [collection, setCollection] = useState<TenantCollection>({});

      const { items, loading, error, refetch } = TenantBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedTenantSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdTenant?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdTenant.id]: data.createdTenant,
            }));
          }
        },
      });

      useUpdatedTenantSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedTenant?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedTenant.id]: data.updatedTenant,
            }));
          }
        },
      });

      useDeletedTenantSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedTenant?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedTenant.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default TenantBaseModel;
