import { ApolloError } from '@apollo/client';
import Notistack from '@frontend/lib/notistack';
import { captureException } from '@sentry/browser';
import { GraphQLError } from 'graphql';
import i18n from 'i18next';
import { OptionsObject } from 'notistack';

export function catchGraphQlError(error: GraphQLError | ApolloError) {
  if (error instanceof ApolloError) {
    error.graphQLErrors.forEach((graphQLError) => catchGraphQlError(graphQLError));

    return;
  }

  // We will try to see if any of the error messages we receive are translated. Otherwise we'll show the default one.
  const message: string[] = [];

  if (error.extensions?.response?.message && Array.isArray(error.extensions.response?.message)) {
    message.push(...error.extensions?.response?.message);
  } else {
    message.push(error.extensions?.response?.message ?? error.message);
  }

  const statusCode: number | null = error.extensions?.response?.statusCode ?? null;
  let defaultMessage = 'messages:error';
  let variant: OptionsObject['variant'] = 'error';

  switch (statusCode) {
    case 422:
      defaultMessage = 'messages:errorFillAllFields';
      break;
    case 413:
      defaultMessage = 'messages:errorContentTooLarge';
      break;
    case 404:
      defaultMessage = 'messages:errorNotFound';
      variant = 'info';
      break;
    case 403:
      defaultMessage = 'messages:errorUnauthorized';
      break;
    case 401:
      defaultMessage = 'messages:errorUnauthenticated';
      break;
    default:
      break;
  }

  console.error(error);

  console.log({ message, error });

  Notistack.toast(i18n.t([...message, defaultMessage]), { variant });
}
