import { merge } from 'lodash';
import React, { useMemo, useCallback } from 'react';

import { RoleSortFields, SortDirection, GetRolesQueryVariables } from '@/graphql';
import { BaseTableProps } from '@boilerplate/types/entity';
import Entity from '@/entities/role';
import EntityTable, {
  defaultPage,
  defaultPageSize,
  defaultSortOrder,
  getDefaultSortField,
} from '@boilerplate/components/entity/EntityTable/EntityTable';
import { canPerformAction, useAuthenticatedUserStore } from '@/stores/UserStore';
import { openConfirmationDialog } from '@/stores/ConfirmationDialogStore';


export const useRolesTable = ({ queryOptions }: { queryOptions?: GetRolesQueryVariables } = {}) => {
  const defaultVariableOptions = useMemo<GetRolesQueryVariables>(
    () => 
      merge(
        {
          sorting: {
            direction: defaultSortOrder === 'desc' ? SortDirection.Desc : SortDirection.Asc,
            field: getDefaultSortField(Entity.table.schema) as RoleSortFields,
          },
          paging: {
            page: defaultPage,
            pageSize: defaultPageSize,
          },
                  withPermissions: true,
          },
          queryOptions,
        ), 
    [queryOptions]
    );
  
  const {
    data: roleData,
    items = [],
    loading,
    refetch: originalRefetch,
    variables,
  } = Entity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
    variables: defaultVariableOptions,
  });

  const [remove] = Entity.model.useDelete();

  const refetch = useCallback(
    (variableOptions?: Partial<GetRolesQueryVariables >) => {
      return originalRefetch(merge({}, defaultVariableOptions, variableOptions));
    },
    [defaultVariableOptions, originalRefetch]
  );

  const allActions = useMemo(() => ({ loading, refetch, remove }), [loading, refetch, remove]);
  const data = useMemo(
    () =>
      roleData
        ? { totalCount: roleData.roles.totalCount, rows: items }
        : { totalCount: 0, rows: [] },
    [roleData, items]
  );

  return { data, actions: allActions, refetch, defaultVariableOptions, variables };
}

export type RolesBaseTableProps = BaseTableProps<typeof Entity.model>;

export default function RolesBaseTable(props: RolesBaseTableProps) {
  const tableProps = useRolesTable();
  const authentictedUser = useAuthenticatedUserStore();

  return (
    <EntityTable
      {...props}
      {...tableProps}
      Entity={Entity}
      user={authentictedUser}
      canPerformThisAction={canPerformAction}
      openConfirmationDialog={openConfirmationDialog}
    />
  );
}
