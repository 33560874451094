import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { useField, FieldProps } from 'uniforms';

import { DateTimeFieldBase } from './DateTimeField';

export default function DateTimeField(props: FieldProps<Date | null, unknown>) {
  // eslint-disable-next-line react/destructuring-assignment
  const [field] = useField(props.name, props, { initialValue: true });

  const handleChange = (date?: Date | null) => {
    if (!date) {
      field.onChange(date);

      return;
    }

    date.setHours(0, 0, 0, 0);
    field.onChange(date);
  };

  return <DateTimeFieldBase props={{ ...props, ...field, onChange: handleChange }} component={DatePicker} />;
}
