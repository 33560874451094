import { Card, CardContent } from '@mui/material';
import React from 'react';

import PageHeading from './PageHeading';

type PageCardProps = {
  heading: string;
  headingRight?: React.ReactNode;
  arrowBack?: boolean;
  noBody?: boolean;
  children: React.ReactNode;
  headingClassName?: string;
  headingTextClassName?: string;
};

function PageCard({
  heading,
  headingRight,
  arrowBack = false,
  noBody = false,
  children,
  headingClassName,
  headingTextClassName,
}: PageCardProps) {
  return (
    <>
      <PageHeading
        title={heading}
        right={headingRight}
        arrowBack={arrowBack}
        className={headingClassName}
        textClassName={headingTextClassName}
      />
      <Card>{!noBody ? <CardContent>{children}</CardContent> : children}</Card>
    </>
  );
}

export default PageCard;
