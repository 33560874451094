import { ApolloQueryResult } from '@apollo/client';
import { Button, TextField, Box, CardContent, Stack } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DaysOff, GetDaysOffsQuery, GetDaysOffsQueryVariables, useCreateDaysOffMutation, useUpdateDaysOffMutation } from '@/graphql';
import { convertToJSDate } from '@/lib/misc';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

type RequestFormData = {
  startDate: Date;
  endDate: Date;
  note: string;
  approved?: boolean | undefined;
  name: string;
};

type DaysOffFormProps = {
  refetch: (variables?: GetDaysOffsQueryVariables) => Promise<ApolloQueryResult<GetDaysOffsQuery>>;
  dayOff?: DaysOff | undefined;
  handleClose: () => void;
};

const defaultDaysOffFormInputs: RequestFormData = {
  startDate: Date.now() as unknown as Date,
  endDate: Date.now() as unknown as Date,
  note: '',
  name: '',
};

function DaysOffForm({ refetch, dayOff, handleClose }: DaysOffFormProps) {
  const [createDaysOff] = useCreateDaysOffMutation();
  const [updateDaysOff] = useUpdateDaysOffMutation();
  const user = useAuthenticatedUserStore();
  const [formInputs, setFormInputs] = useState<RequestFormData>(defaultDaysOffFormInputs);
  const { t } = useTranslation();

  const handleChange = useCallback((input: Partial<RequestFormData>) => {
    setFormInputs((prev) => ({ ...prev, ...input }));
  }, []);

  const handleFormSubmit = () => {
    if (formInputs.startDate > formInputs.endDate) {
      Notistack.toast?.(t('daysOff:error.date'));

      return;
    }

    if (dayOff === undefined) {
      createDaysOff({
        variables: {
          ...formInputs,
          userId: user.id,
          tenantId: user?.currentTenant?.id as string,
        },
      })
        .then(() => {
          refetch();
          handleClose();
          Notistack.toast?.(t('daysOff:success:create'));
        })
        .catch(() => {
          Notistack.toast?.(t('daysOff:error.create'));
        });
    } else {
      updateDaysOff({
        variables: {
          id: dayOff.id,
          ...formInputs,
        },
      })
        .then(() => {
          refetch();
          handleClose();
          Notistack.toast?.(t('daysOff:success:update'));
        })
        .catch(() => {
          Notistack.toast?.(t('daysOff:error.update'));
        });
    }
  };

  useEffect(() => {
    if (dayOff) {
      setFormInputs({
        startDate: convertToJSDate(dayOff.startDate),
        endDate: convertToJSDate(dayOff.endDate),
        note: dayOff?.note === undefined ? '' : (dayOff?.note as string),
        name: '',
      });
    }
  }, []);

  return (
    <CardContent>
      <Stack spacing={4}>
        <Box>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
            <DateTimePicker
              label={t('daysOff:start_date')}
              value={formInputs.startDate}
              onChange={(_date) => handleChange({ startDate: _date === null ? undefined : _date })}
              renderInput={(params) => <TextField {...params} />}
            />
            <DateTimePicker
              label={t('daysOff:end_date')}
              value={formInputs.endDate}
              onChange={(_date) => handleChange({ endDate: _date === null ? undefined : _date })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </Box>
        <TextField
          id="outlined-basic"
          label={t('daysOff:note')}
          variant="outlined"
          value={formInputs.note}
          minRows={2}
          maxRows={5}
          onChange={(e) => handleChange({ note: e.target.value })}
        />
        <Button onClick={handleFormSubmit}>{t('daysOff:request.send')}</Button>
      </Stack>
    </CardContent>
  );
}

export default DaysOffForm;
