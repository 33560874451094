import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import DaysOffBaseModel from '@/_base/daysOff/DaysOffBaseModel';
import DaysOffBaseOrderFormSchema from '@/_base/daysOff/DaysOffBaseOrderFormSchema';
import DaysOffBaseSimpleSchema from '@/_base/daysOff/DaysOffBaseSimpleSchema';
import DaysOffBaseTableSchema, { DaysOffBaseTableSchemaWithoutActions } from '@/_base/daysOff/DaysOffBaseTableSchema';
import DaysOffBaseInsertForm from '@/_base/daysOff/components/DaysOffBaseInsertForm';
import DaysOffBaseUpdateForm from '@/_base/daysOff/components/DaysOffBaseUpdateForm';
import customActions from '@boilerplate/lib/customActions';
import ActionButtons from '@/components/admin/Entity/daysOff/ActionButtons';
import { t } from 'i18next';
import DaysOffsBaseTable from '@/_base/daysOff/components/DaysOffBaseTable';
import DaysOffBaseView from '@/_base/daysOff/components/DaysOffBaseView';

const DaysOffEntity: Entity<typeof DaysOffBaseModel> = {
  name: 'days_off',

  simpleSchema: DaysOffBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(DaysOffBaseSimpleSchema),
    orderSchema: [...DaysOffBaseOrderFormSchema],
    component: DaysOffBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(DaysOffBaseSimpleSchema),
    orderSchema: [...DaysOffBaseOrderFormSchema],
    component: DaysOffBaseUpdateForm,
  },

  view: {
    component: DaysOffBaseView,
  },

  table: {
    schema: [...DaysOffBaseTableSchemaWithoutActions, customActions(ActionButtons, t)],
    component: DaysOffsBaseTable,
  },

  routes: {},

  model: DaysOffBaseModel,

  settings: {
    displayField: 'id',
  },
};

export default DaysOffEntity;
