import { CloudUpload } from '@mui/icons-material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  iconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    padding: 15,
  },
  icon: {
    width: '100% !important',
    height: '100% !important',
    margin: '0 !important',
  },
}));

function DropzoneAnimation() {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.iconContainer}>
      <CloudUpload className={classes.icon} />
    </div>
  );
}

export default DropzoneAnimation;
