import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const DaysOffBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:daysOff.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'startDate',
        label: t(['entityFields:daysOff.start_date', 'entityFields:default.start_date'], 'startDate') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
      },
      {
          field: 'endDate',
        label: t(['entityFields:daysOff.end_date', 'entityFields:default.end_date'], 'endDate') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
      },
      {
          field: 'approved',
        label: t(['entityFields:daysOff.approved', 'entityFields:default.approved'], 'approved') || undefined,
          cell: CellRenderers['ApproveRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['ApproveFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'note',
        label: t(['entityFields:daysOff.note', 'entityFields:default.note'], 'note') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
        optional: true,
      },
      {
          field: 'user',
        label: t(['entityFields:daysOff.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'tenant',
        label: t(['entityFields:daysOff.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:daysOff.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:daysOff.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const DaysOffBaseTableSchema: TableSchema[] = [
  ...DaysOffBaseTableSchemaWithoutActions,
  actions,
];

export { actions, DaysOffBaseTableSchemaWithoutActions };
export default DaysOffBaseTableSchema;
