import { useEffect, useState } from 'react';

export default function useCountdown(initialValue = 0) {
  const state = useState(initialValue);
  const [count, setCount] = state;

  useEffect(() => {
    if (count > 0) {
      const timeout = setTimeout(() => {
        setCount((prev) => prev - 1);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [count, setCount]);

  return state;
}
