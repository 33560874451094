import config from '@/config';

type TokenResponse = { token: string; expiresAt: number };

const resultListeners: ((token: string) => void)[] = [];
let tokenCache: TokenResponse | undefined;
let isFetching = false;

const fetchToken = async () => {
  const response = await fetch(`${config.app.backendUrl}/csrf_token`, { credentials: 'include' });
  const data = (await response.json()) as TokenResponse;

  return data;
};

export const getToken = async (): Promise<string> => {
  if (tokenCache) {
    if (Date.now() < tokenCache.expiresAt - 1000 * 30) {
      return tokenCache.token;

      // If the token has expired, reset the cache
      // eslint-disable-next-line no-else-return
    } else {
      tokenCache = undefined;

      // eslint-disable-next-line no-console
      console.log('%cToken expired. Refetching...', 'color: green');
    }
  }

  if (isFetching) {
    return new Promise<string>((resolve) => resultListeners.push(resolve));
  }

  try {
    isFetching = true;
    tokenCache = await fetchToken();
  } finally {
    isFetching = false;
  }

  if (!tokenCache) {
    throw new Error('Token cache not set');
  }

  // @ts-expect-error: TypeScript thinks 'tokenCache' could be undefined
  resultListeners.forEach((cb) => cb(tokenCache.token));
  resultListeners.length = 0;

  return tokenCache.token;
};

export const csrfMethods = Object.freeze(['post', 'put', 'patch'].map((method) => method.toLowerCase()));

export const isCsrfMethod = (method: string): boolean => csrfMethods.includes(method.toLowerCase());
