import Notistack from '@frontend/lib/notistack';
import { useAuthenticatedUserStore, setTenant, logout } from '@frontend/stores/UserStore';
import { Box, Button, Card, CardActions, CardContent, Container, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import PageHeading from '../PageHeading';

export default function DefaultSwitchTenantPage({ redirect, label }: { redirect?: string; label?: string }) {
  const { t } = useTranslation();
  const user = useAuthenticatedUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const switchTo = (tenant) => {
    setLoading(true);
    setTenant(tenant)
      .then(() => {
        navigate(location?.state?.from || '/');
      })
      .catch((err) => {
        console.error(err);
        Notistack.toast(err.message || err.toString(), { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = () => {
    setLoading(true);
    logout()
      .catch((err) => {
        console.error(err);
        Notistack.toast(err.message || err.toString(), { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (user.tenants.length === 0) {
    return (
      <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <PageHeading title={t('tenants:noAvailableTenants.title')} />

          <Card>
            <CardContent>
              <Typography variant="body1">{t('tenants:noAvailableTenants.description')}</Typography>
            </CardContent>

            <CardActions>
              {!!redirect && <Button onClick={() => navigate(redirect)}>{label}</Button>}
              <Button onClick={handleLogout} disabled={loading}>
                {t('auth:logout')}
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Box>
    );
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center">
      <Container maxWidth="sm">
        <PageHeading title={t('tenants:pickTenant')} />

        <Stack spacing={2}>
          {user.tenants.map((tenant) => (
            <Button variant="outlined" key={tenant.id} onClick={() => switchTo(tenant)} disabled={loading}>
              {tenant.name}
            </Button>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
