import Notistack from '@frontend/lib/notistack';
import { canPerformAction, useAuthenticatedUserStore } from '@frontend/stores/UserStore';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Button, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { camelCase } from 'change-case';
import React, { useMemo, useState, MouseEvent, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import PageCard from '@boilerplate/components/PageCard';
import TableSchema, { Order } from '@boilerplate/types/tableSchema';

import { buildQueryFilter, getTranslatedEntityNamePlural } from '../helpers';

import ExportDownloadMenu from './ExportDownloadMenu';
import OffsetTablePagination from './OffsetTablePagination';
import TableHeadRow from './TableHeadRow';

import type { Actions, BaseModel, Entity as EntityType, Row } from './CellRenderers/types';
import { debounce, merge } from 'lodash';

export const defaultPage = 0;
export const defaultPageSize = 10;
export const defaultSortOrder = 'desc';
export const getDefaultSortField = (tableSchema: TableSchema[]) =>
  tableSchema.find((schema) => schema.field === 'createdAt') ? 'createdAt' : 'id';

export type RowClick<TRow extends Row<BaseModel>> = {
  disabled?: number[];
  skip?: number[];
  onClick: (event: MouseEvent<HTMLElement>, row: TRow, index: number) => void;
};

/**
 * Map certain fields over to a different field. Example SAM-Tool -> tenants field is mapped to userTenantRoles
 */
export type MapQueryBuilder = Record<string, (value: string | number) => Record<string, any>>;

export type EntityTableProps<Model extends BaseModel = BaseModel> = {
  data: { rows: Row<Model>[]; totalCount: number };
  Entity: EntityType<Model>;
  actions: Actions;
  variables?: any;
  defaultVariableOptions?: any;
  noWrapper?: boolean;
  hideDownload?: boolean;
  rowClick?: RowClick<Row<Model>>;
  disablePagination?: boolean;
  mapQueryBuilder?: MapQueryBuilder;
};

const useStyles = makeStyles()(() => ({
  tableRowClickable: {
    cursor: 'pointer',
  },
}));

export const useEntityTable = <Model extends BaseModel = BaseModel>({
  Entity,
  actions,
  data,
  rowClick,
  disablePagination,
  variables,
  defaultVariableOptions,
  mapQueryBuilder = {},
}: EntityTableProps<Model>) => {
  const { refetch } = actions;

  const { classes } = useStyles();

  const defaultSortField = useMemo(() => getDefaultSortField(Entity.table.schema), [Entity.table.schema]);

  const [order, setOrder] = useState<Order>(defaultSortOrder);
  const [orderBy, setOrderBy] = useState(defaultSortField);
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const clickableRows = useMemo<number[]>(
    () =>
      data.rows.reduce<number[]>((arr, _, index) => {
        const { skip = [], onClick } = rowClick ?? {};

        if (onClick && !skip.includes(index)) {
          arr.push(index);
        }

        return arr;
      }, []),
    [data.rows, rowClick]
  );

  const formattedItems = useMemo(() => {
    const { disabled = [], onClick } = rowClick ?? {};

    return data.rows.map((entry, index) => {
      const isClickable = clickableRows.includes(index);

      const props = isClickable
        ? {
            hover: true,
            tabIndex: -1,
            disabled: disabled.includes(index),
            className: classes.tableRowClickable,
            onClick: (e: MouseEvent<HTMLElement>) => onClick?.(e, entry, index),
          }
        : {};

      const cells = Entity.table.schema.map(({ field, cell: Cell, align }) => (
        <TableCell key={field} align={align}>
          <Cell Entity={Entity} row={entry} value={entry[field]} actions={actions} field={field} />
        </TableCell>
      ));

      if (isClickable) {
        cells.push(
          <TableCell key="click-action" align="right">
            <IconButton onClick={(e) => onClick?.(e, entry, index)}>
              <ArrowForwardIcon />
            </IconButton>
          </TableCell>
        );
      }

      return (
        <TableRow key={entry.id} {...props}>
          {cells}
        </TableRow>
      );
    });
  }, [Entity, actions, classes.tableRowClickable, clickableRows, data.rows, rowClick]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);

    refetch({
      paging: {
        page: newPage,
        pageSize,
      },
    }).catch((err) => {
      console.error(err);
      Notistack.toast(err);
    });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);

    refetch({
      paging: {
        page: 0,
        pageSize: newPageSize,
      },
    }).catch((err) => {
      console.error(err);
      Notistack.toast(err);
    });
  };

  return {
    Entity,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    actions,
    rowClick,
    defaultSortField,
    defaultSortOrder,
    clickableRows,
    data,
    formattedItems,
    page,
    pageSize,
    disablePagination,
    handleChangePage,
    handleChangePageSize,
    defaultVariableOptions,
    variables,
    mapQueryBuilder,
  };
};

function EntityTableContent(props: EntityTableProps) {
  const tableProps = useEntityTable(props);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    Entity,
    data,
    formattedItems,
    clickableRows,
    disablePagination,
    page,
    pageSize,
    handleChangePage,
    handleChangePageSize,
    actions,
    variables,
    mapQueryBuilder,
  } = tableProps;

  const { refetch } = actions;

  const debouncedRefetch = useCallback(debounce(refetch, 500), []);

  const handleResetFilter = useCallback(() => {
    setSearchParams();
  }, [setSearchParams]);

  useEffect(() => {
    if (!searchParams.size) {
      refetch({ filter: {} });

      return;
    }

    let buildFilter = {};
    let buildSorting = {};

    if (searchParams.has('orderByField') && searchParams.has('orderDirection')) {
      buildSorting = { field: searchParams.get('orderByField'), direction: searchParams.get('orderDirection')?.toUpperCase() };
    }

    for (const [fieldName, value] of searchParams) {
      if (['orderByField', 'orderDirection'].includes(fieldName)) {
        continue;
      }

      buildFilter = merge(buildFilter, buildQueryFilter(Entity, fieldName, value, mapQueryBuilder));
    }

    debouncedRefetch({
      sorting: buildSorting,
      filter: buildFilter,
    });
  }, [searchParams, searchParams.size]);

  return (
    <>
      <TableContainer>
        <Button style={{ margin: '0.5em' }} variant="contained" onClick={handleResetFilter}>
          {t('strings:filtersReset')}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              {Entity.table.schema.map((field) => (
                <TableHeadRow {...tableProps} key={field.field} fieldSchema={field} />
              ))}
              {clickableRows.length > 0 && <TableCell align="right" sx={{ width: 0 }} />}
            </TableRow>
          </TableHead>

          <TableBody>
            {!!data &&
              (data.rows.length > 0 ? (
                formattedItems
              ) : (
                <TableRow>
                  <TableCell colSpan={Entity.table.schema.length} align="center">
                    {t('crud:noData')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!disablePagination && (
        <OffsetTablePagination
          page={page}
          rowsPerPage={pageSize}
          count={data?.totalCount || 0}
          onChangePage={handleChangePage}
          onChangePageSize={handleChangePageSize}
        />
      )}
    </>
  );
}

export default function EntityTable(props: EntityTableProps) {
  const { Entity, noWrapper = false, actions, hideDownload, variables } = props;

  const user = useAuthenticatedUserStore();

  const { t } = useTranslation();

  const heading = useMemo(() => getTranslatedEntityNamePlural(t, Entity.name), [Entity.name, t]);
  const canAdd = useMemo(() => canPerformAction('create', camelCase(Entity.name), user), [Entity.name, user]);

  if (noWrapper) {
    return <EntityTableContent {...props} />;
  }

  return (
    <PageCard
      heading={heading}
      headingRight={
        <div style={{ display: 'flex', gap: 8 }}>
          {!hideDownload && <ExportDownloadMenu Entity={Entity} variables={variables} />}
          {canAdd && (
            <Button variant="contained" component={Link} to={`/admin/${Entity.name}/create`}>
              {t('crud:add', 'Add')}
            </Button>
          )}
        </div>
      }
      noBody
    >
      {actions.loading ? <LinearProgress /> : <div style={{ height: 4 }} />}
      <EntityTableContent {...props} />
    </PageCard>
  );
}
