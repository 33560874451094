import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import ProjectBaseOrderFormSchema from '@/_base/project/ProjectBaseOrderFormSchema';
import ProjectBaseSimpleSchema from '@/_base/project/ProjectBaseSimpleSchema';
import ProjectBaseTableSchema from '@/_base/project/ProjectBaseTableSchema';
import ProjectBaseInsertForm from '@/_base/project/components/ProjectBaseInsertForm';
import ProjectBaseUpdateForm from '@/_base/project/components/ProjectBaseUpdateForm';

import ProjectModel from './ProjectModel';
import ProjectBaseView from '@/_base/project/components/ProjectBaseView';
import ProjectsBaseTable from '@/_base/project/components/ProjectBaseTable';

const ProjectEntity: Entity<typeof ProjectModel> = {
  name: 'project',

  simpleSchema: ProjectBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(ProjectBaseSimpleSchema),
    orderSchema: [...ProjectBaseOrderFormSchema],
    component: ProjectBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(ProjectBaseSimpleSchema),
    orderSchema: [...ProjectBaseOrderFormSchema],
    component: ProjectBaseUpdateForm,
  },

  view: {
    component: ProjectBaseView,
  },

  table: {
    schema: ProjectBaseTableSchema,
    component: ProjectsBaseTable,
  },

  routes: {},

  model: ProjectModel,

  settings: {
    displayField: 'name',
  },
};

export default ProjectEntity;
