import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import CustomerBaseModel from '@/_base/customer/CustomerBaseModel';
import CustomerBaseOrderFormSchema from '@/_base/customer/CustomerBaseOrderFormSchema';
import CustomerBaseSimpleSchema from '@/_base/customer/CustomerBaseSimpleSchema';
import CustomerBaseTableSchema from '@/_base/customer/CustomerBaseTableSchema';
import CustomerBaseInsertForm from '@/_base/customer/components/CustomerBaseInsertForm';
import CustomerBaseUpdateForm from '@/_base/customer/components/CustomerBaseUpdateForm';
import CustomersBaseTable from '@/_base/customer/components/CustomerBaseTable';
import CustomerBaseView from '@/_base/customer/components/CustomerBaseView';

const CustomerEntity: Entity<typeof CustomerBaseModel> = {
  name: 'customer',

  simpleSchema: CustomerBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(CustomerBaseSimpleSchema),
    orderSchema: [...CustomerBaseOrderFormSchema],
    component: CustomerBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(CustomerBaseSimpleSchema),
    orderSchema: [...CustomerBaseOrderFormSchema],
    component: CustomerBaseUpdateForm,
  },

  view: {
    component: CustomerBaseView,
  },

  table: {
    schema: CustomerBaseTableSchema,
    component: CustomersBaseTable,
  },

  routes: {},

  model: CustomerBaseModel,

  settings: {
    displayField: 'name',
  },
};

export default CustomerEntity;
