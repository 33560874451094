import { BaseFormInsertProps, PreFilledFields } from '@boilerplate/types/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UserBaseInsertForm from '@/_base/user/components/UserBaseInsertForm';
import UserEntity from '@/entities/user';
import { User } from '@/graphql';

function UserInsertForm({ preFilledFields: preFilledFieldsProp, ...props }: BaseFormInsertProps<typeof UserEntity.model>) {
  const { i18n } = useTranslation();

  const preFilledFields = useMemo<PreFilledFields<User>>(
    () => ({
      ...preFilledFieldsProp,
      locale: { value: i18n.language, disabled: false },
      timeZone: { value: new Intl.DateTimeFormat().resolvedOptions().timeZone, disabled: false },
    }),
    [i18n.language, preFilledFieldsProp]
  );

  return <UserBaseInsertForm {...props} preFilledFields={preFilledFields} />;
}

export default UserInsertForm;
