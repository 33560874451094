import AuthLayout from '@frontend/components/auth/Layout/AuthLayout';
import config from '@frontend/config';
import Notistack from '@frontend/lib/notistack';
import { randomString } from '@frontend/lib/util';
import { loginUser, resendConfirmAccount } from '@frontend/stores/UserStore';
import { Button, Typography } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, Form, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import DialogBase from '../DialogBase';
import FormikTextField from '../FormikTextField';

import useDefaultLoginPageStyles from './useDefaultLoginPageStyles';

interface FormValues {
  email: string;
  password: string;
  totpToken: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  totpToken: '',
};

function DefaultLoginPage() {
  const { t } = useTranslation();
  const { classes, cx } = useDefaultLoginPageStyles();
  const formRef = useRef<FormikProps<FormValues>>();
  const [openNotActive, setOpenNotActive] = useState(false);
  const [openNotConfirmed, setOpenNotConfirmed] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const totpInputRef = useRef<HTMLInputElement>();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    if (!values.password) {
      errors.password = t('auth:validation.required');
    }
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    loginUser(values.email, values.password, values.totpToken)
      .then(({ totpRequired }) => {
        if (totpRequired) {
          setShow2FA(true);

          requestAnimationFrame(() => {
            totpInputRef.current?.focus?.();
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'auth:login.notConfirmed.error') {
          setOpenNotConfirmed(true);

          return;
        }

        if (error?.response?.data?.message === 'auth:login.notActive.error') {
          setOpenNotActive(true);

          return;
        }

        const errorMessage = error?.response?.data?.message ? t([error.response.data.message, 'auth:login.error']) : t('auth:login.error');

        console.error(error);

        setErrors({
          email: '',
          password: errorMessage,
          totpToken: errorMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCloseNotActive = useCallback(() => {
    setOpenNotActive(false);
  }, []);

  const handleCloseNotConfirmed = useCallback(() => {
    setOpenNotConfirmed(false);
  }, []);

  const handleFixNotConfirmed = useCallback(() => {
    if (formRef.current?.values.email) {
      resendConfirmAccount(formRef.current.values.email)
        .then(() => {
          Notistack.toast(t('auth:login.notConfirmed.resendSuccess'), { variant: 'success' });
        })
        .catch((error) => {
          Notistack.toast(t(error?.response?.data?.message, 'auth:login.notConfirmed.resendError'), {
            variant: 'error',
          });
        })
        .finally(() => {
          setOpenNotConfirmed(false);
        });
    }
  }, [t]);

  const backendUrl = config.app.backendUrl;

  useEffect(() => {
    if (config.flybase.oAuthEnabled) {
      const randomState: string = randomString(32);

      const protocol: string = window.location.protocol;
      const hostname: string = window.location.hostname;
      const port: string = window.location.port === '' ? '' : `:${window.location.port}`;

      const redirectUri = `${protocol}//${hostname}${port}${config.flybase.redirectRoute}`;

      window.localStorage.setItem('state', randomState);
      window.localStorage.setItem('redirectUri', redirectUri);
      const authUrl = `${config.flybase.frontendBaseUrl}/oauth/authorize?response_type=code&client_id=${config.flybase.publicKey}&redirect_uri=${redirectUri}&state=${randomState}`;
      window.location.href = authUrl;
    }
  }, []);

  if (config.flybase.oAuthEnabled) {
    return (
      <Typography mt={3} variant="h5" component="h5" style={{ fontStyle: 'italic', textAlign: 'center' }}>
        {t('auth:login.redirect.toFlybase')}
      </Typography>
    );
  }

  return (
    <AuthLayout heading={t('auth:login.title')}>
      {!!config.auth.registerEnabled && (
        <div style={{ margin: '10px 0' }}>
          {!!config.auth.facebookLoginEnabled && (
            <a href={`${backendUrl}/auth/facebook`} className={cx(classes.socialButton, classes.facebook_connect)}>
              <span>{t('auth:login.facebook')}</span>
            </a>
          )}
          {!!config.auth.googleLoginEnabled && (
            <a href={`${backendUrl}/auth/google`} className={cx(classes.socialButton, classes.google_connect)}>
              <span>{t('auth:login.google')}</span>
            </a>
          )}
        </div>
      )}

      <br />

      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} innerRef={formRef}>
        {({ isSubmitting }) => (
          <Form>
            {!show2FA ? (
              <>
                <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

                <FormikTextField type="password" name="password" label={t('auth:fields.password')} />
              </>
            ) : (
              <FormikTextField type="text" name="totpToken" label={t('auth:fields.token')} ref={totpInputRef} />
            )}

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('auth:login.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      {!show2FA && (
        <>
          <p>
            <Link to="/forgot-password">{t('auth:links.forgotPassword')}</Link>
          </p>

          {!!config.auth.registerEnabled && (
            <p>
              <Link to="/register">{t('auth:links.dontHaveAnAccountYetRegister')}</Link>
            </p>
          )}
        </>
      )}

      <DialogBase
        id="not-active"
        open={openNotActive}
        onClose={handleCloseNotActive}
        title={t('auth:login.notActive.title')}
        description={t('auth:login.notActive.description')}
      />

      <DialogBase
        id="not-confirmed"
        open={openNotConfirmed}
        onClose={handleCloseNotConfirmed}
        title={t('auth:login.notConfirmed.title')}
        description={t('auth:login.notConfirmed.description')}
        buttons={
          <Button variant="text" onClick={handleFixNotConfirmed}>
            {t('auth:login.notConfirmed.requestNew')}
          </Button>
        }
      />
    </AuthLayout>
  );
}

export default DefaultLoginPage;
