import ActionButtons from './ActionButtons';
import AutoRenderer from './AutoRenderer';
import BooleanRenderer from './BooleanRenderer';
import CodeRenderer from './CodeRenderer';
import DateRenderer from './DateRenderer';
import DateTimeRenderer from './DateTimeRenderer';
import IdRenderer from './IdRenderer';
import RichTextRenderer from './RichTextRenderer';
import SelectRenderer from './SelectRenderer';

export default {
  ActionButtons,
  ApproveRenderer: BooleanRenderer,
  AutoRenderer,
  BooleanRenderer,
  CodeRenderer,
  DateRenderer,
  DateTimeRenderer,
  IdRenderer,
  RichTextRenderer,
  SelectRenderer,
};
