import Footer from '@frontend/components/app/Layout/Footer/Footer';
import Header from '@frontend/components/app/Layout/Header/Header';
import ConfirmationDialog from '@frontend/components/shared/ConfirmationDialog';
import { Box } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  content: {
    width: '100%',
    minHeight: '100px',
    marginTop: 64,
    display: 'flex',
    flexGrow: 1,
  },
});

type DefaultAppLayoutProps = {
  children?: React.ReactNode;
};

function DefaultAppLayout({ children }: DefaultAppLayoutProps) {
  const { classes } = useStyles();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', minHeight: '100vh' }}>
      <Header />

      <div className={classes.content}>{children}</div>

      <Footer />

      <ConfirmationDialog />
    </Box>
  );
}

export default DefaultAppLayout;
