import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  heading: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '4px 4px 0 0',
    padding: '0.8rem 1rem',

    textAlign: 'left',
    width: '100%',

    justifyContent: 'space-between',
    gap: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',

    '&>*': {
      color: 'white',
    },
    '&>h5': {
      flexGrow: 1,
    },

    '&>svg': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },
  input: {
    padding: '0.7rem',
  },
  nowButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  noPadding: {
    padding: '0 !important',
  },
  autocompleteInput: {
    padding: '2.5px 4px 2.5px 6px !important',
  },
  autocompleteRoot: {
    padding: '6px important',
    '& .MuiInputLabel-root.Mui-focused': {
      transform: 'translate(14px, -9px) scale(0.75)',
      userSelect: 'none',
      pointerEvents: 'auto',
      maxWidth: 'calc(133% - 24px)',
      zIndex: 1,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  autocompleteLabel: {
    transform: 'translate(14px, 11px) scale(1)',
  },
  logButton: {
    height: 'fit-content',
    width: 'fit-content',
    padding: '.5rem',
  },
}));
