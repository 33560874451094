import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RenderProps } from './types';

export default function DateTimeRenderer({ value }: RenderProps): JSX.Element {
  const { i18n } = useTranslation();
  const formatter = useMemo(() => new Intl.DateTimeFormat(i18n.language, { dateStyle: 'short' }), [i18n.language]);

  const formattedValue = useMemo(() => (value ? formatter.format(new Date(value)) : ''), [formatter, value]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formattedValue}</>;
}
