import type { sanitize } from 'dompurify';

// https://developer.mozilla.org/en-US/docs/Web/API/Sanitizer/Sanitizer#parameters
export type Config = {
  allowElements?: string[];
  blockElements?: string[];
  dropElements?: string[];
  allowAttributes?: Record<string, string[]>;
  dropAttributes?: Record<string, string[]>;
  allowCustomElements?: boolean;
  allowComments?: boolean;
};

export type NormalizedConfig = Omit<Config, 'allowAttributes' | 'dropAttributes'> & {
  allowAttributes?: string[];
  dropAttributes?: string[];
};

export class AbstractSanitizer {
  // @ts-expect-error: TypeScript gets mad, but it is ok
  protected normalizedConfig: NormalizedConfig;

  get config(): NormalizedConfig {
    return this.normalizedConfig;
  }

  constructor(config?: Config) {
    // EMPTY
  }

  sanitize(input: HTMLElement): ReturnType<typeof sanitize> {
    throw new Error('ABSTRACT CLASS');
  }

  sanitizeFor(element: HTMLElement | string, input: string): ReturnType<typeof sanitize> {
    throw new Error('ABSTRACT CLASS');
  }

  getConfiguration(): NormalizedConfig {
    throw new Error('ABSTRACT CLASS');
  }

  static getDefaultConfiguration(): NormalizedConfig {
    throw new Error('ABSTRACT CLASS');
  }
}

declare global {
  interface HTMLElement {
    setHTML(input: string, opt?: { sanitizer?: AbstractSanitizer }): void;
  }
}
