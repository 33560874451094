import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetCustomerDocument,
  GetCustomerQuery,
  GetCustomerQueryVariables,
  GetCustomersDocument,
  GetCustomersQuery,
  GetCustomersQueryVariables,
  useGetCustomerQuery,
  useGetCustomerLazyQuery,
  useGetCustomersQuery,
  useGetCustomersLazyQuery,

    useGetAllRelatedDataForCustomerQuery,
    useGetAllRelatedDataForCustomerLazyQuery,
    GetAllRelatedDataForCustomerQuery,
    GetAllRelatedDataForCustomerQueryVariables,

  CreateCustomerDocument,
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  useCreateCustomerMutation,

  DeleteCustomerDocument,
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables,
  useDeleteCustomerMutation,

  UpdateCustomerDocument,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  useUpdateCustomerMutation,

    useCreatedCustomerSubscription,
    useUpdatedCustomerSubscription,
    useDeletedCustomerSubscription,
    useRestoredCustomerSubscription,
} from '@/graphql';

  type CustomerCollection = Dictionary<NonNullable<GetCustomersQuery['customers']['items']>[number]>;

const CustomerBaseModel = {
  get: (options: Omit<QueryOptions<GetCustomerQueryVariables, GetCustomerQuery>, 'query'>) => {
    return apolloClient.query<GetCustomerQuery, GetCustomerQueryVariables>({
      ...options,
      query: GetCustomerDocument,
    })
    .then(({ data }) => data.customer);
  },

  useGet: useGetCustomerQuery,

  getAll: (options?: Omit<QueryOptions<GetCustomersQueryVariables, GetCustomersQuery>, 'query'>) => {
    return apolloClient
      .query<GetCustomersQuery, GetCustomersQueryVariables>({
        ...options,
        query: GetCustomersDocument
      })
      .then(({ data }) => data.customers.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) => {
    const hookResult = useGetCustomersQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.customers?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForCustomerQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForCustomerQuery, GetAllRelatedDataForCustomerQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForCustomerQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetCustomerLazyQuery,

  useGetAllLazy: useGetCustomersLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForCustomerLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
      ...options,
      mutation: CreateCustomerDocument,
    });
  },

  useCreate: useCreateCustomerMutation,

  update: (options: Omit<MutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
      ...options,
      mutation: UpdateCustomerDocument,
    });
  },

  useUpdate: useUpdateCustomerMutation,

  delete: (options: Omit<MutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteCustomerMutation, DeleteCustomerMutationVariables>({
      ...options,
      mutation: DeleteCustomerDocument,
    });
  },

  useDelete: useDeleteCustomerMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) => {
      const [collection, setCollection] = useState<CustomerCollection>({});

      const { items, loading, error, refetch } = CustomerBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedCustomerSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdCustomer?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdCustomer.id]: data.createdCustomer,
            }));
          }
        },
      });

      useUpdatedCustomerSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedCustomer?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedCustomer.id]: data.updatedCustomer,
            }));
          }
        },
      });

      useDeletedCustomerSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedCustomer?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedCustomer.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredCustomerSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredCustomer?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredCustomer.id]: data.restoredCustomer,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default CustomerBaseModel;
