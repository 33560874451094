import Loading from '@boilerplate/components/Loading';
import { Box } from '@mui/material';
import React from 'react';

import DaysOffEntity from '@/entities/daysOff';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

import DaysOffDialog from '../DaysOffDialog';
import DaysOffDisplay from '../DaysOffDisplay';

export default function DaysOffContainer() {
  const authUser = useAuthenticatedUserStore();

  const { data, loading, refetch } = DaysOffEntity.model.useGetAll({
    variables: {
      filter: {
        userId: { eq: authUser.id },
      },
    },
  });

  if (loading || !data?.daysOffs) {
    <Loading />;
  }

  return (
    <Box height="100%" width="100%" sx={{ padding: 3.1 }}>
      <DaysOffDisplay daysOff={data?.daysOffs?.items?.map((item) => item)} refetch={refetch} />
      <DaysOffDialog refetch={refetch} />
    </Box>
  );
}
