import env from './env';

export default {
  backendUrl: env('APP_BACKEND_URL', 'http://localhost:3012'),
  branch: env('APP_BRANCH', 'master'),
  debug: env('APP_DEBUG', false),
  gitCommit: env('APP_GIT_COMMIT', ''),
  host: env('APP_HOST', 'localhost'),
  mailpitEnabled: env('APP_MAILPIT_ENABLED', false),
  name: env('APP_NAME', 'Flystart'),
  projectName: env('APP_PROJECT_NAME', 'flystart'),
  port: env('APP_PORT', 3000),
  server: false,
  ssr: env('APP_SSR', true),
  version: env('APP_VERSION', '?'),
  websocketSubscriptions: env('APP_WS_SUBSCRIPTIONS', true),
};
