import { CloseOutlined as CloseOutlinedIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grow, IconButton } from '@mui/material';
import React, { ReactElement, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { randomIds } from 'uniforms';

const getRandomId = randomIds('dialog');

const useStyles = makeStyles()({
  titleWrapper: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'flex',
  },
});

export type DialogBaseProps = {
  onClose?: () => void;
  open: boolean;
  title?: string | ReactNode;
  description?: string;
  children?: ReactNode;
  id?: string;
  buttons?: ReactNode;
  classes?: DialogProps['classes'] & { content?: string };
  noButtons?: boolean;
  noDefaultButtons?: boolean;
  gutterBottom?: boolean;
} & DialogProps;

const Transition = React.forwardRef<HTMLDivElement, { children: ReactElement<any, any> }>(function Transition({ children, ...props }, ref) {
  return (
    <Grow ref={ref} timeout={600} {...props}>
      {children}
    </Grow>
  );
});

function DialogBase({
  open,
  onClose,
  title,
  description,
  children,
  id,
  buttons = false,
  classes: classesProp,
  TransitionComponent = Transition,
  noButtons = false,
  noDefaultButtons = false,
  gutterBottom = false,
  ...props
}: DialogBaseProps): JSX.Element {
  const randomId = useRef(getRandomId());
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Dialog
      {...props}
      open={open}
      TransitionComponent={TransitionComponent}
      onClose={onClose}
      classes={classesProp}
      disableEscapeKeyDown={!onClose}
      aria-labelledby={title ? `${id || randomId.current}-title` : undefined}
      aria-describedby={description ? `${id || randomId.current}-content` : undefined}
    >
      <div className={classes.titleWrapper}>
        {!!title && <DialogTitle id={`${id || randomId.current}-title`}>{title}</DialogTitle>}
        {!!onClose && (
          <IconButton onClick={onClose} aria-label="close">
            <CloseOutlinedIcon />
          </IconButton>
        )}
      </div>
      <DialogContent className={classesProp?.content} sx={gutterBottom ? { paddingTop: 0 } : undefined}>
        {!!description && (
          <DialogContentText id={`${id || randomId.current}-content`} gutterBottom={gutterBottom}>
            {description}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {(!!onClose || !!buttons) && !noButtons && (
        <DialogActions>
          {!!onClose && !noDefaultButtons && (
            <Button variant="text" onClick={onClose}>
              {t('strings:close')}
            </Button>
          )}
          {buttons}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DialogBase;
