import config from '@frontend/config';
import { Alert, ButtonBase, Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/browser';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';

export type IncidentStatusProps = {
  projectName: string;
  checkInterval?: number;
  statusOrigin?: string;
};

type Status = {
  branchesToIgnore?: string[];
  description: string;
  title: string;
  show: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 99999999,
    display: 'flex',
    width: '100%',
    left: 0,
    top: 0,

    '&>*': {
      width: '100%',
    },
  },
}));

class ErrorBoundary extends React.Component<{ children: JSX.Element }> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return null;
    }

    return children;
  }
}

function IncidentStatusBase({
  projectName,
  checkInterval = 60000,
  statusOrigin = 'https://api.duodekastatus.nl',
}: IncidentStatusProps): JSX.Element {
  const [status, setStatus] = useState<(Status & { ignored: boolean }) | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const showHasBeenFalse = useRef(false);
  const classes = useStyles();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { target } = event;

    if (!(target instanceof Element)) {
      return;
    }

    if (target.closest('[title="Close"]')) {
      return;
    }

    setDialogOpen(true);
  };

  useEffect(() => {
    const abortController = 'AbortController' in window ? new AbortController() : null;
    let timeout: number;

    const checkStatus = () => {
      fetch(new URL(`/status/${projectName}`, statusOrigin), { signal: abortController?.signal })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }

          return response.json() as Promise<Status>;
        })
        .then((newStatus) => {
          if (showHasBeenFalse.current === true && newStatus.show) {
            setOpen(true);
          }

          showHasBeenFalse.current = newStatus.show === false;
          setStatus({ ...newStatus, ignored: (newStatus.branchesToIgnore ?? []).includes(config.app.branch) });
        })
        .catch(console.error)
        .finally(() => {
          timeout = window.setTimeout(checkStatus, checkInterval);
        });
    };

    checkStatus();

    return () => {
      abortController?.abort();
      window.clearTimeout(timeout);
    };
  }, [checkInterval, projectName, statusOrigin]);

  return (
    <>
      <Slide direction="down" in={open && !!status && !!status.show && !status.ignored}>
        <ButtonBase onClick={handleClick} className={classes.container} style={{ position: 'fixed' }}>
          <Alert severity="error" variant="filled" onClose={() => setOpen(false)} square>
            {status?.title}
          </Alert>
        </ButtonBase>
      </Slide>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{status?.title}</DialogTitle>
        <DialogContent>
          {/* eslint-disable-next-line react/no-danger */}
          <DialogContentText whiteSpace="pre-line" dangerouslySetInnerHTML={{ __html: status?.description || '' }} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default function IncidentStatus(props: IncidentStatusProps) {
  return (
    <ErrorBoundary>
      <IncidentStatusBase {...props} />
    </ErrorBoundary>
  );
}
