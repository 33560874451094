import AuthLayout from '@frontend/components/auth/Layout/AuthLayout';
import config from '@frontend/config';
import Notistack from '@frontend/lib/notistack';
import { passwordStrength } from '@frontend/lib/passwordStrength';
import { resetPassword, checkPasswordResetToken, loginUser, resendInvite } from '@frontend/stores/UserStore';
import { Button, TextField } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import DialogBase from '@boilerplate/components/DialogBase';

import FormikCheckBox from '../FormikCheckBox';
import FormikTextField from '../FormikTextField';

import PasswordStrengthIndicator from './PasswordStrengthIndicator';

interface FormValues {
  password: string;
  passwordConfirmation: string;
  totpToken: string;
  termsAndConditions?: boolean;
}

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
  totpToken: '',
  termsAndConditions: false,
};

function DefaultResetPasswordPage() {
  const { token = '' } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openExpired, setOpenExpired] = useState(false);
  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [termsAndConditionsRequired, setTermsAndConditionsRequired] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const totpInputRef = useRef<HTMLInputElement>();

  const { pathname } = useLocation();
  const isInvite = matchPath('/invite/:token', pathname);

  useEffect(() => {
    checkPasswordResetToken(token)
      .then((response) => {
        setEmail(response.data.email);
        setTermsAndConditionsRequired(response.data.termsAndConditionsRequired);
      })
      .catch(() => {
        setOpenExpired(true);
      });
  }, [token]);

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.password) {
      errors.password = t('auth:validation.required');
    } else if (passwordStrength(values.password) < config.auth.passwordStrength) {
      errors.password = t('auth:validation.passwordTooWeak');
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t('auth:validation.passwordConfirmationMustMatch');
    }

    if (termsAndConditionsRequired && !values.termsAndConditions) {
      errors.termsAndConditions = t('auth:validation.required');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    (async () => {
      if (!show2FA) {
        await resetPassword(email, token, values.password, values.passwordConfirmation, values.termsAndConditions);
      }

      const { totpRequired } = await loginUser(email, values.password, values.totpToken);

      if (totpRequired) {
        setShow2FA(true);

        requestAnimationFrame(() => {
          totpInputRef.current?.focus?.();
        });

        return;
      }

      navigate('/');
    })()
      .catch((error) => {
        if (error?.response?.data?.statusCode === 410) {
          setOpenExpired(true);

          return;
        }

        const errorMessage = t([error?.response?.data?.message, 'auth:login.error']);

        setErrors({
          totpToken: errorMessage,
          password: errorMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleFixExpired = useCallback(() => {
    if (isInvite) {
      resendInvite(token).then(() => {
        Notistack.toast(t('auth:invite.tokenExpired.resendSuccess'), { variant: 'success' });
      });

      navigate('/login');
    } else {
      navigate('/forgot-password');
    }
  }, [isInvite, navigate, t, token]);

  return (
    <AuthLayout heading={isInvite ? t('auth:invite.title') : t('auth:resetPassword.title')}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <Form>
            <TextField
              id="email"
              disabled
              value={email}
              margin="normal"
              label={t('auth:fields.email')}
              type="email"
              variant="outlined"
              fullWidth
            />

            {!show2FA ? (
              <>
                <FormikTextField type="password" name="password" label={t('auth:fields.newPassword')} />

                <PasswordStrengthIndicator password={values.password} />

                <FormikTextField type="password" name="passwordConfirmation" label={t('auth:fields.passwordConfirmation')} />
              </>
            ) : (
              <FormikTextField type="text" name="totpToken" label={t('auth:fields.token')} ref={totpInputRef} />
            )}

            {termsAndConditionsRequired && (
              <FormikCheckBox
                name="termsAndConditions"
                label={
                  <Trans
                    i18nKey="auth:fields.termsAndConditions"
                    components={{
                      a: <a href="/Algemene_voorwaarden.pdf" target="_blank" />,
                    }}
                  />
                }
              />
            )}

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {isInvite ? t('auth:invite.submit') : t('auth:resetPassword.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      <DialogBase
        open={openExpired}
        id="expired-dialog"
        title={t('auth:resetPassword.tokenExpired.title')}
        buttons={<Button onClick={handleFixExpired}>{t('auth:resetPassword.tokenExpired.requestNew')}</Button>}
        description={isInvite ? t('auth:invite.tokenExpired.description') : t('auth:resetPassword.tokenExpired.description')}
      />
    </AuthLayout>
  );
}

export default DefaultResetPasswordPage;
