import { makeStyles } from 'tss-react/mui';

const useDefaultProfilePageStyles = makeStyles()({
  profileImage: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  profile: {
    textAlign: 'center',
    maxHeight: '120px',
  },
});

export default useDefaultProfilePageStyles;
