import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        description: {
          type: String,
          form: true,
          view: true,
        },
        roles: {
          type: Array,
          relation: {
            name: 'BelongsToMany',
            type: 'ManyToMany',
            model: 'role',
            displayField: 'displayName',
          },
          optional: true,
          form: true,
          view: true,
        },
        'roles.$': {
          type: String,
          optional: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:permission.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:permission.name', 'entityFields:default.name'], '') || undefined,
        description: t(['entityFields:permission.description', 'entityFields:default.description'], '') || undefined,
        roles: t(['entityFields:permission.role', 'entityFields:default.role'], '') || undefined,
});

export default schema;