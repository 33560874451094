import { makeStyles } from 'tss-react/mui';

export const useRegisteredHoursDisplayStyles = makeStyles()((theme) => ({
  main: {
    height: '100%',

    [theme.breakpoints.up('lg')]: {
      minHeight: '742px',
    },
  },
  headerContainer: {
    display: 'flex',
    mb: '2rem',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },

    '&>h4': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '2rem',
      },
    },
  },
  title: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    gap: '1rem',
    height: 'fit-content',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  actionsContainerToday: {
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },
  dateRangePickerContainer: {
    padding: '1rem',
  },
  registeredHoursDisplayContainer: {
    position: 'relative',
  },
  tabsRow: {
    borderBottom: 1,
    borderColor: 'divider',
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalHoursOfWeekText: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '10px',
  },
  registeredHoursPanelsContainer: {
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  registeredHourRowContainer: {
    padding: '1rem',

    gridTemplateColumns: '1fr 100px 45px',
    alignItems: 'center',
    display: 'grid',
    gap: '1rem',

    '&:not(:last-of-type)': {
      borderBottom: '1px solid #dfdfdf',
    },
  },
  registeredHourInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  registeredHourInfoDescription: {
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    overflow: 'hidden',
  },
  registeredHourTimeContainer: {
    width: '100px',
  },
  registeredHourActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));
