import { Typography } from '@mui/material';
import React, { ReactNode, DetailedHTMLProps, FieldsetHTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

export type FieldsetProps = DetailedHTMLProps<FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> & {
  label?: string;
  children?: ReactNode;
  required?: boolean;
  error?: boolean;
};

const useStyles = makeStyles<{ error: boolean }>()(({ palette, spacing, shape }, { error }) => {
  const borderColor = (() => {
    if (error) {
      return palette.error.main;
    }

    return palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(225, 225, 225, 0.23)';
  })();

  return {
    fieldset: {
      borderRadius: shape.borderRadius,
      borderWidth: 1,
      borderColor,

      paddingTop: spacing(1),
    },
    label: {
      color: error ? palette.error.main : '',
      padding: `0 ${spacing(0.5)}`,
    },
  };
});

function Fieldset({ label, children, className = '', required = false, error = false, ...props }: FieldsetProps): JSX.Element {
  const { classes } = useStyles({ error });

  return (
    <fieldset className={`${className} ${classes.fieldset}`} {...props}>
      {!!label && (
        <Typography component="legend" variant="caption" className={classes.label}>
          {label}
          {required ? ' *' : ''}
        </Typography>
      )}
      {children}
    </fieldset>
  );
}

export default Fieldset;
