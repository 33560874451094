import { TableCell, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { sentenceCase } from 'change-case';
import React, { useMemo, useState } from 'react';

import { EntityTableProps } from './EntityTable';

import type { Actions } from './CellRenderers/types';
import { useSearchParams } from 'react-router-dom';
import TableSchema from '@boilerplate/types/tableSchema';

type Order = 'asc' | 'desc';

export default function TableHeadRow({
  fieldSchema,
  Entity,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  actions,
  defaultSortOrder = 'asc',
  defaultSortField = 'id',
  ...rest
}: {
  fieldSchema: TableSchema;
  Entity: Pick<EntityTableProps, 'Entity'>;
  order: Order;
  setOrder: (value: Order) => void;
  orderBy: string;
  setOrderBy: (value: string) => void;
  actions: Actions;

  defaultSortOrder?: Order;
  defaultSortField?: string;
}) {
  const label = useMemo(
    () => sentenceCase(typeof fieldSchema.label === 'function' ? fieldSchema.label() : fieldSchema.label ?? fieldSchema.field),
    [fieldSchema]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(() => {
    if (searchParams.has(fieldSchema.field)) {
      return searchParams.get(fieldSchema.field);
    }

    return '';
  });

  const FilterComponent = useMemo(() => fieldSchema.filter ?? null, [fieldSchema.filter]);

  const handleRequestSort = () => {
    const [newOrder, newOrderBy] = ((): [Order, string] => {
      const isDesc = orderBy === fieldSchema.field && order === 'desc';

      if (isDesc && orderBy !== defaultSortField) {
        return [defaultSortOrder, defaultSortField];
      }

      const isAsc = orderBy === fieldSchema.field && order === 'asc';

      return [isAsc ? 'desc' : 'asc', fieldSchema.field];
    })();

    setOrder(newOrder);
    setOrderBy(newOrderBy);

    setSearchParams((prevParams) => {
      if (prevParams.has('orderByField')) {
        const currentOrderByField = prevParams.get('orderByField');

        if (currentOrderByField !== newOrderBy) {
          prevParams.set('orderByField', newOrderBy);
        }
      } else {
        prevParams.set('orderByField', newOrderBy);
      }

      prevParams.set('orderDirection', newOrder);

      return prevParams;
    });
  };

  const handleFilter = (value: string) => {
    setSearchParams(
      (prevParams) => {
        if (value) {
          prevParams.set(fieldSchema.field, value);
        } else {
          prevParams.delete(fieldSchema.field);
        }

        return prevParams;
      },
      { replace: true }
    );

    setFilter(value);
  };

  return (
    <TableCell
      align={fieldSchema.numeric ? 'right' : fieldSchema.align ?? 'left'}
      sortDirection={orderBy === fieldSchema.field ? order : false}
    >
      {!fieldSchema.sortable ? (
        label
      ) : (
        <TableSortLabel
          active={orderBy === fieldSchema.field}
          direction={orderBy === fieldSchema.field ? order : 'asc'}
          onClick={handleRequestSort}
        >
          {label}
          {orderBy === fieldSchema.field && (
            <Box component="span" sx={visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          )}
        </TableSortLabel>
      )}
      {FilterComponent && (
        <div>
          <FilterComponent
            {...rest}
            actions={actions}
            Entity={Entity}
            setFilter={handleFilter}
            fieldSchema={fieldSchema}
            fieldName={fieldSchema.field}
            filterValue={filter}
          />
        </div>
      )}
    </TableCell>
  );
}
