import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetUserTenantRoleDocument,
  GetUserTenantRoleQuery,
  GetUserTenantRoleQueryVariables,
  GetUserTenantRolesDocument,
  GetUserTenantRolesQuery,
  GetUserTenantRolesQueryVariables,
  useGetUserTenantRoleQuery,
  useGetUserTenantRoleLazyQuery,
  useGetUserTenantRolesQuery,
  useGetUserTenantRolesLazyQuery,

    useGetAllRelatedDataForUserTenantRoleQuery,
    useGetAllRelatedDataForUserTenantRoleLazyQuery,
    GetAllRelatedDataForUserTenantRoleQuery,
    GetAllRelatedDataForUserTenantRoleQueryVariables,

  CreateUserTenantRoleDocument,
  CreateUserTenantRoleMutation,
  CreateUserTenantRoleMutationVariables,
  useCreateUserTenantRoleMutation,

  DeleteUserTenantRoleDocument,
  DeleteUserTenantRoleMutation,
  DeleteUserTenantRoleMutationVariables,
  useDeleteUserTenantRoleMutation,

  UpdateUserTenantRoleDocument,
  UpdateUserTenantRoleMutation,
  UpdateUserTenantRoleMutationVariables,
  useUpdateUserTenantRoleMutation,

    useCreatedUserTenantRoleSubscription,
    useUpdatedUserTenantRoleSubscription,
    useDeletedUserTenantRoleSubscription,
    useRestoredUserTenantRoleSubscription,
} from '@/graphql';

  type UserTenantRoleCollection = Dictionary<NonNullable<GetUserTenantRolesQuery['userTenantRoles']['items']>[number]>;

const UserTenantRoleBaseModel = {
  get: (options: Omit<QueryOptions<GetUserTenantRoleQueryVariables, GetUserTenantRoleQuery>, 'query'>) => {
    return apolloClient.query<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>({
      ...options,
      query: GetUserTenantRoleDocument,
    })
    .then(({ data }) => data.userTenantRole);
  },

  useGet: useGetUserTenantRoleQuery,

  getAll: (options?: Omit<QueryOptions<GetUserTenantRolesQueryVariables, GetUserTenantRolesQuery>, 'query'>) => {
    return apolloClient
      .query<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>({
        ...options,
        query: GetUserTenantRolesDocument
      })
      .then(({ data }) => data.userTenantRoles.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>) => {
    const hookResult = useGetUserTenantRolesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.userTenantRoles?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForUserTenantRoleQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForUserTenantRoleQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetUserTenantRoleLazyQuery,

  useGetAllLazy: useGetUserTenantRolesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForUserTenantRoleLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateUserTenantRoleMutation, CreateUserTenantRoleMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateUserTenantRoleMutation, CreateUserTenantRoleMutationVariables>({
      ...options,
      mutation: CreateUserTenantRoleDocument,
    });
  },

  useCreate: useCreateUserTenantRoleMutation,

  update: (options: Omit<MutationOptions<UpdateUserTenantRoleMutation, UpdateUserTenantRoleMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateUserTenantRoleMutation, UpdateUserTenantRoleMutationVariables>({
      ...options,
      mutation: UpdateUserTenantRoleDocument,
    });
  },

  useUpdate: useUpdateUserTenantRoleMutation,

  delete: (options: Omit<MutationOptions<DeleteUserTenantRoleMutation, DeleteUserTenantRoleMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteUserTenantRoleMutation, DeleteUserTenantRoleMutationVariables>({
      ...options,
      mutation: DeleteUserTenantRoleDocument,
    });
  },

  useDelete: useDeleteUserTenantRoleMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>) => {
      const [collection, setCollection] = useState<UserTenantRoleCollection>({});

      const { items, loading, error, refetch } = UserTenantRoleBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedUserTenantRoleSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdUserTenantRole?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdUserTenantRole.id]: data.createdUserTenantRole,
            }));
          }
        },
      });

      useUpdatedUserTenantRoleSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedUserTenantRole?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedUserTenantRole.id]: data.updatedUserTenantRole,
            }));
          }
        },
      });

      useDeletedUserTenantRoleSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedUserTenantRole?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedUserTenantRole.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default UserTenantRoleBaseModel;
