import { Check as CheckIcon, Close as CloseIcon, QuestionMark as QuestionMarkIcon } from '@mui/icons-material';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  SwitchProps,
} from '@mui/material';
import { omit } from 'lodash';
import React, { Ref, useMemo } from 'react';
import { FieldProps, GuaranteedProps, filterDOMProps, useField } from 'uniforms';

export type BooleanFieldProps = FieldProps<
  boolean,
  CheckboxProps | SwitchProps,
  {
    appearance?: 'checkbox' | 'switch';
    fullWidth?: boolean;
    helperText?: string;
    legend?: string;
    margin?: 'dense' | 'normal' | 'none';
  }
>;

type SubFieldProps = GuaranteedProps<BooleanFieldProps['value']> & BooleanFieldProps;

function NonOptionalField(props: SubFieldProps) {
  const { appearance, label, value, name, inputRef, disabled, readOnly, legend, onChange } = props;

  const SelectionControl = appearance === 'checkbox' || appearance === undefined ? Checkbox : Switch;

  return (
    <>
      {legend && (
        <FormLabel component="legend" htmlFor={name}>
          {legend}
        </FormLabel>
      )}
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <SelectionControl
              checked={!!value}
              name={name}
              onChange={(event) => !disabled && !readOnly && onChange && onChange(event.target.checked)}
              ref={inputRef as Ref<HTMLButtonElement>}
              value={name}
              {...omit(filterDOMProps(props), ['helperText', 'fullWidth'])}
            />
          }
        />
      </FormGroup>
    </>
  );
}

function OptionalField(props: SubFieldProps) {
  const { name, label, required, value, onChange } = props;

  const mappedValue = useMemo(() => {
    switch (value) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return 'null';
    }
  }, [value]);

  const handleChange = (e: unknown, newValue: string) => {
    onChange(
      (() => {
        switch (newValue) {
          case 'true':
            return true;
          case 'false':
            return false;
          case 'indeterminate':
          default:
            return null;
        }
      })()
    );
  };

  return (
    <>
      <FormLabel id={`${name}-group-label`} required={required}>
        {label}
      </FormLabel>
      <RadioGroup
        {...omit(filterDOMProps(props), 'onCopy')}
        aria-labelledby={`${name}-group-label`}
        defaultValue="female"
        name={`${name}-group`}
        onChange={handleChange}
        value={mappedValue}
      >
        <FormControlLabel value="true" control={<Radio />} label={<CheckIcon />} />
        <FormControlLabel value="false" control={<Radio />} label={<CloseIcon />} />
        <FormControlLabel value="null" control={<Radio />} label={<QuestionMarkIcon fontSize="small" />} />
      </RadioGroup>
    </>
  );
}

export default function BooleanField(props: BooleanFieldProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const [fieldProps] = useField<BooleanFieldProps>(props.name, props);

  const {
    disabled,
    readOnly,
    showInlineError,
    error,
    errorMessage,
    helperText,
    margin,
    required,
    field: { optional = false },
  } = fieldProps;

  const formHelperText = showInlineError && error ? errorMessage : helperText;
  const formControlProps = {
    disabled: !!disabled,
    error: !!error,
    margin,
    readOnly,
    required,
    fullWidth: true,
  };

  return (
    <FormControl {...formControlProps}>
      {optional ? <OptionalField {...fieldProps} /> : <NonOptionalField {...fieldProps} />}
      {!!formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}
