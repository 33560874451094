import { OptionsObject, ProviderContext, SnackbarMessage } from 'notistack';

type CustomNotistack = {
  registerSnackbar: (context: ProviderContext) => void;
  toast: ProviderContext['enqueueSnackbar'];
} & ProviderContext;

const Notistack: CustomNotistack = {
  // Default empty functions.
  toast: () => '',
  enqueueSnackbar: () => '',
  closeSnackbar: () => undefined,

  // Allow functional code to queue and close Snackbars/Toasts
  registerSnackbar: ({ enqueueSnackbar, closeSnackbar }) => {
    Object.assign(Notistack, {
      enqueueSnackbar,
      closeSnackbar,
      toast: (message: SnackbarMessage, options?: OptionsObject) => {
        // Convert (Error) Objects to String
        const formattedMessage = typeof message !== 'string' ? message?.toString() ?? '' : message;

        if (!formattedMessage) {
          console.error('Empty toast message (throw error to get trace)');
          // Throw an error to find out where this toast was created.
          // throw new Error('Empty toast message');
        }

        if (message instanceof Error) {
          options ??= {};
          options.variant ??= 'error';
        }

        // Creates a Snackbar and allows closing it by clicking it
        const key = enqueueSnackbar(formattedMessage, {
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(key);
            },
          },
          ...options,
        });

        return key;
      },
    });
  },
};

export default Notistack;
