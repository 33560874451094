import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useEffect, useMemo } from 'react';
import { useField, FieldProps } from 'uniforms';

export function DateTimeFieldBase({
  props,
  component: Component,
}: {
  props: FieldProps<Date | null, { required?: boolean }>;
  component: (props: Record<string, unknown>) => JSX.Element;
}) {
  const { value: valueProp, onChange, label, field, disabled, required } = props;

  const value = useMemo(() => {
    if (valueProp instanceof Date) {
      return valueProp;
    }

    if (valueProp) {
      return new Date(valueProp);
    }

    return field.optional ? null : new Date();
  }, [valueProp, field.optional]);

  useEffect(() => {
    if (!field.optional && !valueProp) {
      onChange(new Date());
    }
  }, [field.optional, onChange, valueProp]);

  return (
    <Component
      value={value}
      onChange={(date: Date | null) => onChange(date)}
      disabled={disabled}
      label={label}
      ampm={false}
      slotProps={{
        textField: {
          size: 'small',
          required,
        },
      }}
    />
  );
}

export default function DateTimeField(props: FieldProps<Date | null, unknown>) {
  // eslint-disable-next-line react/destructuring-assignment
  const [field] = useField(props.name, props, { initialValue: true });

  return <DateTimeFieldBase props={{ ...props, ...field }} component={DateTimePicker} />;
}
