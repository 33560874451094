import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { pickBy } from 'lodash';
import React from 'react';

export default function ExportDownloadMenu({ Entity, variables }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (extension: 'csv' | 'xlsx') => {
    const columns = Object.keys(
      pickBy(Entity.simpleSchema._schema, (schemaObject, key) => {
        if (schemaObject.relation || key.includes('.$')) {
          return false;
        }

        return true;
      })
    );

    axios
      .post('/export', { entityName: Entity.name, columns, extension, variables }, { responseType: 'blob' })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));

        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = url;
        tempLink.setAttribute('download', `${Entity.name} export - ${new Date().toUTCString()}.${extension}`);
        document.body.appendChild(tempLink);
        tempLink.click();
        tempLink.remove();
        handleClose();
      })
      .catch(console.error);
  };

  return (
    <div>
      <Button
        id="download-entity"
        aria-controls={open ? 'entity-download-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
      >
        Download
      </Button>
      <Menu
        id="entity-download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'download-entity',
        }}
      >
        <MenuItem onClick={() => handleDownload('xlsx')}>XLSX</MenuItem>
        <MenuItem onClick={() => handleDownload('csv')}>CSV</MenuItem>
      </Menu>
    </div>
  );
}
