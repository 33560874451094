import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        active: {
          type: Boolean,
          defaultValue: false,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        description: {
          type: String,
          optional: true,
          form: {
            component: 'LongText',
          },
          view: true,
        },
        startDate: {
          type: Date,
          optional: true,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        endDate: {
          type: Date,
          optional: true,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        customer: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'customer',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        categories: {
          type: Array,
          relation: {
            name: 'HasMany',
            type: 'ManyToMany',
            model: 'category',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        'categories.$': {
          type: String,
          optional: true,
        },
        tenant: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'tenant',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        loggedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'loggedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'loggedHours.$': {
          type: String,
          optional: true,
        },
        budgets: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'budget',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'budgets.$': {
          type: String,
          optional: true,
        },
        reportRecipients: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'reportRecipient',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'reportRecipients.$': {
          type: String,
          optional: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:project.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:project.name', 'entityFields:default.name'], '') || undefined,
        active: t(['entityFields:project.active', 'entityFields:default.active'], '') || undefined,
        description: t(['entityFields:project.description', 'entityFields:default.description'], '') || undefined,
        startDate: t(['entityFields:project.start_date', 'entityFields:default.start_date'], '') || undefined,
        endDate: t(['entityFields:project.end_date', 'entityFields:default.end_date'], '') || undefined,
        customer: t(['entityFields:project.customer', 'entityFields:default.customer'], '') || undefined,
        categories: t(['entityFields:project.category', 'entityFields:default.category'], '') || undefined,
        tenant: t(['entityFields:project.tenant', 'entityFields:default.tenant'], '') || undefined,
        loggedHours: t(['entityFields:project.logged_hour', 'entityFields:default.logged_hour'], '') || undefined,
        budgets: t(['entityFields:project.budget', 'entityFields:default.budget'], '') || undefined,
        reportRecipients: t(['entityFields:project.report_recipient', 'entityFields:default.report_recipient'], '') || undefined,
        createdAt: t(['entityFields:project.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:project.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:project.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;