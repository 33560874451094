import { RenderProps } from '@boilerplate/components/entity/EntityTable/CellRenderers/types';
import { Avatar as AvatarBase } from '@mui/material';
import React from 'react';

export default function Avatar({ row }: RenderProps) {
  return (
    <AvatarBase alt="Profile picture" src={row.avatar ?? undefined}>
      {row.name?.[0] || '?'}
    </AvatarBase>
  );
}
