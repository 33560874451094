import SimpleSchema from './simpleSchema';

export type FormSchema = string[][];

export const customFields = (customSchema: FormSchema, baseSchema: FormSchema): FormSchema => {
  const flatSchema = customSchema.flat(1);

  return baseSchema.map((row) => row.filter((entry) => !flatSchema.includes(entry))).filter((row) => row.length > 0);
};

export const removeExcludedFormFields = (rawSchema: SimpleSchema): SimpleSchema => {
  const schema = rawSchema.clone().schema();

  for (const key in schema) {
    const value = schema[key];

    if ('form' in value && value.form === false) {
      value.optional = true;
    }
  }

  return new SimpleSchema(schema);
};
