import React, { createRef, useRef, useEffect, useState, useCallback, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactShadowRoot from 'react-shadow-root';

import getSanitizer, { AbstractSanitizer } from '../lib/sanitizer-polyfill';

export type SanitizedProps = {
  component?: string | ReactElement;
  useShadowRoot?: boolean;
  children: string;
};

function Sanitized<T extends Record<string, unknown> = Record<string, any>>({
  children,
  component: Component = 'div',
  useShadowRoot = false,
  props,
  ...extraProps
}: SanitizedProps & { props?: T }) {
  const sanitizerRef = useRef<AbstractSanitizer>();
  const ref = createRef<HTMLDivElement>();

  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const update = useCallback(
    (sanitizer: AbstractSanitizer) => {
      if (ref.current) {
        ref.current.setHTML(children, { sanitizer });
      }
    },
    [children, ref]
  );

  useEffect(() => {
    getSanitizer()
      .then((Sanitizer) => {
        sanitizerRef.current = new Sanitizer();
        update(sanitizerRef.current);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });
  }, [update]);

  useEffect(() => {
    if (sanitizerRef.current) {
      update(sanitizerRef.current);
    }
  }, [update]);

  if (error) {
    return <p>{t('strings:sanitized.unableToRender')}</p>;
  }

  const content = <Component {...extraProps} {...props} ref={ref} />;

  return useShadowRoot ? <ReactShadowRoot>{content}</ReactShadowRoot> : content;
}

export default Sanitized;
