import AppLayout from '@frontend/components/app/Layout/AppLayout';
import AuthLayoutRight from '@frontend/components/auth/Layout/AuthLayoutRight';
import { Container, Grid, Hidden, Typography, useTheme } from '@mui/material';
import React from 'react';

type PanelProps = {
  side: 'left' | 'right';
  children: React.ReactNode;
};

function Panel({ children, side }: PanelProps) {
  const theme = useTheme();

  let backgroundColor = 'inherit';

  if (side === 'right') {
    backgroundColor = theme.palette.mode === 'light' ? '#fafafa' : '#151515';
  }

  return (
    <Grid
      item
      container
      xs={12}
      md={6}
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        backgroundColor,
      }}
    >
      {side === 'left' && (
        <Hidden mdDown>
          <Grid item flexGrow={1} />
        </Hidden>
      )}

      <Grid item flexBasis="800px">
        <Container maxWidth="sm">{children}</Container>
      </Grid>

      {side === 'right' && (
        <Hidden mdDown>
          <Grid item flexGrow={1} />
        </Hidden>
      )}
    </Grid>
  );
}

type CustomAuthLayoutProps = {
  heading: string;
  children: React.ReactNode;
};

function DefaultAuthLayout({ heading, children }: CustomAuthLayoutProps) {
  return (
    <AppLayout>
      <Grid container alignItems="stretch">
        <Panel side="left">
          <Typography variant="h1" fontSize={32} fontWeight={600}>
            {heading}
          </Typography>

          {children}
        </Panel>

        <Panel side="right">
          <AuthLayoutRight />
        </Panel>
      </Grid>
    </AppLayout>
  );
}

export default DefaultAuthLayout;
