import { TablePagination } from '@mui/material';
import React from 'react';

type OffsetTablePaginationProps = {
  page: number;
  rowsPerPage: number;
  count: number;
  onChangePage: (newPage: number) => void;
  onChangePageSize: (newRowsPerPage: number) => void;
};

export default function OffsetTablePagination({
  page,
  rowsPerPage,
  count,
  onChangePage,
  onChangePageSize: onChangeRowsPerPage,
}: OffsetTablePaginationProps) {
  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={(e, newPage) => {
        onChangePage(newPage);
      }}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(event) => {
        onChangeRowsPerPage(parseInt(event.target.value, 10));
      }}
    />
  );
}
