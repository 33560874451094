import SimpleSchemaBase, { SchemaDefinition } from 'simpl-schema';

import { DeepPartial } from '@flystart/generator/fields';

SimpleSchemaBase.extendOptions(['form', 'view', 'relation']);

export type FormOptions = {
  component?: 'Id' | 'DateTime' | 'Date' | 'Boolean' | 'Upload' | 'LongText' | 'Number' | 'Select';
  row?: number;
  helpText?: string | (() => JSX.Element | string | boolean | null);
  disabled?: boolean;
};

export type SchemaEntry = SchemaDefinition & {
  form?: boolean | FormOptions;
  view?: boolean;
  relation?: {
    name: 'BelongsTo' | 'BelongsToMany' | 'HasMany' | 'HasOne' | 'ManyToOne' | 'OneToMany';
    type: 'OneToOne' | 'ManyToMany' | 'OneToMany' | 'ManyToOne';
    model: string;
    displayField: string;
  };
};

export type Schema = {
  [key: string]: SchemaEntry;
};

/**
 * SimpleSchema validates JavaScript objects to ensure they match a schema.
 *
 * It can also clean the objects to automatically convert types, remove unsupported properties,
 * and add automatic values such that the object is then more likely to pass validation.
 *
 * Within Flystart it used for the backoffice to generate it's various standard forms, such as insert and update.
 *
 */
export default class SimpleSchema extends SimpleSchemaBase {
  constructor(schema: Schema | SimpleSchema) {
    // @ts-expect-error: The types for the constructor are wrong, it does allow you to supply a constructed simple schema
    super(schema);
  }

  // @ts-expect-error: The function is not the same as the base, but that's fine
  extend(otherSchema: DeepPartial<SimpleSchema | Schema>): SimpleSchema {
    // @ts-expect-error: The function is not the same as the base, but that's fine
    return super.extend(otherSchema);
  }

  getAllowedValuesForKey(key: keyof Schema): string[] {
    // @ts-expect-error: For some reason the types miss this function...
    return super.getAllowedValuesForKey(key);
  }

  /**
   * @method SimpleSchema#pick
   * @param {[fields]} The list of fields to pick to instantiate the subschema
   * @returns {SimpleSchema} The subschema
   */
  // @ts-expect-error: The function is not the same as the base, but that's fine
  pick(this: SimpleSchema, ...args: string[]): SimpleSchema {
    return this.pick(...args);
  }
  /**
   * @method SimpleSchema#omit
   * @param {[fields]} The list of fields to omit to instantiate the subschema
   * @returns {SimpleSchema} The subschema
   */
  // @ts-expect-error: The function is not the same as the base, but that's fine
  omit(this: SimpleSchema, ...args: string[]): SimpleSchema {
    return this.omit(...args);
  }
}

/**
 * Determine the order in which fields are to be rendered.
 *
 * You can also hide certain fields, be careful as they might be required!
 *
 * Is used in EntityForm.tsx -> const formattedFields = ...
 */
export type OrderSchema = string[][];

export enum FieldType {
  STRING,
  BOOLEAN,
  ARRAY,
  FLOAT,
  OBJECT,
  DATE,
}

export const getFieldType = (
  field: Omit<SchemaEntry, 'type'> & {
    type?: [{ type?: typeof String | typeof Boolean | typeof Number | typeof Array | typeof Object | typeof Date }];
  }
): FieldType | null => {
  switch (field.type?.[0]?.type) {
    case String:
      return FieldType.STRING;
    case Boolean:
      return FieldType.BOOLEAN;
    case Number:
      return FieldType.FLOAT;
    case Array:
      return FieldType.ARRAY;
    case Object:
      return FieldType.OBJECT;
    case Date:
      return FieldType.DATE;
    default:
      return null;
  }
};
