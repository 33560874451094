const schema = [
        ['startDate'],
        ['endDate'],
        ['approved'],
        ['note'],
        ['user'],
        ['tenant'],
];

export default schema;
