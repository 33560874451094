import { format, Locale } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import i18next from 'i18next';

const locales: { [key: string]: Locale } = { en: enUS, 'nl-NL': nl };

export default function (date: Date, formatStr = 'PP') {
  return format(date, formatStr, {
    locale: locales[i18next.language],
  });
}
