import { snakeCase } from 'change-case';
import React from 'react';
import { Link } from 'react-router-dom';

export default function EntityLink({ data }) {
  if (!data) {
    return <span>-</span>;
  }

  return <Link to={`/admin/${snakeCase(data.__typename)}/${data.id}`}>{data.displayField}</Link>;
}
