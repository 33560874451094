import { Stack } from '@mui/material';
import React, { useMemo } from 'react';

import EntityLink from './EntityLink';

export default function EntityLinks({ value }: object) {
  const items = useMemo(() => {
    if (!value) {
      return null;
    }

    if (value.items) {
      return value.items;
    }

    return [value];
  }, [value]);

  if (!items) {
    return <EntityLink data={value} />;
  }

  if (items.length == 0) {
    return <span>-</span>;
  }

  return (
    <Stack spacing={1}>
      {items.map((item: object) => (
        <EntityLink key={item.id} data={item} />
      ))}
    </Stack>
  );
}
