import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const PermissionBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:permission.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:permission.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'description',
        label: t(['entityFields:permission.description', 'entityFields:default.description'], 'description') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'roles',
        label: t(['entityFields:permission.role', 'entityFields:default.role'], 'role') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
];

const PermissionBaseTableSchema: TableSchema[] = [
  ...PermissionBaseTableSchemaWithoutActions,
  actions,
];

export { actions, PermissionBaseTableSchemaWithoutActions };
export default PermissionBaseTableSchema;
